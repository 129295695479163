<template>
    <div class="master" v-if="!showPlaceholder">
        <CategorySelector v-if="categories.length > 1"
                          v-bind:class="{active:this.activeScrollPosition > 100}"
                          v-bind:categories="categories"
                          v-bind:selected="currentCategory"
                          v-on:setTabAndScroll="setTabAndScroll"/>
        <div class="scrollable" ref="scrollable">
            <div v-if="banner && dspBanner" class="top-banner" v-on:click="dspBanner = false">
                <div v-html="banner"></div>
            </div>
            <div class="catalog" v-if="!loading">
                <div v-for="category in categories" v-bind:key="category.id" class="category-wrapper"
                     :data-category="category.id"
                     :ref="'category_' + category.id">
                    <div class="wrapper category__head"
                         v-if="category.reference !== 'flagship' && category.title">
                        <div class="title">{{ category.title }}</div>
                        <div class="subtitle" v-if="category.subtitle">{{ category.subtitle }}</div>
                    </div>
                    <ShopItem v-for="product in category.products"
                              v-on:addToCart="addToCart"
                              v-on:removeFromCart="removeFromCart"
                              v-bind:product="product"
                              v-bind:key="product.id"
                              v-bind:palette="palette"
                              v-bind:paletteCardboardCollectiveFees="getCardboardCollectiveFees"
                              v-bind:orderEnabled="isOpened"/>
                </div>
            </div>
            <LoadingSpinner v-else/>
        </div>
        <transition name="slide" v-if="cart.loaded">
            <router-link to="/panier" class="btn bg btn-show-cart" v-if="nbItems">
                Voir le panier <span class="badge">{{ nbItems }}</span>
            </router-link>
        </transition>
        <transition name="slide" v-if="cart.loaded">
            <div class="btn-show-cart__blur" v-if="nbItems"></div>
        </transition>
    </div>
    <div v-else>
        <div>Rejoignez une palette et découvrez le catalogue</div>
    </div>
</template>

<script>
import {cart} from "@/mixins/cart";
import {palette} from "@/mixins/palette";
import ShopItem from "@/components/Shop/ShopItem";
import LoadingSpinner from "@/components/UX/LoadingSpinner";
import CategorySelector from "@/components/Shop/CategorySelector";

export default {
    name: 'Shop',
    components: {
        LoadingSpinner,
        ShopItem,
        CategorySelector
    },
    mixins: [palette, cart],
    data() {
        return {
            currentCategory: null,
            loading: true,
            scrollPosition: 0,
            activeScrollPosition: 0,
            catPositions: [],
            scrollTimeout: null,
            dspBanner: true,
            showPlaceholder: false
        }
    },
    computed: {
        categories() {
            return this.$store.getters.CATEGORIES;
        },
        products() {
            return this.$store.getters.PRODUCTS;
        },
        banner() {
            return this.$store.getters.ENV_DATA('top-banner', null);
        }
    },
    mounted() {
        this.getCurrentPalette().then(palette => {
            this.$store.dispatch('LOAD_CATALOG', palette.id).then(async () => {
                await this.fetchCart();
                this.loading = false;
            });
            this.$nextTick(() => {
                this.$refs.scrollable.addEventListener('scroll', this.handleScroll);
                setTimeout(() => {
                    this.computeCategoriesPositions();
                }, 500);
            });
            if (this.$gtag) {
                this.$gtag.event('view_item_list');
            }
        }).catch(e => {
            this.loading = false;
            this.showPlaceholder = true;
        })
    },
    methods: {
        setTabAndScroll(cat) {
            this.setActiveTab(cat);
            this.scrollTo(cat);
        },
        setActiveTab(cat) {
            this.currentCategory = parseInt(cat);
        },
        scrollTo(cat) {
            var scrollTo = this.$refs['category_' + cat][0].offsetTop - 32;
            this.$refs.scrollable.scrollTop = scrollTo > 0 ? scrollTo : 0;
        },
        computeCategoriesPositions() {
            this.catPositions = [];
            document.querySelectorAll(".category-wrapper").forEach(el => {
                this.catPositions.push({
                    position: el.offsetTop - 100,
                    height: el.offsetHeight,
                    name: el.getAttribute('data-category'),
                })
            });
        },
        handleScroll(event) {
            this.activeScrollPosition = this.$refs.scrollable.scrollTop;

            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout);
            }

            let newActive;
            this.scrollTimeout = setTimeout(() => {
                this.scrollPosition = this.$refs.scrollable.scrollTop;
                const scrollHeight = this.$refs.scrollable.scrollHeight;
                const clientHeight = this.$refs.scrollable.clientHeight;
                this.catPositions.forEach((el) => {
                    if (this.scrollPosition >= el.position || this.scrollPosition + el.height + (clientHeight / 2) >= scrollHeight) {
                        newActive = el.name;
                    }
                });

                if (this.currentCategory !== newActive && newActive !== null) {
                    this.setActiveTab(newActive, false);
                }
            }, 200);
        },
    },
    created() {
        window.addEventListener('resize', this.computeCategoriesPositions);
    },
    destroyed() {
        window.removeEventListener('resize', this.computeCategoriesPositions);
        if (this.$refs.scrollable) {
            this.$refs.scrollable.removeEventListener('scroll', this.handleScroll);
        }
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "src/styles/vars";

.catalog {
  padding-bottom: $padding-wrapper * 2;
}

.btn-show-cart {
  position: fixed;
  bottom: $bottom-nav-height + $padding-wrapper;
  left: $padding-wrapper;
  right: $padding-wrapper;
  width: auto;
  margin: auto;
  max-width: $layout-max-width - ($padding-wrapper * 2);
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.28);
  z-index: 100;

  .badge {
    background: darken($primary, 14);
    margin-left: 5px;
    font-size: 12px;
    font-weight: 500;
    height: 24px;
    width: 24px;
    padding: 0;
    line-height: 24px;
    text-align: center;
  }
}

.btn-show-cart__blur {
  height: 75px;
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0));
  z-index: 10;
}

.slide-enter-active, .slide-leave-active {
  transition: all .18s ease-in-out;
}

.slide-enter, .slide-leave-to {
  transform: translateY(100%) scale(0.95);
  opacity: 0;
}

.category-wrapper {
  .category__head {

    position: relative;
    //background: #f3f3f3;
    //border-top: 1px solid #fff;

    &::after {
      content: '';
      bottom: math.div($padding-wrapper, 2);
      left: $padding-wrapper;
      width: $padding-wrapper * 2;
      height: 4px;
      background: #222;
      position: absolute;
      border-radius: 0 1000px 1000px 0;
    }

    .title {

    }

    .subtitle {
      color: $gray;
    }
  }
}

</style>