<template>
    <transition :name="transitionName" v-if="!loading">
        <router-view :code="code"
                     :editing="isEditing"
                     v-on:save="postPaletteData"
                     v-on:capacity="saveCapacity"
                     v-on:name="saveName"
                     v-on:date="saveDate"
                     v-on:setCustomType="setCustomType"
                     v-on:setCollectiveFees="setCollectiveFees"
                     v-on:setPaletteMode="setPaletteMode"
                     v-on:address="saveAddress"/>
    </transition>
    <LoadingSpinner v-else/>
</template>

<script>
import PaletteService from "../../services/PaletteService";
import moment from "moment";
import {user} from "@/mixins/user";
import LoadingSpinner from "@/components/UX/LoadingSpinner";

export default {
    name: 'PalletManager',
    components: {LoadingSpinner},
    props: ["code"],
    mixins: [user],
    computed: {
        isEditing() {
            return this.code !== 'start';
        },
    },
    methods: {
        postPaletteData(redirect = false) {
            if (redirect) {
                this.loading = true;
            }
            return PaletteService.postPalette(this.palette).then((data) => {
                if (data.code) {
                    // eslint-disable-next-line
                    this.code = data.code;
                }
                console.log("Saved palette", data);
                this.loadPaletteFromCode();
                return this.$store.dispatch('LOAD_PALETTE', data.id);
            }).then(() => {
                if (redirect === true) {
                    this.$router.push('/palette');
                }
            }).finally(() => {
                setTimeout(() => {
                    this.loading = false;
                }, 300);
            })
        },
        saveCapacity(cardboards) {
            console.debug("save capacity", cardboards);
            this.palette.cardboardCapacity = cardboards;
            if (this.palette.id > 0) {
                this.postPaletteData(false);
            }
        },
        saveName(name) {
            console.debug("save name", name);
            this.palette.name = name;
            if (this.palette.id > 0) {
                this.postPaletteData(false);
            }
        },
        saveDate(range) {
            console.debug("save date", range);
            this.palette.startDate = range.start;
            this.palette.expirationDate = range.end;
            if (this.palette.id > 0) {
                this.postPaletteData(false);
            }
        },
        saveAddress(addressId) {
            console.debug("save address", addressId);
            this.palette.addressId = addressId;
            if (this.palette.id > 0) {
                this.postPaletteData(false);
            }
        },
        setPaletteMode(mode) {
            this.palette.mode = mode;
            if (this.palette.id > 0) {
                this.postPaletteData(false);
            }
        },
        moment: function () {
            moment.locale('fr');
            return moment();
        },
        setCollectiveFees(fees) {
            this.palette.cardboardCollectiveFees = fees;
            if (this.palette.id > 0) {
                this.postPaletteData(false);
            }
        },
        setCustomType(customBeerType, redirect) {
            this.palette.customBeerType = customBeerType;
            this.postPaletteData(redirect);
        },
        loadPaletteFromCode() {
            return PaletteService.getPalette(this.code).then((palette) => {
                this.palette = palette;
                if (this.palette.expirationDate === null) {
                    this.palette.expirationDate = this.defaultPalette.expirationDate;
                }
                console.log(this.palette)
                this.loading = false;
            }).catch(() => {
                this.$router.push('/404');
            });
        }
    },
    created() {
        this.loading = true;
        if (this.isEditing) {
            console.debug("Pallet edition mode");
            this.loadPaletteFromCode().then(() => {
                if (!this.$store.getters.isGodfatherPalette(this.palette)) {
                    this.$router.push('/palette');
                }
            });
        } else {
            console.debug("NEW");
            this.palette = this.defaultPalette;
            if (!this.palette.mode && this.$route.name !== 'manager-mode') {
                this.$router.push('/palette/' + this.code + '/parametres/mode');
            }
            setTimeout(() => {
                this.loading = false;
            }, 300);
        }
    },
    data() {
        return {
            palette: {},
            defaultPalette: {
                id: 0,
                mode: null, // standard / collective
                name: 'La palette de ' + this.$store.state.user.firstName,
                startDate: moment().format('YYYY-MM-DD hh:mm'),
                expirationDate: moment().add(14, 'days').format('YYYY-MM-DD hh:mm'),
                defaultAddress: null,
                cardboardCapacity: 120,
                cardboardCollectiveFees: 2.40,
                cardboardMinimumCapacity: 40,
                cardboardMaximumCapacity: 120,
            },
            loading: false,
            transitionName: 'slide-left'
        }
    },
    beforeRouteUpdate(to, from, next) {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
        next()
    },
}
</script>
