<template>
    <div class="master scrollable" v-if="!loading">
        <div v-if="banner && dspBanner" class="top-banner" v-on:click="dspBanner = false">
            <div v-html="banner"></div>
        </div>
        <div class="wrapper flex" v-if="palette">
            <div class="pallet-board-header">
                <h1 class="title size-4">
                    {{ palette.name }}
                    <router-link v-if="this.$store.getters.isGodfatherPalette(palette)" class="settings right"
                                 :to="'/palette/' + palette.code + '/parametres'">
                        <i class="ic icon-adjust"></i>
                    </router-link>
                </h1>

                <div class="progress_message">
                    <p v-html="progress_message"></p>
                </div>

                <PalletProgressBar
                        v-bind:showGifts="this.$store.getters.isGodfatherPalette(palette) && palette.mode === 'standard'"
                        v-bind:max="palette.cardboardCapacity"
                        v-bind:goal="palette.cardboardCapacity"
                        v-bind:current="palette.currentCardBoardCounter"/>
            </div>

            <router-link :to="'/palette/' + palette.code" class="palette-board-screen">
                <div v-if="palette.cardboardCollectiveFees > 0 && palette.mode === 'collective'"
                     class="porcherie">
                    <img v-bind:src="require('@/assets/img/piggy-bank.svg')"/>
                    <span class="collected-amount" v-if="palette.currentCollectiveFeesAmount">
                        {{ $_.round(palette.currentCollectiveFeesAmount) }}€ collectés
                    </span>
                    <span class="collected-amount" v-else>
                        Récoltez des fonds !
                    </span>
                </div>
                <PalletModel3D
                        :classname="isOpened ? 'opened' : 'closed'"
                        :cartons="palette.currentCardBoardCounter"
                        :rotate="isOpened">
                </PalletModel3D>
                <div class="delivery-end-container" v-if="displayCountdown">
                    <div class="delivery-countdown">
                        <div class="days">J-{{ deliveryCountdownDays }}</div>
                        <div class="hours">{{ deliveryCountdownHours }}</div>
                    </div>
                </div>
                <div v-else-if="palette.state > this.STATE_OPEN" class="delivery-end-container">
                    <div class="delivery-end-message">
                        <p v-html="end_message"></p>
                    </div>
                </div>
                <div v-else-if="palette.state === this.STATE_PENDING_APPROVAL" class="delivery-end-container">
                    <div class="delivery-end-message" v-html="end_message"></div>
                </div>
            </router-link>

            <Modal v-if="collectiveFeesDetailsModal" v-bind:show-close-button=true
                   v-on:cancel="collectiveFeesDetailsModal = false">
                <div slot="body">
                    <img v-bind:src="require('@/assets/img/piggy-bank.svg')" width="100%"/>
                    <div class="center mt">
                        {{ this.palette.currentCollectiveFeesAmount }}€ collectés
                    </div>
                </div>
                <div slot="footer">
                    <button class="btn btn-link" @click="collectiveFeesDetailsModal = false">Fermer</button>
                </div>
            </Modal>

            <div class="members">
                <BoardMemberItem :member="null" :key="'member-add'"
                                 :to="'/palette/' + palette.code"/>
                <div class="members-wrapper" v-if="!loadingMembers">
                    <BoardMemberItem v-for="member in members" v-bind:member="member" v-bind:key="member.id"
                                     :to="'/palette/' + palette.code"/>
                </div>
                <div class="members-wrapper placeholder" v-else>
                    <BoardMemberItem v-for="n in 4" :key="n"/>
                </div>
            </div>
        </div>

        <div class="wrapper flex" v-else>
            <h1 class="title size-4">Pas de palette en cours</h1>
            <p>Mais ça ne devrait pas tarder...</p>
            <div class="palette-board-screen empty">
                <img :src="require('@/assets/img/pallet/pallet-empty.svg')"/>
            </div>
        </div>

        <div class="fixed-to-bottom wrapper">
            <template v-if="palette && canBeJoined">
                <a class="btn btn-l bg invert" v-on:click="shareLink"
                   v-if="this.canBeJoined && (canShare || this.isGodfatherPalette(palette))">
                    Inviter des amis
                </a>
                <router-link v-if="isOpened" to="/catalogue" class="btn btn-l bg">
                    Commander
                </router-link>
                <router-link v-else-if="isPendingApproval || isDraft"
                        to="/catalogue" class="btn btn-l bg">
                    Découvrir le catalogue
                </router-link>
            </template>
            <router-link v-else-if="nextPalette"
                    :to="'/rejoindre?code=' + nextPalette.code" class="btn btn-l multilines bg" >
                Rejoindre la palette<br>
                <small>{{ nextPalette.name }}</small>
            </router-link>
            <template v-else>
                <button @click="$refs.modalPaletteList.showPaletteList()" class="btn btn-l bg">
                    Rejoindre une autre palette
                </button>
            </template>
        </div>

        <AvatarSelector v-if="this.$store.getters.shouldAskUserAvatar" v-bind:user="user"
                        v-bind:modalOpen="this.$store.getters.shouldAskUserAvatar"
                        v-on:uploadEnd="loadMembers"
                        v-on:modalClosed="stopAvatarSelection"
                        v-on:cancel="stopAvatarSelection"/>

        <ModalPaletteList ref="modalPaletteList" />
    </div>
    <div class="master" v-else>
        <LoadingSpinner/>
    </div>
</template>

<script>
import {share} from "@/mixins/share";
import {palette} from "@/mixins/palette";
import PalletProgressBar from "@/components/UX/PalletProgressBar";
import BoardMemberItem from "@/components/List/BoardMemberItem";
import LoadingSpinner from "@/components/UX/LoadingSpinner";
import moment from "moment";
import PalletModel3D from "@/components/PalletModel3D";
import AvatarSelector from "@/views/Profile/AvatarSelectorModal";
import Modal from "@/components/UX/Modal";
import {user} from "@/mixins/user";
import PaletteService from "@/services/PaletteService";
import ModalPaletteList from "@/components/List/ModalPaletteList";

export default {
    name: 'PalletBoard',
    mixins: [share, palette, user],
    components: {
        ModalPaletteList,
        Modal, AvatarSelector, PalletModel3D, LoadingSpinner, PalletProgressBar, BoardMemberItem},
    data() {
        return {
            loading: true,
            loadingMembers: false,
            now: moment(),
            dspBanner: true,
            nextPalette: null,
            avatar_selection_mode: false,
            collectiveFeesDetailsModal: false,
            modalPaletteList: false,
            palettesAvailable: [],
            palettesListLoaded: false,
        }
    },
    computed: {
        cardboardCounterSpan() {
            return '<span class="highlight"><span>' + this.palette.currentCardBoardCounter + ' carton' + (this.palette.currentCardBoardCounter > 1 ? 's' : '') + '</span></span>';
        },
        end_message() {
            if (this.palette.state === this.STATE_PREPARATION) {
                return "La palette est en cours de préparation.";
            } else if (this.palette.state === this.STATE_SHIPMENT) {
                return "La palette a été expédiée.";
            } else if (this.palette.state === this.STATE_CLOSE) {
                return "La palette est terminée.";
            } else if (this.palette.state === this.STATE_PENDING_APPROVAL) {
                if (this.isGodfatherPalette(this.palette)) {
                    return "<b><i class='ic icon-notification'></i> La palette est en attente de validation.</b> <br>Nous revenons vers vous sous 24h max !";
                } else {
                    return "<b>La palette n'est pas encore ouverte.</b><br>Revenez bientôt !";
                }
            }

            return "";
        },
        progress_message() {
            if (this.palette.state === this.STATE_PREPARATION) {
                return this.cardboardCounterSpan + " ! " +
                    this.$store.getters.ENV_DATA('cardboard_progress_preparation', 'En cours de préparation, bientôt à destination 😃🍺');
            } else if (this.isShipped) {
                return this.cardboardCounterSpan + " ! " +
                    this.$store.getters.ENV_DATA('cardboard_progress_shipping', 'La palette a été expédiée ! 😃🍺');
            } else {
                if (this.palette.currentCardBoardCounter === 0) {
                    return this.$store.getters.ENV_DATA('cardboard_progress_0', 'Vite, des cartons ! Des cartons !');
                } else if (this.palette.currentCardBoardCounter >= 100) {
                    return this.cardboardCounterSpan + " sur la palette !" + this.$store.getters.ENV_DATA('cardboard_progress_100', '🎵🎵 Youuu are the chaaaaampions ! 🎵🎵');
                } else if (this.palette.currentCardBoardCounter >= 80) {
                    return this.cardboardCounterSpan + " ! " +
                        this.$store.getters.ENV_DATA('cardboard_progress_80', 'Arrivé à ce stade, plus le choix, il faut la remplir à bloc ! 💪');
                } else if (this.palette.currentCardBoardCounter >= 60) {
                    return this.cardboardCounterSpan + " ! " +
                        this.$store.getters.ENV_DATA('cardboard_progress_60', 'Jusqu\'où vous arrêterez-vous !');
                } else if (this.palette.currentCardBoardCounter >= 40) {
                    return this.cardboardCounterSpan + " ! " +
                        this.$store.getters.ENV_DATA('cardboard_progress_40', 'La palette est livrable, mais ne vous arrêtez pas en si bon chemin 😉');
                } else if (this.palette.currentCardBoardCounter >= 10) {
                    return "Déjà " + this.cardboardCounterSpan + " sur la palette ! " +
                        this.$store.getters.ENV_DATA('cardboard_progress_10', 'ça monte, ça monte ! 🚵');
                } else {
                    return "Déjà " + this.cardboardCounterSpan + " sur la palette ! " +
                        this.$store.getters.ENV_DATA('cardboard_progress_1', 'C\'est un bon début ! 🏃');
                }
            }
        },
        estimatedDelivery() {
            if (this.palette.deliveryDate) {
                return moment(this.palette.deliveryDate, 'YYYY-MM-DD hh:mm');
            }

            return moment(this.palette.expirationDate, 'YYYY-MM-DD hh:mm').add(5, 'days');
        },
        timeLeft() {
            return moment.duration(moment(this.palette.expirationDate, 'YYYY-MM-DD hh:mm').diff(this.now));
        },
        deliveryCountdownDays() {
            return this.timeLeft.days();
        },
        deliveryCountdownHours() {
            return [this.pad(this.timeLeft.hours()), this.pad(this.timeLeft.minutes()), this.pad(this.timeLeft.seconds())].join(':');
        },
        members() {
            return this.$store.getters.PALETTE_MEMBERS;
        },
        palette_image() {
            if (this.palette && this.palette.currentCardBoardCounter > 0) {
                const x = this.percentValue(this.palette.currentCardBoardCounter);
                const cardboardStep = Math.floor(x / 10) * 10;
                if (x > 0 && cardboardStep < 10) {
                    return require('@/assets/img/pallet/pallet-5.svg');
                } else {
                    return require('@/assets/img/pallet/pallet-' + cardboardStep + '.svg');
                }
            }

            return require('@/assets/img/pallet/pallet-empty.svg');
        },
        displayCountdown() {
            return this.timeLeft > 0 && this.timeLeft.days() <= 2 && this.isOpened;
        },
        banner() {
            return this.$store.getters.ENV_DATA('top-banner', null);
        }
    },
    methods: {
        shareLink() {
            try {
                this.browserShareLink(this.palette.code);
            } catch (err) {
                this.$router.push('/plus');
            }
        },
        loadMembers() {
            this.loadingMembers = true;
            this.$store.dispatch('LIST_MEMBERS').then(() => {
                this.loadingMembers = false;
            });
        },
        pad(s) {
            return (s < 10) ? '0' + s : s;
        },
        moment: function () {
            moment.locale('fr');
            return moment();
        },
        percentValue(value) {
            if (value > 0) {
                return value / this.palette.cardboardMaximumCapacity * 100;
            }
            return 0;
        },
        async onloadedPalette() {
            if (!this.members) {
                this.loadMembers();
            }
            this.nextPalette = await this.getNextPalette();
        },
        stopAvatarSelection() {
            this.$store.dispatch("confirmUserAvatar");
            this.loadMembers();
        }
    },
    mounted() {
        this.loading = true;
        if (!this.$store.getters.ACTIVE_PALETTE) {
            this.getCurrentPalette().then((data) => {
                this.loading = false;
                if (data !== null) {
                    this.onloadedPalette();
                } else {
                    console.log("placeholder pal board ?")
                }
            }).catch(e => {
                this.loading = false;
                console.log("placeholder pal board 2 ?")
            });
        } else {
            this.loading = false;
            this.onloadedPalette();
        }

        setInterval(() => {
            this.now = moment();
        }, 1000);

        if (this.$store.getters.shouldAskUserAvatar) {
            this.avatar_selection_mode = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "./src/styles/vars";

.wrapper.flex {
  padding-bottom: 6px;
}

.pallet-board-header {
  flex: 0;

  .title {
    padding-top: 0;
    padding-bottom: 0;

    + p {
      margin-bottom: 15px;
    }
  }
}

.settings {
  font-size: 16px;
  margin-top: 4px;
}

.palette-board-screen {
  height: 100%;
  max-height: 70vh;
  position: relative;
  display: block;
  text-align: center;
  flex-grow: 1;
  margin-left: -$padding-wrapper;
  margin-right: -$padding-wrapper;

  > img {
    max-height: 35vh;
    max-width: 100%;
    width: 250px;
    animation: flying 1.5s ease-in-out infinite alternate;
  }

  &.empty {
    opacity: 0.5;
  }
}

@keyframes flying {
  from {
    transform: translateY(0)
  }
  to {
    transform: translateY(15px)
  }
}

.fixed-to-bottom {
  padding-top: 0;
}

.members {
  display: flex;
  flex-direction: row;
  flex: 0;
  margin-top: auto;
  align-items: flex-end;
  overflow: auto;
  width: calc(100% + 48px);
  padding: 4px 0;
  flex-basis: 80px;
  min-height: 80px;
  margin-left: -$padding-wrapper;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > .member-item:first-child {
    margin-left: $padding-wrapper;
  }

  .member-item:last-child {
    margin-right: 0;
    padding-right: $padding-wrapper;
    width: 42px + $padding-wrapper;
  }

  .members-wrapper {
    width: 100%;
    display: flex;
    position: relative;

    &.placeholder {
      max-width: 60%;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to right, transparent, #fff);
      }
    }
  }
}

.delivery-end-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  text-align: center;
}

.delivery-countdown {
  background: #000;
  color: #fff;
  font-weight: 700;
  text-align: center;
  display: inline-block;

  .days {
    background: $primary;
  }

  .hours {
    width: 67px;
  }

  > div {
    padding: 4px 8px;
    display: inline-block;
  }
}

.porcherie {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: $padding-wrapper;
  z-index: 100;
  width: auto;
  padding: 4px 10px;
  border-radius: 10px;
  background: lighten($body-color, 10);

  img {
    max-width: 100%;
    width: 28px;
    margin-right: 10px;
  }

  .collected-amount {
    display: block;
    padding: 4px 0;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    color: $gray;
  }

  .cochon {

  }
}

</style>