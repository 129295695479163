export const share = {
    data() {
        return {
            code: null,
            baseurl: null,
        }
    },
    computed: {
        canShare() {
            return this.$store.getters.CAN_SHARE || this.$store.getters.isGodfather;
        }
    },
    methods: {
        browserShareLink(code) {
            var sharedUrl = this.getLinkUrl(code);
            var sharedTitle = this.$store.getters.ENV_DATA('invite-title', 'Rejoins ma palette de bières de la brasserie BOS !');
            var sharedBody;

            if (this.$gtag) {
                this.$gtag.event('share');
            }

            if (this.$store.getters.isGodfather) {
                sharedBody = this.$store.getters.ENV_DATA('invite-body-parrain', 'J\'organise une commande groupée de bières artisanales direct producteur. ' +
                    'Rejoins ma palette avec le code ' + code) + " ";
            } else {
                sharedBody = this.$store.getters.ENV_DATA('invite-body', 'Participe à ma palette de bières artisanales ! Une commande groupée direct producteur') + " ";
            }

            if (navigator.share) {
                navigator.share({
                    title: sharedTitle,
                    text: sharedBody,
                    url: sharedUrl,
                });
            } else {
                window.location.href = 'mailto:?subject=' + sharedTitle + '&body=' + sharedBody + "%20" + this.baseurl + sharedUrl;
            }
        },
        getLinkUrl(code) {
            return "/onboarding/invite?code=" + code + "&referral=" + this.$store.getters.USER.id;
        },
    },
    created() {
        this.baseurl = window.location.protocol + "//" + window.location.host;
    }
}