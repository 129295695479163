<template>
    <div class="master scrollable" v-if="!loading">
        <TopBar back="/plus" title="Classement des palettes"/>
        <div class="wrapper">
            <h1 class="title size-5">The best of the best</h1>
            <p class="subtitle">Le classement des meilleures palettes</p>
            <div class="m_spacer"></div>
            <div class="rank-line" :class="palette_active && palette_active.id === data.id ? 'selected' : ''"
                 v-for="(data, i) in palettes" v-bind:key="data.id">
                <div v-if="i <= 2" class="palette-rank trophy" :class="'rank' + (i + 1)">
                    <span class="ic icon-trophy"></span>
                </div>
                <div v-else class="palette-rank">
                    <span>{{ i + 1 }}</span>
                </div>

                <div class="avatar">
                    <img :src="data.image || require('@/assets/img/people/parrain.svg')"/>
                </div>

                <div class="palette-info">
                    <div class="godfather">{{ data.firstName }} {{ data.lastName.charAt(0) }}</div>
                    <div class="palette-title">{{ data.name }}</div>
                </div>
                <div class="palette-cartons">{{ data.cartons }}</div>
            </div>
        </div>
    </div>
    <LoadingSpinner v-else/>
</template>
<script>
import TopBar from "@/components/UX/TopBar";
import LoadingSpinner from "@/components/UX/LoadingSpinner";
import PaletteService from "@/services/PaletteService";

export default {
    components: {LoadingSpinner, TopBar},
    data() {
        return {
            loading: true,
            palettes: [],
            palette_active: null,
        }
    },
    mounted() {
        this.loading = true;
        PaletteService.getPaletteRanking().then((data) => {
            this.palettes = data;
            this.loading = false;
        });
    },
    created() {
        if (this.$store.getters.isAuth && !this.$store.getters.ACTIVE_PALETTE && parseInt(this.$store.getters.ACTIVE_PALETTE_ID) !== 0) {
            this.$store.dispatch('LOAD_PALETTE', this.$store.getters.ACTIVE_PALETTE_ID).then(() => {
                this.palette_active = this.$store.getters.ACTIVE_PALETTE;
            })
        } else {
            this.palette_active = this.$store.getters.ACTIVE_PALETTE;
        }
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

.rank-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  &.selected {
    border-radius: 18px;
    background: linear-gradient(to right, transparent, transparentize($primary, .9)) no-repeat;
    background-size: 100%;
    margin-right: math.div(-$padding-wrapper, 2);
    border-color: transparent;

    .palette-cartons {
      padding-right: math.div($padding-wrapper, 2);
    }
  }

  .palette-rank {
    text-align: center;
    width: 34px;
    font-weight: bold;
    color: gray;
    margin-right: 6px;

    &:not(.trophy) {
      border: 1px solid #eee;
      height: 34px;
      padding: 6px;
    }

    &.trophy .ic {
      font-size: 20px;
    }

    &.rank1 .ic {
      color: #f7cc45;
    }

    &.rank2 .ic {
      color: #b5b8bc;
    }

    &.rank3 .ic {
      color: #caa788;
    }
  }

  .palette-info {
    padding: math.div($padding-wrapper, 2);
    flex: 1;
    line-height: 1.2;

    .godfather {
      color: $gray;
      font-weight: bold;
    }

    .palette-title {
      color: lighten($gray, 5);
    }
  }

  .palette-cartons {
    font-weight: bold;
    font-size: 1.1rem;
    color: $primary;
  }

  .avatar {
    margin-left: 6px;
    margin-right: 6px;
    width: 42px;
    height: 42px;
    border-radius: 10000px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

}

</style>