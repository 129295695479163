<template>
    <div>
        <div class="member-item">
            <div class="member-item__profile">
                <img :src="memberImage"
                     @click="member.user.image ? $emit('showMemberImage', member.user.image) : showDetails"/>
            </div>
            <div class="member-item__content" @click="showDetails">
                <div class="member-item__title">{{ memberName }} <span v-if="itsMe">(vous)</span></div>
                <div class="member-item__subtitle">
                    {{ cardboardsCount }} carton{{ cardboardsCount > 1 ? 's' : '' }}
                </div>
            </div>
            <div class="member-item__icon" @click="showDetails">
                <i class="ic icon-next"></i>
            </div>
        </div>
        <Modal v-if="openModal">
            <div slot="header">
                <h3 class="title size-5">
                    {{ memberFullName }}
                    <small v-if="memberPhoneLink" v-html="'&bullet; ' + memberPhoneLink"></small>
                </h3>
                <p class="subtitle">Commandes sur cette palette</p>
            </div>
            <div slot="body">
                <div v-if="orders.length > 0">
                    <OrderListItemLight v-for="order in orders"
                                        v-bind:member="member"
                                        v-bind:order="order"
                                        v-bind:key="order.id "/>
                </div>
                <div v-else>
                    <p class="disabled">Aucune commande pour le moment</p>
                </div>
            </div>
            <div slot="footer">
                <button class="btn btn-link" @click="openModal = false">Fermer</button>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from "../UX/Modal";
import OrderListItemLight from "../List/OrderListItemLight";
import Api from "../../services/Api";
import {parsePhoneNumberFromString} from "libphonenumber-js";

export default {
    name: 'ListMemberItem',
    props: ['member'],
    components: {
        Modal, OrderListItemLight
    },
    computed: {
        memberName() {
            return this.member.user.publicName;
        },
        memberFullName() {
            return this.member.user.fullName;
        },
        memberImage() {
            return this.$store.getters.getMemberImage(this.member.user);
        },
        itsMe() {
            return this.$store.getters.USER.id === this.member.user.id;
        },
        cardboardsCount() {
            return this.member.cardboards;
        },
        memberPhoneLink() {
            if (this.member.user.mobile) {
                return '<a href="tel:' + this.member.user.mobile + '">' + this.formattedNumber(this.member.user.mobile) + '</a>';
            }

            return null;
        },
    },
    methods: {
        fetchOrders() {
            return Api.get('/member-order/' + this.member.id)
        },
        showDetails() {
            this.loading = true;
            this.openModal = true;
            this.fetchOrders().then((orders) => {
                this.loading = false;
                this.orders = orders;
            });
        },
        formattedNumber(phone) {
            const phoneNumber = parsePhoneNumberFromString(phone, 'FR');
            if (phoneNumber) {
                return phoneNumber.formatNational();
            }
            return phone;
        }
    },
    data() {
        return {
            openModal: false,
            orders: [],
        }
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

.member-item {
  border-bottom: 1px solid $line-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $font-body-alt;
  padding: math.div($padding-wrapper, 2) 0;
  cursor: pointer;

  &:active {
    background: linear-gradient(to left, #fafafa, #fff);
    color: #000;

    .member-item__icon {
      transform: translateX(-15px);
    }
  }

  .member-item__profile {
    width: 42px;
    height: 42px;
    margin-right: math.div($padding-wrapper, 2);
    border-radius: 1000px;
    overflow: hidden;
    background: #eee;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .member-item__content {
    flex: 1;

    .member-item__title {
      font-size: 1.12rem;
      font-weight: 500;
      color: #000;
    }

    .member-item__subtitle {
      font-size: 0.875rem;
      color: $gray;
    }
  }

  .member-item__icon {
    font-size: 1rem;
    color: $gray;
    line-height: 1;
    transition: transform .2s;
  }

}
</style>