<template>
    <div class="master scrollable" v-if="!loading">
        <TopBar v-bind:back="'/catalogue'" v-bind:title="'Panier'"/>
        <div class="wrapper">
            <div class="cart-item-list" v-if="nbItems">
                <p class="title">Votre commande</p>
                <p class="subtitle">{{ nbItems }} produit{{ nbItems > 1 ? 's' : '' }}</p>
                <hr>
                <CartItem v-for="product in productsInCart"
                          v-on:addToCart="addToCart"
                          v-on:removeFromCart="removeFromCart"
                          v-bind:product="product"
                          v-bind:paletteCardboardCollectiveFees="getCardboardCollectiveFees"
                          v-bind:key="product.id"/>
            </div>
            <div v-else>
                <p>Votre panier est vide !</p>
            </div>

            <div class="additional-products" v-if="additional_products.length">
                <p class="title">Complétez votre commande</p>
                <p class="subtitle">Ces produits devraient vous plaire !</p>
                <hr>
                <div v-for="additional_product in additional_products" v-bind:key="additional_product.id"
                     :data-category="additional_product.id"
                     :ref="'product_' + additional_product.id">
                    <AdditionalShopItem
                            v-on:addToCart="addToCart"
                            v-on:removeFromCart="removeFromCart"
                            v-bind:product="additional_product"
                            v-bind:key="additional_product.id"/>
                </div>
            </div>

        </div>
        <div class="wrapper fixed-to-bottom" v-if="nbItems">
            <div class="cart-summary">
                <div class="cart-summary__line total">
                    <div class="label">Total à payer</div>
                    <div class="value">{{ order_amount + '€' }}</div>
                </div>
                <div class="cart-summary__line">
                    <div class="label">Dont TVA</div>
                    <div class="value">{{ tva_amount + '€' }}</div>
                </div>
            </div>
            <div v-if="nbItems < palette.minimumOrderQuantity" class="box-error error mb">
                <i class="ic icon-alert-1"></i> &nbsp;La quantité minimale par commande est de
                {{ palette.minimumOrderQuantity }} produits.
            </div>

            <router-link to="/commander" class="btn btn-l bg"
                         :class="nbItems < palette.minimumOrderQuantity ? 'disabled' : ''">
                Continuer
            </router-link>
        </div>
        <div class="wrapper fixed-to-bottom" v-else>
            <router-link to="/catalogue" class="btn btn-l">Voir le catalogue</router-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>

@import "./src/styles/vars";

hr {
  margin: 12px 0;
  border-top: 0;
  border-bottom: 1px solid #ddd;
}

.cart-summary {
  padding: $padding-wrapper 0;

  .cart-summary__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
    color: $gray;

    &.total {
      font-size: 1.2rem;
      font-weight: 500;
      color: #000;
    }

    .value {
      text-align: right;
    }
  }
}

.box-error {
  padding: 1rem;
  border: 1px solid $red;
}

.additional-products {
  padding: $padding-wrapper 0;
}
</style>

<script>
import TopBar from "@/components/UX/TopBar";
import CartItem from "@/components/Shop/CartItem";
import {palette} from "@/mixins/palette";
import {cart} from "@/mixins/cart";
import ShopItem from "@/components/Shop/ShopItem";
import AdditionalShopItem from "@/components/Shop/AdditionalShopItem";

export default {
    name: "Cart",
    components: {
        AdditionalShopItem,
        TopBar, CartItem
    },
    data() {
        return {
            loading: false,
        }
    },
    mixins: [palette, cart],
    computed: {
        additional_products() {
            return this.$store.getters.PRODUCTS.filter(product => product.category === 'additional');
        },
    },
    created() {
        this.getCurrentPalette().then(palette => {
            this.loading = true;
            this.$store.dispatch('LOAD_CATALOG', palette.id).then(async () => {
                await this.fetchCart();
                this.loading = false;
            });
        }).catch(e => {
            this.loading = false;
        })
    }
}

</script>