<template>
    <div v-if="!waiting">
        <h1 class="title mb">Rejoindre une palette</h1>
        <p>
            Saisissez le <span class="highlight"><span>code de votre parrain</span></span> pour rejoindre sa palette, ou
            cliquez sur le lien qu'il vous a partagé. Pas de code ?
            <a :href="'mailto:' + contact_email " class="_link">Contactez-nous</a>
        </p>
        <div class="field">
            <div class="label">Le code de votre palette</div>
            <input type="text" class="input" placeholder="ABC123" v-model="register_code" @keyup.enter.prevent="nextStep" v-focus/>
        </div>
        <div class="field">
            <button type="button" class="btn btn-l" :class="{loading:loading}" @click="nextStep()">
                Rejoindre une palette
            </button>
        </div>
        <hr class="mb mt">
        <h1 class="title mb">Créer une nouvelle palette</h1>

        <div class="center mt">
            <button type="button" class="btn btn-l invert" :class="{loading:loading}" @click="setNewPalette()">
                Créer un compte
            </button>
            <div class="center mt">
                <router-link to="/connexion" class="small">
                    Déjà un compte ? <span class="_link">Connectez-vous</span>
                </router-link>
            </div>
        </div>
    </div>
    <LoadingSpinner v-else/>
</template>

<script>
import {Ui} from "@/class/Ui";
import PaletteService from "../../../services/PaletteService";
import LoadingSpinner from "@/components/UX/LoadingSpinner";

export default {
    name: 'RegisterCode',
    components: {LoadingSpinner},
    props: ['user'],
    data() {
        return {
            register_code: '',
            loading: false,
            waiting: true
        }
    },
    methods: {
        setNewPalette(){
            this.setCode('start').then(() => {
                this.$router.push('/inscription/email');
            }).finally(() => {
                this.loading = false;
            });
        },
        nextStep() {
            console.debug("next step");
            if(!this.register_code.length) {
                Ui.notif("Renseignez le code palette", "error");
                return false;
            }

            this.loading = true;
            return this.checkCode(this.register_code).then((response) => {
                if (response === true) {
                    this.setCode(this.register_code).then(() => {
                        this.$router.push('/inscription/email');
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    Ui.notif("Le code palette n'est pas valide", "error");
                    this.loading = false;
                }
            })
        },
        checkCode(code) {
            if (code && code.length > 0) {
                return PaletteService.checkoutCode(code).then(async data => {
                    if (data.available === true && data.palette !== null) {
                        await this.$store.dispatch('SET_PALETTE', data.palette);
                        return true;
                    }
                }).catch(err => false);
            }
            return false;
        },
        setCode(code) {
            return new Promise((resolve) => {
                this.$emit("setCode", code);
                resolve();
            })
        }
    },
    computed: {
        contact_email() {
            return process.env.VUE_APP_CONTACT_EMAIL;
        }
    },
    created() {
        if (this.user.code && this.user.code !== 'start') {
            this.waiting = true;
            this.register_code = this.user.code;
            // on passe l'étape s'il y a un code
            this.nextStep().then(() => {
                this.waiting = false;
            })
        } else {
            this.waiting = false;
        }
    }
}
</script>
