<template>
    <div class="scrollable onboarding" v-if="invitation && invitation.available">
        <div class="steps" ref="steps-container" id="onboarding-steps">
            <Step ref="steps"
                  v-bind:image="step.image"
                  v-bind:title="step.title"
                  v-bind:intro="textIntro"
                  v-bind:user="parrain"/>
        </div>
        <div class="double-buttons-container">
            <div>
                <router-link class="btn btn-l"
                             :to="'/inscription?code=' + this.code + (this.referral ? '&referral=' + this.referral : '')">
                    Rejoindre la palette
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Step from "@/components/Onboarding/Step";
import PaletteService from "../../services/PaletteService";
import {Ui} from "@/class/Ui";
import moment from "moment";

export default {
    name: 'OnboardingInvited',
    components: {
        Step,
    },
    data() {
        return {
            step: {
                image: "img/pallet.svg",
                title: "La Palette",
                intro: null,
                active: true,
            },
            invitation: null,
            current: 1,
            maxStep: 1,
            code: null,
            referral: null,
        }
    },
    computed: {
        htmlSteps: function () {
            return this.$refs['steps'];
        },
        stepWidth: function () {
            return this.htmlSteps[0].$el.clientWidth;
        },
        parrainName() {
            return this.parrain.firstName + " " + this.parrain.lastName;
        },
        dateLimit() {
            return this.palette ? this.palette.expirationDate : null;
        },
        parrain() {
            return this.invitation.user;
        },
        palette() {
            return this.invitation.palette;
        },
        textIntro() {
            let default_text = "<b>" + this.parrainName + "</b> vous invite à sa <span class='color'>commande groupée exclusive de bières</span>, direct du producteur, prix pro et sans frais de ports.<br>";
            if (this.dateLimit) {
                moment.locale('fr');
                default_text += "<b>Date limite de commande : " + moment(this.dateLimit).format("DD/MM/YYYY") + "</b><br>";
            }
            default_text += "Merci " + this.parrain.firstName;
            return this.$store.getters.ENV_DATA("invited_intro", default_text);
        }
    },
    methods: {
        checkoutCode(code) {
            if (code.length > 0) {
                return PaletteService.checkoutCode(code)
            }
            return false;
        },
    },
    async created() {
        this.code = await this.$route.query.code;
        this.referral = await this.$route.query.referral;
        if (!this.code) {
            console.log("empty code");
            await this.$router.push('/onboarding');
            return;
        }

        if (this.$store.getters.isAuth) {
            // l'utilisateur est déjà connecté
            //const palette = await this.$store.dispatch('LOAD_PALETTE');
            const palette = this.$store.getters.ACTIVE_PALETTE;
            // une seule palette active à la fois
            if (palette !== null) {
                await this.$router.push('/rejoindre?code=' + this.code);
                return;
            }
        }

        this.invitation = await this.checkoutCode(this.code);

        console.log("invitation", this.invitation);

        if (this.invitation && !this.invitation.available && this.invitation.palette) {
            // La palette existe, mais n'est pas disponible.
            Ui.notif("Impossible de rejoindre " + this.invitation.palette.name, "error");
        }

        if (!this.invitation || !this.invitation.available) {
            await this.$router.push('/onboarding');
            return;
        }

        if (this.code.length && this.code !== "start") {
            this.$_.cookies_set("palette_register_code", this.code, 86400, "/");
        } else {
            this.$_.cookies_remove("palette_register_code");
        }

        await this.$store.dispatch('SET_PALETTE', this.invitation.palette);
    }
}
</script>
<style src="./Onboarding.scss" lang="scss" scoped/>
