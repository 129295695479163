<template>
    <div class="master scrollable">
        <TopBar :back="editing ? backUrl : 'back'" title="Lieu de livraison"/>
        <div class="wrapper form">
            <div class="title size-4">Une palette, ce n'est pas petit !</div>
            <p class="b">
                Pour que La Palette puisse être livrée vous devez disposez de :
            </p>
            <ul>
                <li><i class="ic icon-check"></i> Au moins 10m² pour assurer la dépose</li>
                <li><i class="ic icon-check"></i> Accessible à un camion 10T</li>
                <li><i class="ic icon-check"></i> Pas de forte pente sur le terrain de dépose</li>
            </ul>
            <hr class="mt mb">
            <div class="field">
                <label class="label">Vos adresses enregistrées</label>
                <div class="address-list" v-if="addresses.length > 0">
                    <div class="address-list-item" v-for="address in addresses" v-bind:key="address.id">
                        <input :id="'adr_' + address.id" type="radio" name="current_address" :value="address.id"
                               class="custom-radio" :checked="address.id === palette.addressId"
                               @change="setAddress(address.id)">
                        <label :for="'adr_' + address.id" class="address-list-item__content">
                            <div class="b">{{ address.address }}</div>
                            <div v-if="address.address2">{{ address.address2 }}</div>
                            <div>{{ address.zipCode }} {{ address.city }}</div>
                        </label>

                        <a @click="editAddressModal(address)" class="_link">Modifier</a>
                    </div>
                </div>
                <div v-else>
                    <p>Aucune adresse</p>
                </div>
                <a @click="addAddressModal" class="btn btn-link text-left">+ Ajouter une adresse</a>
            </div>

            <Modal v-if="showModal">
                <h3 slot="header" class="address-title">
                    <span>Adresse de livraison</span>
                    <button class="btn btn-s invert right"
                            v-if="current_address.id && this.palette.addressId !== current_address.id"
                            @click.prevent="deleteAddress(current_address)">
                        Supprimer
                    </button>
                </h3>
                <form class="form" slot="body">
                    <input type="hidden" v-model="current_address.id" value="0"/>
                    <div class="field">
                        <label class="label">Adresse</label>
                        <input type="text" class="input" v-model="current_address.address">
                    </div>
                    <div class="field">
                        <label class="label">Complément d'adresse</label>
                        <input type="text" class="input" v-model="current_address.address2">
                    </div>
                    <div class="field">
                        <label class="label">Code postal</label>
                        <input type="text" class="input" v-model="current_address.zipCode">
                    </div>
                    <div class="field">
                        <label class="label">Ville</label>
                        <input type="text" class="input" v-model="current_address.city">
                    </div>
                    <div class="field">
                        <label class="label">Instructions de livraison</label>
                        <textarea class="input"
                                  placeholder="Consignes au livreur, n° de tel à contacter et nom du contact..."
                                  v-model="current_address.comment"></textarea>
                    </div>
                </form>
                <div slot="footer">
                    <button class="btn bg" ref="btnSave" @click="saveAddress">Enregistrer</button>
                    <button class="btn btn-link" @click="showModal = false">Annuler</button>
                </div>
            </Modal>
        </div>
        <div class="wrapper fixed-to-bottom">
            <button class="btn bg btn-l" :class="editing ? 'invert' : ''" ref="btnNext" @click="next" v-if="!editing">
                Confirmer le lieu
            </button>
            <div class="illustration">
                <img :src="require('@/assets/img/ui/lieujaune.png')" alt="Le lieu"/>
                <span class="handwriting">Un lieu</span>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import Modal from "../../components/UX/Modal";
import AddressService from "../../services/AddressService";
import {Ui} from "@/class/Ui";

export default {
    name: 'PalletManagerAddress',
    props: ['code', 'editing'],
    components: {
        TopBar,
        Modal
    },
    data() {
        return {
            palette: null,
            backUrl: '/palette/' + this.code + '/parametres',
            addresses: [],
            showModal: false,
            default_address: null,
            current_address: {
                id: 0,
                address: '',
                address2: '',
                zipCode: '',
                city: '',
                country: '',
                comment: '',
            }
        }
    },
    methods: {
        next() {
            if (this.editing) {
                this.$router.push(this.backUrl);
            } else {
                this.$router.push('/palette/' + this.code + '/parametres/custom');
            }
        },
        editAddressModal(address) {
            this.showModal = true;
            this.current_address = address;
        },
        addAddressModal() {
            this.showModal = true;
            this.current_address = this.default_address;
        },
        deleteAddress(address) {
            return AddressService.deleteAddress(address.id)
                .then(() => this.fetchAddressList())
                .catch((error) => {
                    Ui.notif(error.message, "error");
                    console.error(error)
                })
                .finally(() => {
                    this.showModal = false;
                });
        },
        async saveAddress() {
            this.$refs.btnSave.classList.add('loading');

            if (this.current_address.id > 0) {
                await AddressService.editAddress(this.current_address);
            } else {
                const resp = await AddressService.createAddress(this.current_address);
                console.log("resp", resp);
                await this.setAddress(resp.id);
            }

            await this.fetchAddressList();
            this.showModal = false;
        },
        fetchAddressList() {
            return AddressService.getAddresses().then((data) => {
                this.addresses = data;
            });
        },
        async setAddress(addressId) {
            this.$emit('address', addressId);
        }
    },
    created() {
        this.palette = this.$parent.palette;
        if (this.palette == null) {
            this.$router.push('/palette/' + this.code + '/parametres');
        }
    },
    mounted() {
        if (this.palette == null || this.palette.expirationDate == null) {
            this.$router.push('/palette/' + this.code + '/parametres');
        }
        this.default_address = this.current_address;
        this.fetchAddressList().then(() => {
            if (this.palette.addressId == null && this.addresses.length > 0) {
                this.palette.addressId = this.addresses[0].id;
            }
        })
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

ul li .ic {
  margin-right: 4px;
  color: $gray;
}

.illustration {
  text-align: center;

  img {
    height: 150px;
    max-width: 100%;
  }

  .handwriting {
    font-family: $font-handwriting;
    padding: 5px;
    display: block;
    color: $gray;
  }
}

.address-list-item {
  padding: math.div($padding-wrapper, 2) 0;
  font-family: $font-body;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid $line-color;
  }

  ._link {
    margin-left: auto;
  }
}

.address-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    flex: 2;
  }

  > .btn {
    flex: 0;
    margin-left: auto;
  }
}
</style>