<template>
    <div class="master">
        <TopBar title="Rejoindre une palette" back="/palette"/>
        <div class="wrapper">
            <h1 class="title">Rejoindre une palette</h1>
            <p class="subtitle mb">Saisissez le code de votre parrain pour rejoindre sa palette.</p>
            <form @submit.prevent="subscribeToPallet" ref="form" v-if="!waiting">
                <div class="field">
                    <div class="label">Code de la palette</div>
                    <input type="text" class="input" placeholder="ABC123" ref="code" v-model="code"/>
                </div>
                <div class="field">
                    <button type="submit" class="btn btn-l" :class="loading">Rejoindre la palette</button>
                </div>
            </form>
            <LoadingSpinner v-else/>
        </div>
    </div>
</template>

<script>
import PaletteService from "../../services/PaletteService";
import TopBar from "../../components/UX/TopBar";
import {Ui} from "@/class/Ui";
import LoadingSpinner from "@/components/UX/LoadingSpinner";

export default {
    name: 'Join',
    components: {LoadingSpinner, TopBar},
    data() {
        return {
            code: this.$route.query.code ?? '',
            loading: false,
            waiting: false,
        }
    },
    computed: {
        user() {
            return this.$store.getters.USER;
        },
    },
    methods: {
        subscribeToPallet() {
            this.loading = true;
            return PaletteService.subscribe(this.code)
                .then((resp) => {
                    return this.loadAndGoToPalette(resp.id, true)
                })
                .catch(async (error) => {
                    const alreadyJoined = await PaletteService.getPaletteList().then((list) => {
                        return list.find(p => {
                            return p.code === this.code;
                        });
                    });

                    if (alreadyJoined && alreadyJoined.id > 0) {
                        // L'utilisateur a déjà rejoint cette palette
                        this.loadAndGoToPalette(alreadyJoined.id, false);
                    } else {
                        this.loading = false;
                        Ui.notif(error, "error");
                    }
                });
        },
        loadAndGoToPalette(id, message = true) {
            return this.$store.dispatch('LOAD_PALETTE', id).then(() => {
                this.loading = false;
                if (message) {
                    Ui.notif("Vous avez rejoint la palette", "success");
                }
                if (this.code && !localStorage.getItem('welcome-' + this.code)) {
                    localStorage.setItem('welcome-' + this.code, '1');
                    this.$router.push('/welcome-about');
                } else {
                    this.$router.push('/palette');
                }
            });
        }
    },
    created() {
        if (this.code) {
            this.waiting = true;
            if (this.code === 'start') {
                this.$router.push('/palette/start/parametres');
            } else {
                this.subscribeToPallet().finally(() => {
                    this.waiting = false;
                })
            }
        } else {
            this.waiting = false;
        }
    },
    mounted() {
        if (this.$refs.code) {
            this.$refs.code.focus();
        }
    }
}
</script>
