<template>
    <div class="master scrollable" v-if="palette">
        <TopBar :back="'back'" title="Choix du catalogue"/>
        <div class="wrapper flex">
            <div class="title size-4">
                Souhaitez vous ajouter une <span class="highlight"><span>bière personnalisée</span></span> au catalogue
                ?
            </div>
            <div class="mode-selector-container">
                <div class="mode-selector">
                    <input id="rd-select-mode-standard" type="radio" v-model="customizable" value="0" checked>
                    <label for="rd-select-mode-standard">
                        <img :src="require('@/assets/img/bos.png')"/>
                        <div class="selector-title">Le catalogue de la Brasserie Bos</div>
                        <p class="selector-subtitle handwriting">Environ 10 bières différentes, pour tous les goûts
                            !</p>
                        <div class="tag default-tag invert">PAR DÉFAUT</div>
                    </label>
                </div>

                <div class="mode-selector">
                    <input id="rd-select-mode-collective" type="radio" v-model="customizable" value="1">
                    <label for="rd-select-mode-collective">
                        <img :src="require('@/assets/img/cheers.svg')"/>
                        <div class="selector-title">Catalogue Bos + une bière personnalisée</div>
                        <p class="selector-subtitle handwriting">Avec une étiquette à votre image, parfait pour les
                            clubs !</p>
                    </label>
                </div>
            </div>

            <div v-if="isCustom">
                <p class="is_custom_text">
                    <b>La brasserie vous contactera par téléphone sous 24h pour personnaliser votre étiquette.</b><br>
                    Vous aurez également le choix du type de bière à personnaliser... Blonde, ambrée, IPA, c'est comme
                    vous voulez !
                </p>
            </div>
        </div>
        <div class="wrapper fixed-to-bottom">
            <button class="btn btn-l bg" :class="!isReady ? 'disabled' : ''" @click="save" ref="btnNext">Enregistrer
            </button>
        </div>
    </div>
</template>

<script>

import TopBar from "@/components/UX/TopBar";
import {Ui} from "@/class/Ui";

export default {
    name: 'PalletManagerCustom',
    props: ['code', 'editing'],
    components: {
        TopBar
    },
    data() {
        return {
            palette: null,
            backUrl: '/palette/' + this.code + '/parametres',
            customizable: false,
            beer_type: null,
            beers: [
                {
                    color: 'blonde',
                    title: 'Blonde',
                    description: 'Légère et maltée, une blonde en toute simplicité, qui plaira au plus grand nombre.'
                },
                {
                    color: 'ipa',
                    title: 'IPA',
                    description: 'Bien houblonnée, des notes florales et de fruits blancs soutenues par une belle amertume.'
                },
                {
                    color: 'ambree',
                    title: 'Ambrée',
                    description: 'Bière ambrée aux notes caramel, à la robe cuivrée et relevée par une légère amertume.'
                }
            ]
        }
    },
    computed: {
        isCustom() {
            return this.customizable === '1';
        },
        isReady() {
            return this.customizable === '1' || this.customizable === '0';
        }
    },
    methods: {
        save() {
            if (this.$refs.btnNext) {
                this.$refs.btnNext.classList.add('loading');
            }

            if (this.isCustom) {
                this.$emit('setCustomType', 'custom', true);
            } else {
                this.$emit('setCustomType', null, true);
            }

            this.$nextTick(() => {
                if (this.$refs.btnNext) {
                    this.$refs.btnNext.classList.remove('loading');
                }
                // this.$router.push('/palette');
            });
        },
    },
    mounted() {
        this.palette = this.$parent.palette;
        if (!this.palette || !this.palette.expirationDate) {
            this.$router.push('/palette/' + this.code + '/parametres');
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.mode-selector-container {
  display: flex;
  align-items: stretch;
  padding: 5px 0;
  flex-direction: row;
  margin-left: -5px;
  margin-right: -5px;

  @media(max-width: $width-m) {
    flex-direction: column;
  }
}

.mode-selector {
  flex: 1;
  margin: 5px;

  input[type="radio"] {
    display: none;
  }

  label {
    text-align: center;
    padding: $padding-wrapper;
    border: 3px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    height: 100%;
    transition: all .2s;
    position: relative;
    background: #fff;
  }

  .default-tag {
    position: absolute;
    left: 5px;
    top: 5px;
    border-color: $green;
    background: $green;
    color: #fff;
    font-weight: 600;
  }

  input[type="radio"]:checked + label {
    border-color: $primary;
    background: transparentize($primary, 0.9);
  }

  svg, img {
    height: 60px;
    max-width: 100%;
    margin: 2px 0 15px;
    fill: #697386;
  }
}

.selector-title {
  display: block;
  color: $gray-dark;
  font-weight: 700;
  text-transform: uppercase;
  margin: 5px 0;
  font-size: 1.2rem;
}

.selector-description {
  color: $gray;
}

.beer-bottle-svg {
  height: 50px;
  margin: 10px auto;
  display: block;
  @media (max-width: $width-m) {
    margin: 0 auto;
    height: 35px;
  }

  fill: #ffc876;

  &.blonde {
    fill: #ffd250;
  }

  &.blonde2 {
    fill: #b39350;
  }

  &.ipa {
    fill: #ffc876;
  }

  &.brune {
    fill: #542513;
  }

  &.rouge {
    fill: #ad1b14;
  }

  &.ambree {
    fill: #de6c2f;
  }

  &.rousse {
    fill: #dc622b;
  }

  &.ambree-pain-epice {
    fill: #ec7939;
  }

  &.blanche {
    fill: #ffecd8;
  }

  &.limonade {
    fill: #b4deea;
  }
}

.custom-beer-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px -5px;
}

.custom-beer {
  flex: 1;
  margin: 5px;

  input[type="radio"] {
    display: none;
  }
}

.is_custom_text {
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem;
}

</style>