<template>
    <div class="onboarding">
        <div class="steps scrollable" ref="steps-container" id="onboarding-steps"
             v-on:scroll="handleOnboardingScroll($event.target)">
            <Step ref="steps" v-for="step in steps"
                  :key="step.id"
                  :image="step.image"
                  :title="step.title"
                  :logo="step.logo"
                  :intro="step.intro">

                <div v-if="step.id === 2" class="step-body mt" slot="body">
                    <div class="text-intro">
                        <p>Et vous pouvez même <b>personnaliser vos étiquettes</b> !</p>
                    </div>
                </div>

                <div v-if="step.id === 3" class="step-body" slot="body">
                    <div class="feature">
                        <img :src="require('@/assets/img/giftbox.svg')" alt="generous">
                        <div>
                            <div class="feature-title">Cartons offerts</div>
                            <p>Idéal pour faire partager et faire plaisir aux copains</p>
                        </div>
                    </div>
                    <div class="feature">
                        <img :src="require('@/assets/img/love.svg')" alt="generous">
                        <div>
                            <div class="feature-title">Récolte de fonds</div>
                            <p>Animez votre association ou votre club</p>
                        </div>
                    </div>
                    <div class="feature">
                        <img :src="require('@/assets/img/beer.svg')" alt="generous">
                        <div>
                            <div class="feature-title">Passez un bon moment</div>
                            <p>Récupérez vos cartons chez le parrain et partagez un moment de convivialité</p>
                        </div>
                    </div>
                </div>
            </Step>
        </div>
        <div class="steps-radio" v-if="steps.length > 1">
            <StepSelector v-for="step in steps"
                          :key="step.id"
                          :checked="step.active"
                          :step="step.id"
                          v-on:change="goToStep(step.id)"/>
        </div>
        <div class="double-buttons-container">
            <div>
                <router-link class="btn btn-l invert multilines" to="connexion">
                    <span>Déjà un compte ?</span>
                    Connexion
                </router-link>
            </div>
            <div>
                <a class="btn btn-l multilines" v-on:click="nextStep" v-html="discoverText"/>
            </div>
        </div>
    </div>
</template>

<script>
import Step from "@/components/Onboarding/Step";
import StepSelector from "@/components/Onboarding/StepSelector";

export default {
    name: 'Onboarding',
    components: {
        Step,
        StepSelector
    },
    data() {
        return {
            steps: [
                {
                    id: 1,
                    image: "/img/palette-3D-cartons.png",
                    logo: "/img/logo/lapalette-bleu.png",
                    title: "Commandez des bières en équipe",
                    intro: this.$store.getters.ENV_DATA("onboarding_step_1", 'Bières brassées et embouteillées en France<br>A partir de 2€ la bouteille<br><div class="tag_fr"><span></span><span></span><span></span></div>'),
                    active: true,
                },
                {
                    id: 2,
                    image: this.$store.getters.ENV_DATA("onboarding_step_2_image", 'img/3-bieres-cool.png'),
                    title: this.$store.getters.ENV_DATA("onboarding_step_2_title", "Nos bières artisanales"),
                    intro: this.$store.getters.ENV_DATA("onboarding_step_2", 'En direct de la Brasserie <img src="/img/logo/bos-gray.svg" style="line-height: 0; height: 42px; margin: 0 2px; vertical-align: -16px;"><br>' +
                        '                        <p>Blonde, ambrée, brune, IPA... Vous avez tous les choix !</p>'),
                    active: false,
                    logo: null
                },
                {
                    id: 3,
                    image: null,
                    title: this.$store.getters.ENV_DATA("onboarding_step_2_title", "Les avantages de La Palette"),
                    intro: null,
                    active: false
                },
            ],
            current: 1,
            maxStep: null,
        }
    },
    created() {
        this.maxStep = this.steps.length;
    },
    computed: {
        htmlSteps: function () {
            return this.$refs['steps'];
        },
        stepWidth: function () {
            return this.htmlSteps[0].$el.clientWidth;
        },
        discoverText() {
            return '<small>Pas encore inscrit ?</small><br>' + (this.current >= this.maxStep ? 'Rejoindre' : 'Découvrir');
        }
    },
    methods: {
        nextStep() {
            if (this.current + 1 > this.maxStep) {
                this.$router.push({path: '/inscription'});
            } else {
                this.goToStep(++this.current);
            }
        },
        prevStep() {
            if (this.current - 1 >= 1) {
                this.goToStep(--this.current);
            }
        },
        goToStep(step = 1) {
            const index = step - 1;
            const scroll = Math.round(this.stepWidth * index);
            this.$refs['steps-container'].scrollTo({
                left: scroll,
                behavior: "smooth",
            });
        },
        handleOnboardingScroll(container) {
            const index = Math.round(container.scrollLeft / this.stepWidth);
            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout);
            }
            this.scrollTimeout = setTimeout(() => {
                this.setActiveIndex(index);
            }, 10);
        },
        setActiveIndex(index) {
            this.current = index + 1;
            this.steps.forEach(s => {
                s.active = false;
            });
            this.steps[index].active = true;
        },
    }
}
</script>
<style src="./Onboarding.scss" lang="scss" scoped/>
