import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export class PalletModelThreeJS {

    constructor(container, box_count = 0, options = {}) {

        this.container = container;
        this.box_size = 1.8;
        this.box_count = box_count;
        this.medium_pallet_step = options.medium_pallet_step ?? 96;
        this.large_pallet_step = options.large_pallet_step ?? 174;
        this.max_rendered_box = options.max_rendered_box ?? 294;

        // Limite max de cartons 3D
        if (this.box_count > this.max_rendered_box) {
            this.box_count = this.max_rendered_box;
        }

        // Nombre maximum de cartons par (largeur & longueur))
        this.max_x = 4;
        if (this.box_count >= this.medium_pallet_step) {
            this.max_x = 5;
        }
        this.max_y = 6;

        if (this.box_count >= this.large_pallet_step) {
            this.max_x += 1;
            this.max_y += 1;
            this.box_size = 1.5;
        }

        // Espace entre les cartons
        this.x_spacing = 0.02;
        this.y_spacing = 0.02;
        this.z_spacing = 0.01;

        // Position initiale des cartons sur la palette
        this.initial_pos_x = -2.72;
        if (this.box_count >= this.medium_pallet_step) {
            this.initial_pos_x = -3.8;
        }
        this.initial_pos_y = -4.55;
        this.initial_pos_z = 1.125;

        this.auto_rotate = options.auto_rotate ?? true;
        this.enable_controls = options.enable_controls ?? true;
        this.enable_zoom = options.enable_zoom ?? false;
        this.objectsGroup = new THREE.Group();

        this.box_image_path = options.box_image_path || 'models/images/';

        this.init();
    }

    init() {
        this.scene = new THREE.Scene();
        this.setCamera();
        this.setLight();
        this.setRenderer();
        this.loadModels();
        this.render();
    }

    clearModel() {
        if (this.scene) {
            while (this.scene.children.length > 0) {
                this.scene.remove(this.scene.children[0]);
            }
        }
    }

    addBox(x, y, z) {
        let cube = new THREE.Mesh(this.box_geometry, this.box_materials);
        cube.castShadow = true;
        cube.receiveShadow = true;

        // console.log("Add box => ", x, y, z);
        cube.position.x = x;
        cube.position.y = z;
        cube.position.z = y;

        this.objectsGroup.add(cube);
    }

    loadModels() {

        // Charge les textures des cartons
        const texture = new THREE.TextureLoader();
        const image_side_plain = texture.load('./' + this.box_image_path + 'box-side.jpg');
        const image_side_logo = texture.load('./' + this.box_image_path + 'box-side-logo.jpg');
        const image_top = texture.load('./' + this.box_image_path + 'box-top.jpg');
        const image_back = texture.load('./' + this.box_image_path + 'box-back.jpg')
        const image_front = texture.load('./' + this.box_image_path + 'box-front.jpg')

        this.box_materials = [
            new THREE.MeshPhongMaterial({map: image_side_logo, shininess: 0.5, specular: 0x404040}), //side
            new THREE.MeshPhongMaterial({map: image_side_logo, shininess: 0.5, specular: 0x404040}), // side
            new THREE.MeshPhongMaterial({map: image_top, shininess: 0.5, specular: 0x404040}), // up
            new THREE.MeshPhongMaterial({map: image_side_plain, shininess: 0.5, specular: 0x404040}), // down
            new THREE.MeshPhongMaterial({map: image_back, shininess: 0.5, specular: 0x404040}), // back
            new THREE.MeshPhongMaterial({map: image_front, shininess: 0.5, specular: 0x404040}), // front
        ];

        this.box_geometry = new THREE.BoxGeometry(this.box_size, this.box_size, this.box_size);

        let x, y, z;
        let index_x = 0;
        let index_y = 0;
        let index_z = 0;

        for (let i = 0; i < this.box_count; i++) {

            x = this.initial_pos_x + index_x * (this.box_size + this.x_spacing);
            y = this.initial_pos_y + index_y * (this.box_size + this.y_spacing);
            z = this.initial_pos_z + index_z * (this.box_size + this.z_spacing);

            if ((index_x + 1) >= this.max_x) {
                index_x = 0;
                if ((index_y + 1) >= this.max_y) {
                    index_y = 0;
                    index_z++;
                } else {
                    index_y++;
                }
            } else {
                index_x++;
            }

            this.addBox(x, y, z);
        }

        const loader = new GLTFLoader();
        const object_texture = new THREE.MeshPhongMaterial({color: 0xf7b375});

        loader.load('./models/pallet.glb', (gltf) => {

            if (this.box_count >= this.medium_pallet_step) {
                gltf.scene.scale.set(1.24, 1, 1);
            } else {
                gltf.scene.scale.set(1, 1, 1);
            }

            gltf.scene.receiveShadow = true;
            gltf.scene.castShadow = true;

            gltf.scene.traverse((model) => {
                model.material = object_texture;
            });

            this.objectsGroup.add(gltf.scene);

        }, undefined, function (error) {
            console.error(error);
        });
    }

    setRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            powerPreference: 'default',
        });
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        this.renderer.setClearColor(0xffffff, 1);

        this.container.appendChild(this.renderer.domElement);
    }

    setCamera() {
        this.camera = new THREE.PerspectiveCamera(60, this.container.offsetWidth / this.container.offsetHeight, 0.1, 100);
        this.camera.position.set(-12, -2, -10);

        if (this.enable_controls || this.auto_rotate) {
            this.controls = new OrbitControls(this.camera, this.container);
            const yPosRatio = -9 + (1 + (0.015 * this.box_count));
            this.controls.target.set(0, yPosRatio, 0);
            // console.log(yPosRatio);
            this.controls.enableZoom = this.enable_zoom;
            this.controls.enableRotate = false;
            this.controls.enablePan = false;
            this.controls.enableDamping = true;
            this.controls.enableRotate = this.enable_controls;
            this.controls.autoRotate = this.auto_rotate;
            this.controls.autoRotateSpeed = 2;
            this.controls.update();
        }
    }

    setLight() {
        // ambiant light
        const ambiant_light = new THREE.AmbientLight(0x999999); // soft white ambiant_light
        this.scene.add(ambiant_light);

        const light = new THREE.DirectionalLight(0xffffff, 0.5);
        light.target = this.objectsGroup;
        light.position.set(-300, 150, 150);

        this.scene.add(light);

        light.shadow.camera.left = -8;
        light.shadow.camera.right = 8;
        light.shadow.camera.top = 8;
        light.shadow.camera.bottom = -8;

        light.castShadow = true;
    }

    render() {
        this.objectsGroup.position.y = -9;
        this.scene.add(this.objectsGroup);
        this.renderer.render(this.scene, this.camera);

        const renderLoop = () => {
            requestAnimationFrame(renderLoop);
            if (this.controls) {
                this.controls.update();
            }
            this.renderer.render(this.scene, this.camera);
        };

        renderLoop();
    }
}
