<template>
    <div>
        <div v-if="loading">
            <p>Vous allez être redirigé vers Stripe...</p>
        </div>
        <div>
            <button type="button" @click="submitForm" ref="btnPay" class="btn btn-l bg"
                    :class="(loading ? 'loading' : '') + (enabled ? '' : 'disabled')">
                Payer maintenant
            </button>
            <div class="secured"><i class="ic icon-correct green"></i> Paiement sécurisé</div>
        </div>
    </div>
</template>

<script>
import Api from "@/services/Api";
import {share} from "@/mixins/share";
import {palette} from "@/mixins/palette";
import {Ui} from "@/class/Ui";

export default {
    name: 'StripeCheckout',
    props: ['pk', 'amount', 'taxes', 'stripe', 'enabled', 'data'],
    mixins: [share, palette],
    data() {
        return {
            loading: false,
            order: null,
        }
    },
    methods: {
        initOrder() {
            const member = this.$store.getters.USER_AS_MEMBER;
            console.log(this.data);
            if ((this.palette.brand.title === 'Crédit Agricole' || this.palette.brand.title === 'Axereal') && !this.data.site) {
                this.$parent.$refs.custom_input.classList.add('has-error');
                return Promise.reject(false);
            }

            return Api.post('/order', {
                memberId: member.id,
                data: this.data
            });
        },
        async fetchCheckoutSession() {
            const order = await this.initOrder();

            if (this.$gtag) {
                this.$gtag.event('purchase', {
                    value: this.amount,
                    currency: 'EUR',
                    tax: this.taxes,
                    transaction_id: order.id
                });
            }

            return Api.post('/create-checkout-session', {
                orderId: order.id,
                success_url: this.baseurl + this.$router.resolve({name: 'end-success'}).href,
                cancel_url: this.baseurl +  this.$router.resolve({name: 'cart-purchase'}).href,
            });
        },
        async submitForm() {
            if (!this.loading && this.enabled) {
                this.loading = true;
                try {
                    const {sessionId} = await this.fetchCheckoutSession();
                    return this.stripe.redirectToCheckout({
                        sessionId,
                    }).then((error) => {
                        this.loading = false;
                        console.log(error);
                    })
                } catch (e) {
                    Ui.notif("Une erreur est survenue, veuillez réessayer.", "error");
                    this.loading = false;
                }
            }
        },
    },
    async created() {
        if (!this.$store.getters.ACTIVE_PALETTE) {
            await this.getCurrentPalette();
        }
        if (!this.$store.getters.PALETTE_MEMBERS) {
            await this.$store.dispatch('LIST_MEMBERS');
        }
    },
    mounted() {
        if (this.$gtag) {
            this.$gtag.event('begin_checkout');
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.secured {
  display: block;
  text-align: center;
  font-size: 0.875rem;
  color: $gray;
  margin-top: 4px;
}
</style>
