import Vue from 'vue'
import VueRouter from 'vue-router'
import PalletBoard from "./views/Pallet/PalletBoard";
import Onboarding from "./views/Onboarding/Onboarding";
import Shop from "./views/Shop/Shop";
import Cart from "./views/Shop/Cart";
import Purchase from "./views/Order/Purchase";
import Success from "./views/Order/Success";
import Profile from "./views/Profile/Profile";
import ProfileInfos from "./views/Profile/ProfileInfo";
import ProfileOrders from "./views/Profile/ProfileOrders";
import ProfileFidelity from "./views/Profile/ProfileFidelity";
import ProfileAddress from "./views/Profile/ProfileAddress";
import Connexion from "./views/Onboarding/Login";
import PasswordRenew from "./views/Onboarding/PasswordRenew";
import Welcome from "./views/Onboarding/Welcome";
import WelcomeAbout from "./views/Onboarding/WelcomeAbout";
import PalletView from "./views/Pallet/PalletView";
import PalletManagerName from "./views/Pallet/PalletManagerName";
import PalletManagerMenu from "./views/Pallet/PalletManagerMenu";
import PalletManager from "./views/Pallet/PalletManager";
import PalletManagerDate from "./views/Pallet/PalletManagerDate";
import PalletManagerAddress from "./views/Pallet/PalletManagerAddress";
import PalletManagerCardboards from "./views/Pallet/PalletManagerCardboards";
import ProfileBoard from "./views/Profile/ProfileBoard";
import OnboardingInvited from "./views/Onboarding/OnboardingInvited";
import RegisterCode from "./views/Onboarding/Registration/RegisterCode";
import RegisterInfos from "./views/Onboarding/Registration/RegisterInfos";
import RegisterLogin from "./views/Onboarding/Registration/RegisterLogin";
import RegisterPassword from "./views/Onboarding/Registration/RegisterPassword";
import Register from "./views/Onboarding/Registration/Register";
import Join from "./views/Onboarding/Join";
import More from "./views/More/More";
import About from "./views/More/About";
import CGV from "./views/More/CGV";
import Invite from "./views/More/Invite";
import Ranking from "./views/More/Ranking";
import ContactList from "./views/More/ContactList";
import PalletManagerCollectFee from "@/views/Pallet/PalletManagerCollectFee";
import PalletManagerModeSelector from "@/views/Pallet/PalletManagerModeSelector";
import PalletManagerStripeConnect from "@/views/Pallet/PalletManagerStripeConnect";
import PalletManagerCustom from "@/views/Pallet/PalletManagerCustom";

Vue.use(VueRouter);

const routes = [
    {path: '/', redirect: '/palette',},
    {path: '/palette', name: 'pallet-home', component: PalletBoard, meta: {
        layout: "container-with-navbar", current: "palette", requireAuth: true
    }},
    {path: '/palette/:code', name: 'pallet-details', component: PalletView, meta: {
        layout: "no-navbar", current: "palette", requireAuth: true
    }},
    {path: '/palette/:code/parametres', component: PalletManager, props: true, meta: {layout: "no-navbar", requireAuth: true},
        children: [
            {path: '', name: 'manager-menu', component: PalletManagerMenu, meta: {layout: "no-navbar", requireAuth: true},},
            {path: 'nom', name: 'manager-name', component: PalletManagerName, meta: {layout: "no-navbar", requireAuth: true}, props: true,},
            {path: 'cartons', component: PalletManagerCardboards, meta: {layout: "no-navbar", requireAuth: true}, props: true,},
            {path: 'adresse', component: PalletManagerAddress, meta: {layout: "no-navbar", requireAuth: true},},
            {path: 'date', component: PalletManagerDate, meta: {layout: "no-navbar", requireAuth: true},},
            {path: 'cagnotte', component: PalletManagerCollectFee, meta: {layout: "no-navbar", requireAuth: true},},
            {path: 'mode', name: 'manager-mode', component: PalletManagerModeSelector, meta: {layout: "no-navbar", requireAuth: true},},
            {path: 'stripe', component: PalletManagerStripeConnect, meta: {layout: "no-navbar", requireAuth: true},},
            {path: 'custom', component: PalletManagerCustom, meta: {layout: "no-navbar", requireAuth: true},},
        ]
    },
    {path: '/profil', component: Profile, meta: {layout: "container-with-navbar", current: "profile", requireAuth: true},
        children: [
            {path: '', component: ProfileBoard, meta: {current: 'profile'}},
            {path: 'informations', component: ProfileInfos, meta: {current: 'profile'}},
            {path: 'commandes', component: ProfileOrders, meta: {current: 'profile'}},
            {path: 'adresses', component: ProfileAddress, meta: {current: 'profile'}},
            {path: 'fidelite', component: ProfileFidelity, meta: {current: 'profile'}}
        ]
    },
    {path: '/catalogue', name: 'shop-view', component: Shop, meta: {
        layout: "container-with-navbar", current: "catalogue", requireAuth: true
    }},
    {path: '/panier', name: 'cart-view', component: Cart, meta: {
        layout: "no-navbar", requireAuth: true
    }},
    {path: '/commander', name: 'cart-purchase', component: Purchase, meta: {
        layout: "no-navbar", requireAuth: true
    }},
    {path: '/commander/success', name: 'end-success', component: Success, meta: {
        layout: "no-navbar", requireAuth: true
    }},
    {path: '/onboarding', name: 'onboarding-start', component: Onboarding, meta: {
        layout: "no-navbar", requireAuth: false
    }},
    {path: '/onboarding/invite', name: 'onboarding-invite', component: OnboardingInvited, meta: {
        layout: "no-navbar", requireAuth: false
    }},
    {path: '/connexion', name: 'login-view', component: Connexion, meta: {
        layout: "no-navbar", requireAuth: false
    }},
    {path: '/mot-de-passe/:userId/:verificationCode', name: 'password-renew-view', component: PasswordRenew, meta: {
        layout: "no-navbar", requireAuth: false
    }},
    {path: '/rejoindre', name: 'join-view', component: Join, meta: {
        layout: "no-navbar", requireAuth: true
    }},
    {path: '/inscription', component: Register, meta: {layout: "no-navbar", requireAuth: false},
        children: [
            {path: '', component: RegisterCode, meta: {layout: "no-navbar", requireAuth: false}},
            {path: 'informations', component: RegisterInfos, meta: {layout: "no-navbar", requireAuth: false}},
            {path: 'email', component: RegisterLogin, meta: {layout: "no-navbar", requireAuth: false}},
            {path: 'mot-de-passe', component: RegisterPassword, meta: {layout: "no-navbar", requireAuth: false}}
        ]
    },
    {path: '/welcome', name: 'welcome-view', component: Welcome, meta: {
        layout: "no-navbar", requireAuth: true
    }},
    {path: '/welcome-about', name: 'welcome-about-view', component: WelcomeAbout, meta: {
        layout: "no-navbar", requireAuth: true
    }},
    {path: '/invite', name: 'invite-view', component: Invite, meta: {
        layout: "no-navbar", requireAuth: false
    }},
    {path: '/plus', name: 'plus', component: More, meta: {
        layout: "container-with-navbar", current: "plus", requireAuth: true
    }},
    {path: '/conditions-generales-de-vente', name: 'legal-view', component: CGV, meta: {
        layout: "no-navbar", requireAuth: false
    }},
    {path: '/a-propos', name: 'about-view', component: About, meta: {
        layout: "no-navbar", requireAuth: false
    }},
    {path: '/classement', name: 'ranking-view', component: Ranking, meta: {
        layout: "no-navbar", requireAuth: true
    }},
    {path: '/contacts', name: 'contact-list-manager', component: ContactList, meta: {
        layout: "container-with-navbar", current: "plus", requireAuth: true
    }},
];

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: routes
});

export default router
