import Api from './Api'

export default {
    getBrandInfos(palette) {
        console.log("GET BRAND INFOS FROM PALETTE", palette);
        return Api.get('/brand?brand=' + palette.brand.id);
    },
    getBrandData(palette) {
        return Api.get('/brand/data?brand=' + palette.brand.id);
    },
}
