<template>
    <div class="master scrollable" v-if="palette">
        <TopBar v-bind:back="'/palette'" v-bind:title="palette.name"/>
        <div class="wrapper">
            <div class="tag bg mb" v-if="palette.state > this.STATE_OPEN">{{ paletteState }}</div>
            <div class="members">
                <p v-if="members.length > 0">
                    Actuellement {{ members.length }} participant{{ members.length > 1 ? 's' : '' }}
                </p>
                <p v-else>Aucun participant pour le moment</p>
                <ListMemberItem v-for="member in members"
                                v-bind:member="member"
                                v-bind:key="member.id"
                                v-on:showMemberImage="showMemberImage"/>
            </div>
        </div>

        <ImagePreview v-if="profileImageModal && profileImageSrc" v-on:close="profileImageModal = false"
                      :image="profileImageSrc"/>

        <div class="wrapper fixed-to-bottom" v-if="palette">
            <div class="double-buttons-container" v-if="isGodFather && this.$store.getters.isGodfatherPalette(palette)">
                <div>
                    <button class="btn btn-l bg" v-on:click="shareLink">Inviter</button>
                </div>
                <div v-if="isOpened">
                    <button class="btn btn-l"
                            :class="(isReadyToShip ? '' : 'disabled') + (loadingShipping ? 'loading' : '')"
                            @click="setStateDelivery">
                        Terminer
                        <small v-if="!isReadyToShip">
                            (min. 20)
                        </small>
                    </button>
                </div>
                <div v-else class="center disabled">
                    {{ paletteState }}
                </div>
            </div>
            <div class="double-buttons-container" v-else-if="isOpened">
                <div v-if="canShare">
                    <button class="btn btn-l invert" v-on:click="shareLink">Inviter des amis</button>
                </div>
                <div>
                    <router-link to="/catalogue" class="btn btn-l bg">Ajouter des cartons</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import ListMemberItem from "../../components/List/ListMemberItem";
import {share} from "@/mixins/share";
import moment from "moment";
import PaletteService from "../../services/PaletteService";
import {Ui} from "@/class/Ui";
import {palette} from "@/mixins/palette";
import {user} from "@/mixins/user";
import ImagePreview from "@/components/UX/ImagePreview";

export default {
    name: 'PalletView',
    components: {ImagePreview, ListMemberItem, TopBar},
    mixins: [share, palette, user],
    data() {
        return {
            members: [],
            loadingMembers: false,
            loadingShipping: false,
            profileImageModal: false,
            profileImageSrc: false
        }
    },
    computed: {
        estimatedDelivery() {
            if (this.palette.deliveryDate) {
                return moment(this.palette.deliveryDate);
            }

            return moment(this.palette.expirationDate).add(5, 'days');
        }
    },
    methods: {
        shareLink() {
            try {
                this.browserShareLink(this.palette.code);
            } catch (err) {
                this.$router.push('/plus');
            }
        },
        loadMembers() {
            this.loadingMembers = true;
            if (this.palette) {
                this.$store.dispatch('LIST_MEMBERS').then((members) => {
                    this.members = members;
                    this.loadingMembers = false;
                });
            }
        },
        moment: function () {
            moment.locale('fr');
            return moment();
        },
        setStateDelivery() {
            if (this.isReadyToShip) {
                if (confirm("Expédier la palette ? Il ne sera plus possible d'y ajouter des cartons")) {
                    this.loadingShipping = true;
                    PaletteService.requestShipping(this.palette).then(() => {
                        this.loadingShipping = false;
                        Ui.notif("L'expédition de la palette a été demandée !", "success");
                        this.getCurrentPalette(true).then(() => {
                            this.$router.push('/');
                        });
                    }).catch(error => {
                        this.loadingShipping = false;
                        Ui.notif(error, 'error');
                    });
                }
                return true;
            } else if (this.isOpened) {
                alert('Un minimum de 20 cartons est nécessaire pour commander !');
            }

            return false;
        },
        showMemberImage(src) {
            this.profileImageSrc = src;
            this.profileImageModal = true;
        }
    },
    async created() {
        await this.getCurrentPalette();
        this.loadMembers();
    },
}
</script>

<style lang="scss" scoped>
.progress-bar-container {
  flex: 0;
}
</style>