import PaletteService from "../services/PaletteService";

export const palette = {
    created() {
        this.STATE_DRAFT = 0;
        this.STATE_PENDING_APPROVAL = 1;
        this.STATE_OPEN = 2;
        this.STATE_READY = 3;
        this.STATE_PREPARATION = 4;
        this.STATE_SHIPMENT = 5;
        this.STATE_CLOSE = 6;
        this.STATE_EXPIRED = 10;
        this.STATE_CANCELED = 11;
    },
    computed: {
        palette() {
            return this.$store.getters.ACTIVE_PALETTE;
        },
        paletteState() {
            if (this.palette) {
                return this.getStateStr(this.palette.state);
            }

            return '';
        },
        isDraft() {
            return this.palette.state === this.STATE_DRAFT;
        },
        isPendingApproval() {
            return this.palette.state === this.STATE_PENDING_APPROVAL;
        },
        isReadyToShip() {
            return this.palette.currentCardBoardCounter >= 20 && this.isOpened;
        },
        isOpened() {
            return this.palette.state === this.STATE_OPEN;
        },
        isEditable() {
            return this.isDraft || this.isPendingApproval;
        },
        isShipped() {
            return this.palette.state === this.STATE_SHIPMENT || this.palette.state === this.STATE_CLOSE;
        },
        isFinished() {
            return this.palette.state >= this.STATE_READY;
        },
        isClosed() {
            return this.palette.state === this.STATE_CLOSE || this.isCanceled;
        },
        isCanceled() {
            return this.palette.state === this.STATE_CANCELED;
        },
        canBeJoined() {
            return this.palette.state > this.STATE_DRAFT && this.palette.state < this.STATE_READY;
        },
        cardboardLeft() {
            return this.palette.cardboardCapacity - this.palette.currentCardBoardCounter;
        },
        isCollective() {
            return this.palette.mode === "collective";
        },
        getCardboardCollectiveFees() {
            if (this.isCollective) {
                return this.palette.cardboardCollectiveFees;
            }
            return 0.0;
        }
    },
    methods: {
        async getCurrentPalette(force = false) {
            console.log("mixin current palette", this.$store.getters.ACTIVE_PALETTE_ID, localStorage.getItem('palette_active'));
            if ((!this.$store.getters.ACTIVE_PALETTE || force === true) && parseInt(this.$store.getters.ACTIVE_PALETTE_ID) !== 0) {
                return this.$store.dispatch('LOAD_PALETTE', this.$store.getters.ACTIVE_PALETTE_ID);
            }

            return Promise.resolve(this.$store.getters.ACTIVE_PALETTE);
        },
        getNextPalette() {
            if (this.palette && this.palette.next_palette > 0) {
                return PaletteService.getPaletteFromId(this.palette.next_palette).then((data) => {
                    return data;
                })
            }
            return null;
        },
        changePalette(paletteId) {
            if (paletteId === 0) {
                this.$router.push('/palette/start/parametres');
            }
            return this.$store.dispatch('LOAD_PALETTE', paletteId).then((data) => {
                if (paletteId > 0) {
                    if (this.$router.currentRoute.path === "/palette") {
                        this.$router.go(0);
                    } else {
                        this.$router.push('/palette');
                    }
                }
            }).catch(e => {
                console.error("changePalette", e);
            });
        },
        getStateStr(status) {
            switch (status) {
                case this.STATE_DRAFT:
                    return 'En cours de création';
                case this.STATE_PENDING_APPROVAL:
                    return 'En attente de validation';
                case this.STATE_OPEN:
                    return 'Ouverte';
                case this.STATE_READY:
                    return 'Prête';
                case this.STATE_PREPARATION:
                    return 'En préparation';
                case this.STATE_SHIPMENT:
                    return 'Expédiée';
                case this.STATE_CLOSE:
                    return 'Terminée';
                case this.STATE_EXPIRED:
                    return 'Expirée';
                case this.STATE_CANCELED:
                    return 'Annulée';
            }

            return null;
        }
    }
}