<template>
    <div class="product" :class="{flagship:product.flagship}">
        <div class="product__infos" @click="showDetails">
            <div class="primary_content">
                <div class="product__tag tag light" v-if="product.details && product.details.highlight">
                    {{ product.details.highlight }}
                </div>
                <h3 class="product__title">
                    {{ product.name }}
                    <span v-if="product.details && product.details.alcohol > 0">/ {{ product.details.alcohol }}°</span>
                </h3>
                <p class="product__subtitle" v-if="product.short_description">{{ product.short_description }}</p>
                <div class="product__description" v-html="description"/>
                <span class="tag invert see-more" v-if="product.long_description">&bullet;&bullet;&bullet;</span>
                <div class="product__price" v-if="product.stock > 0">
                    <span v-if="old_price" class="old_price">{{ old_price }}</span>
                    <span class="price">{{ price }}</span>
                    <span class="fees green b" v-if="collectiveFees > 0">
                        +{{ collectiveFees }}€ reversés
                    </span>
                </div>
                <div class="product__icons" v-if="product.details">
                    <img :src="require('@/assets/img/rewards/bio-ab.jpg')" v-if="product.details.bio === true" alt="label bio">
                    <img :src="require('@/assets/img/rewards/vigneron-independant.png')" v-if="product.details.vigneron_independant === true" alt="Vigneron indépendant">
                    <img :src="require('@/assets/img/rewards/hve.png')" v-if="product.details.hve === true" alt="Haute valeur environnementale">
                    <img class="medal" v-for="reward in product.details.rewards" v-bind:key="reward.image"
                         :title="reward.title" :alt="reward.title"
                         :src="require('@/assets/img/rewards/' + reward.image)"/>
                </div>
            </div>
            <div class="secondary_content">
                <div v-if="product.flagship" class="flagship_title">{{ product.name }}</div>
                <img :src="product.image" :alt="product.name" class="product__image"
                     :class="product.stock > 0 ? '' :'bw'"/>
                <div class="product__actions" v-if="isLoaded && product.stock > 0 && orderEnabled">
                    <template v-if="in_cart > 0">
                        <a class="btn btn-m btn-remove-qty" v-on:click.stop="$emit('removeFromCart', product, 1)">
                            <i class="icon-minus"></i>
                        </a>
                        <input type="text" class="quantity-input input-number" readonly :value="in_cart"/>
                        <a class="btn btn-m btn-add-qty" v-on:click.stop="$emit('addToCart', product, 1)">
                            <i class="icon-plus"></i>
                        </a>
                    </template>
                    <a v-else class="btn btn-m btn-add-qty" v-on:click.stop="$emit('addToCart', product, 1)">
                        <i class="icon-plus"></i> <span>Ajouter</span>
                    </a>
                </div>
                <div class="product__unavailable" v-if="isLoaded && product.stock <= 0">
                    <div class="unavailable">Epuisé !</div>
                </div>
            </div>
        </div>
        <Modal v-if="showDetailsPage" :show-close-button="true"
               class="product_details_modal"
               v-on:cancel="showDetailsPage = false">
            <div slot="header">
                <h2 class="title size-4">{{ product.name }}</h2>
                <p class="subtitle">{{ product.short_description }}</p>
            </div>
            <div slot="body">
                <div class="center">
                    <img :src="product.image" :alt="product.name" class="product__image"/>
                </div>
                <p v-html="product.description"></p>
                <div v-html="product.long_description"></div>

                <div class="product__icons" v-if="product.details">
                    <img :src="require('@/assets/img/rewards/bio-ab.jpg')" v-if="product.details.bio === true" alt="label bio">
                    <img :src="require('@/assets/img/rewards/vigneron-independant.png')" v-if="product.details.vigneron_independant === true" alt="Vigneron indépendant">
                    <img :src="require('@/assets/img/rewards/hve.png')" v-if="product.details.hve === true" alt="Haute valeur environnementale">
                    <img class="medal" v-for="reward in product.details.rewards" v-bind:key="reward.image"
                         :title="reward.title" :alt="reward.title"
                         :src="require('@/assets/img/rewards/' + reward.image)"/>

                    <div v-if="product.details.advanced" class="product_advanced_info">
                        <div v-for="(score, key) in product.details.advanced" v-bind:key="key">
                            <div v-if="score" class="product_advanced_info_line">
                                <label>{{ key }}</label>
                                <div class="score-bullets">
                                    <span class="bullet" v-for="n in 5" v-bind:key="n"
                                          :class="score >= n ? 'active': ''"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="product__actions" v-if="isLoaded && product.stock > 0 && orderEnabled">
                    <template v-if="in_cart">
                        <a class="btn btn-m btn-remove-qty" v-on:click="$emit('removeFromCart', product, 1)">
                            <i class="icon-minus"></i>
                        </a>
                        <input type="text" class="quantity-input input-number" readonly :value="in_cart">
                        <a class="btn btn-m btn-add-qty" v-on:click="$emit('addToCart', product, 1)">
                            <i class="icon-plus"></i>
                        </a>
                    </template>
                    <button v-if="in_cart === 0" class="btn btn-l" v-on:click="$emit('addToCart', product, 1)">
                        Ajouter au panier &bullet; {{ price }}
                    </button>
                </div>
                <a @click="showDetailsPage = false" class="btn btn-l gr">Fermer</a>
            </div>
        </Modal>
        <div class="scroll-info" v-if="product.flagship"><i class="ic icon-down-arrow"></i></div>
    </div>
</template>

<script>
import Modal from "../UX/Modal";

export default {
    name: 'ShopItem',
    components: {Modal},
    props: {
        product: {
            type: Object,
            required: true
        },
        orderEnabled: {
            type: Boolean,
            required: true
        },
        paletteCardboardCollectiveFees: {
            type: Number,
            default: 0.0
        },
    },
    data() {
        return {
            showDetailsPage: false,
        }
    },
    computed: {
        price() {
            if (this.product.tva) {
                return this.$_.round(this.product.price * (1 + (this.product.tva / 100))) + '€';
            }
            return this.$_.round(this.product.price).toFixed(2) + '€';
        },
        collectiveFees() {
            if (this.product.fees_apply) {
                return this.$_.round(this.paletteCardboardCollectiveFees);
            }

            return 0.0;
        },
        old_price() {
            if (this.product.old_price) {
                if (this.product.tva) {
                    return this.$_.round(this.product.old_price * (1 + (this.product.tva / 100))) + '€';
                }
                return this.$_.round(this.product.old_price) + '€';
            }
            return null;
        },
        description() {
            return this.product.description.replace(/\n/g, "<br>");
        },
        isLoaded() {
            return this.$parent.cart.loaded && this.$store.getters.ACTIVE_PALETTE !== null;
        },
        in_cart() {
            const item = this.$store.getters.CART.items.find(p => p.productId === this.product.id);
            if (item) {
                return item.quantity;
            }
            return 0;
        },
    },
    methods: {
        showDetails() {
            this.showDetailsPage = true;
            if (this.$gtag) {
                this.$gtag.event('view_item');
            }
        }
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "./src/styles/vars";

.product {
  padding: $padding-wrapper;
  @extend .desktop-wrap;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  .product__tag {
    text-transform: uppercase;
    font-weight: 500;
    margin-left: -0.3em;
    margin-bottom: .5em;
  }

  .product__infos {
    display: flex;
    flex-direction: row;

    .primary_content {
      flex: 1;
      padding-right: $padding-wrapper;

      .product__title {
        font-family: $font-body-alt;
        font-weight: 500;
        color: #000;
      }

      .product__subtitle {
        color: $dark;
        margin: 5px 0;
      }

      .product__description {
        font-size: .9em;
        color: $gray;
        line-height: 1.2;
      }

      .product__price {
        padding: 5px 0;
        color: #333;
        font-size: 1rem;

        .price {
          font-weight: 600;
        }

        .old_price {
          text-decoration: line-through;
          margin-right: 5px;

          + .price {
            font-size: 1.12em;
            color: $red;
          }
        }
      }

    }

    .secondary_content {
      flex: 0 128px;
      max-width: 40vw;
      position: relative;

      img {
        max-width: 100%;
        border-radius: 4px;

        &.bw {
          filter: grayscale(0.8);
        }
      }
    }
  }

  .product__actions {
    padding: 10px 0;
    display: flex;
    max-width: 100%;
    justify-content: center;

    input {
      width: 36px;
      max-width: 36px;
      // border: 1px solid #000;
      text-align: center;
      padding: 5px;
    }

    input + .btn,
    .btn + input {
      margin-left: 5px;
    }

    .btn-add-qty {
      i + span {
        margin-left: 6px;
      }
    }

    .quantity-input {
      flex: 0;
      outline: 0;
      background: transparent;
    }

    .btn-add-qty,
    .btn-remove-qty {
      background: none;
      border-radius: 1000px;
      border: 1px solid $gray;
      color: #222;
      background: #fff;
      flex: 0;

      > i {
        font-size: 11px;
        font-weight: bold;
      }
    }
  }

  .product__unavailable {
    text-align: center;
    position: absolute;
    top: 46%;
    left: 0;
    right: 0;

    .unavailable {
      background: $red;
      color: #fff;
      text-align: center;
      font-weight: 600;
      display: inline-block;
      padding: 4px 7px;
      line-height: 1;
    }
  }

  .product__icons {
    img {
      max-height: 36px;
      margin-right: 5px;

      &.medal {
        max-height: 48px;
      }
    }
  }

  .product_details_modal .product__icons {
    margin-top: 8px;
    img {
      max-height: 64px;
      margin-right: 5px;

      &.medal {
        max-height: 64px;
      }
    }
  }

  .see-more {
    color: $gray;
    font-weight: 600;
    margin-top: 5px;
    cursor: pointer;
  }

  &:not(.flagship) {
    .secondary_content .product__image {
      height: 128px;
      width: 128px;
      object-fit: contain;
    }
  }
}

.product.flagship {

  background: #f3f3f3;

  .product__infos {
    flex-direction: column-reverse;
    align-items: center;

    .primary_content {
      padding: 24px;
      border-radius: 12px;
      text-align: center;
      font-size: 1.25em;
      background: rgba(255, 255, 255, 0.9);

      .product__title {
        display: none;
      }

      &.dark {
        background: $secondary;

        .product__title {
          color: $secondary-text;
        }

        .product__subtitle {
          color: #ccc;
        }

        .product__description {
          color: #999;
        }

        .product__price {
          color: #ccc;
        }
      }
    }

    .secondary_content {
      max-width: 100%;
    }
  }

  .flagship_title {
    position: absolute;
    top: 20%;
    background: #f3f3f3;
    color: $secondary;
    font-size: 1.4rem;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.08);
    padding: 12px;
    text-align: center;
    left: 0;
    right: 0;
    text-transform: uppercase;
    font-family: $font-heading;
    border-radius: 8px;
  }

  .product__actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -28px;
  }

  .btn-add-qty,
  .btn-remove-qty {
    background: $secondary;
    color: $secondary-text;
  }

  position: relative;
  padding-bottom: math.div($padding-wrapper, 2);

  .scroll-info {
    margin-top: math.div($padding-wrapper, 2);
    color: #999;
    font-size: 16px;
    text-align: center;
  }
}

.product__image {
  max-height: 50vh;
  max-width: 100%;
}

.product_details_modal {
  .product__image {
    margin: 5px 0px 10px;
  }
}

.product_advanced_info {
  margin: 10px 0;

  .product_advanced_info_line {
    display: flex;
    flex-direction: row;
    padding: 2px 0px;
  }

  label {
    flex: 0 110px;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray;
  }

  .score-bullets {
    display: flex;
    flex-direction: row;
    flex: 1;

    .bullet {
      height: 16px;
      width: 16px;
      margin: 0 2px;
      border: 1px solid transparent;
      border-radius: 100px;
      background: #e3e3e3;

      &.active {
        &:first-child {
          background: lighten($primary, 30);
        }

        &:nth-child(2) {
          background: lighten($primary, 20);
        }

        &:nth-child(3) {
          background: lighten($primary, 10);
        }

        &:nth-child(4) {
          background: $primary;
        }

        &:nth-child(5) {
          background: darken($primary, 10);
        }
      }
    }
  }
}

</style>