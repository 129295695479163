<template>
    <div class="wrapper">
        <form @submit.prevent="submitPassword">
            <h1 class="title mb">Choisissez un mot de passe</h1>
            <div class="field">
                <label for="input_password" class="label">Nouveau mot de passe</label>
                <input id="input_password" type="password" class="input" v-model="password" v-focus/>
            </div>
            <div class="field">
                <label for="input_password_verify" class="label">Confirmez le mot de passe</label>
                <input id="input_password_verify" type="password" class="input" v-model="password_verify"/>
            </div>
            <div class="field mt">
                <button type="submit" class="btn btn-l" :class="loading ? 'loading' : ''">Confirmer</button>
            </div>
        </form>
    </div>
</template>

<script>
    import Api from "../../services/Api";
    import {Ui} from "../../class/Ui";

    export default {
        name: 'PasswordRenew',
        data() {
            return {
                loading: false,
                userId: 0,
                code: null,
                password: null,
                password_verify: null,
            }
        },
        methods: {
            passwordCheck() {
                return this.password !== null;
            },
            submitPassword() {

                if (this.loading) {
                    return false;
                }

                if (!this.passwordCheck()) {
                    Ui.notif("Le mot de passe est incorrect", 'error');
                    return false;
                }

                if (this.password !== this.password_verify) {
                    Ui.notif("Les mots de passe ne correspondent pas", "error");
                    return false;
                }

                this.loading = true;
                return Api.put('/user/set-password', {
                    userId: this.userId,
                    code: this.code,
                    password: this.password
                }).then((resp) => {
                    if (resp && resp.success) {
                        Ui.notif("Le mot de passe a été modifié", "success");
                        this.$router.push('/connexion');
                        return true;
                    }
                    return false;
                }).catch((error) => {
                    Ui.notif(error, "error");
                    return false;
                }).finally(() => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 1500);
                });

            }
        },
        created() {
            this.userId = this.$route.params.userId;
            this.code = this.$route.params.verificationCode;
        },
        directives: {
            focus: {
                inserted: function (el) {
                    el.focus()
                }
            }
        }
    }
</script>