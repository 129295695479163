<template>
    <div class="master scrollable">
        <TopBar back="/plus" title="Mes contacts">
            <div slot="action" class="action">
                <a @click="addContact">
                    <i class="ic icon-plus"></i>
                </a>
            </div>
        </TopBar>
        <div class="contact-list" v-if="contacts && contacts.length > 0">
            <div class="contact-item" v-for="contact in contacts" v-bind:key="contact.id">
                <div class="contact-item__content">
                    <div class="contact-item__name">{{ contact.firstName }} {{ contact.lastName }}</div>
                    <div class="contact-item__email" v-if="contact.email">{{ contact.email }}</div>
                    <div class="contact-item__mobile" v-if="contact.mobile">{{ formattedNumber(contact.mobile) }}</div>
                </div>
            </div>
        </div>
        <div class="wrapper" v-else>
            Aucun contact
        </div>

        <Modal v-if="editContactModal">
            <div slot="body" class="form">
                <div class="field">
                    <label class="label">Prénom</label>
                    <input type="text" class="input" name="firstName" v-model="editing_contact.firstName">
                </div>
                <div class="field">
                    <label class="label">Nom</label>
                    <input type="text" class="input" name="lastName" v-model="editing_contact.lastName">
                </div>
                <div class="field">
                    <label class="label">Email</label>
                    <input type="email" class="input" name="email" v-model="editing_contact.email">
                </div>
                <div class="field">
                    <label class="label">Téléphone</label>
                    <input type="tel" class="input" name="mobile" v-model="editing_contact.mobile">
                </div>
            </div>
            <div slot="footer" class="double-buttons-container">
                <div>
                    <a @click="cancel" class="btn gr">Annuler</a>
                </div>
                <div>
                    <a @click="saveContact" class="btn bg" :class="loading ? 'loading' : ''">Enregistrer</a>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    import Api from "../../services/Api";
    import TopBar from "../../components/UX/TopBar";
    import Modal from "../../components/UX/Modal";
    import {parsePhoneNumberFromString} from "libphonenumber-js";

    export default {
        name: 'ContactList',
        components: {TopBar, Modal},
        data() {
            return {
                contacts: null,
                loading: false,
                editing_contact: null,
                editContactModal: false,
            }
        },
        methods: {
            fetchContacts() {
                return Api.get('/contact');
            },
            addContact() {
                this.editing_contact = {
                    firstName: '',
                    lastName: '',
                    email: '',
                    mobile: '',
                    id: 0,
                };
                this.editContactModal = true;
            },
            editContact(contact) {
                this.editing_contact = JSON.parse(JSON.stringify(contact));
                this.editContactModal = true;
            },
            cancel() {
                this.editContactModal = false;
            },
            saveContact() {
                this.loading = true;
                return Api.post('/contact', {
                    contacts: [this.editing_contact]
                }).then((resp) => {
                    this.editContactModal = false;
                }).finally(() => {
                    this.loading = false;
                    this.fetchContacts().then((contacts) => {
                        this.contacts = contacts;
                    });
                })
            },
            formattedNumber(phone) {
                const phoneNumber = parsePhoneNumberFromString(phone, 'FR');
                if (phoneNumber) {
                    return phoneNumber.formatNational();
                }
                return phone;
            },
        },
        created() {
            this.fetchContacts().then((contacts) => {
                this.contacts = contacts;
            })
        }
    }
</script>

<style lang="scss" scoped>

    @use "sass:math";
    @import "../../styles/vars";

    .contact-item {
        border-bottom: 1px solid $line-color;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $font-heading;
        padding: math.div($padding-wrapper, 2) 0;
        cursor: pointer;

        .contact-item__content {
            flex: 1;
            padding: 0 1rem;

            .contact-item__name {
                font-size: 1.12rem;
                font-weight: 500;
                color: #000;
            }

            .contact-item__email, .contact-item__mobile {
                font-size: 0.875rem;
                color: $gray;
            }
        }

    }
</style>