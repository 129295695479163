import Api from './Api'

export default {
    getAddresses() {
        return Api.get('/address');
    },
    editAddress(data) {
        return Api.put('/address', data);
    },
    createAddress(data) {
        return Api.post('/address', data);
    },
    deleteAddress(id) {
        return Api.delete('/address', {id: id});
    }
}
