<template>
    <div class="master scrollable">
        <TopBar v-bind:back="'/panier'" v-bind:title="'Votre commande'"/>
        <div class="wrapper" v-if="palette">
            <div class="member-item" @click="user_infos = true">
                <div class="member-item__profile">
                    <img :src="userImage"/>
                </div>
                <div class="member-item__content">
                    <div class="member-item__title">{{ fullName }} <span>(vous)</span></div>
                    <div class="member-item__subtitle">{{ palette.name }}</div>
                </div>
            </div>

            <div class="item-collective-fees" v-if="palette.cardboardCollectiveFees">
                <div class="item-collective-fees__image">
                    <img :src="require('@/assets/img/piggy-bank.svg')"/>
                </div>
                <div class="item-collective-fees__content" v-if="collective_fees > 0">
                    <div class="label">Montant reversé à votre collectif</div>
                    <div class="value">+ {{ collective_fees + '€' }}</div>
                </div>
            </div>

            <div v-if="palette.brand.title === 'Crédit Agricole'" class="mt form">
                <div class="field">
                    <label class="label">
                        <i class="ic icon-location"></i> Sélectionnez votre site pour la livraison
                    </label>
                    <select class="select" ref="custom_input" v-model="additional_data.site"
                            @change="$refs.custom_input.classList.remove('has-error')">
                        <option value="undefined" disabled>Sélectionnez une agence Crédit Agricole</option>
                        <option value="18">Site de Bourges - 18</option>
                        <option value="58">Site de Varennes Vauzelles - 58</option>
                        <option value="45">Site de St Jean de Braye - 45</option>
                    </select>
                </div>
            </div>

            <div v-if="palette.brand.title === 'Axereal'" class="mt form">
                <div class="field">
                    <label class="label">
                        <i class="ic icon-location"></i> Sélectionnez votre site pour la livraison
                    </label>
                    <select class="select" ref="custom_input" v-model="additional_data.site"
                            @change="$refs.custom_input.classList.remove('has-error')">
                        <option value="undefined" disabled>Sélectionnez un site Axereal Group</option>
                        <option value="ARTENAY">ARTENAY</option>
                        <option value="AUNEAU">AUNEAU</option>
                        <option value="AUTHON">AUTHON</option>
                        <option value="AX VIGNE AMBOISE">AX VIGNE AMBOISE</option>
                        <option value="BAILLEAU LE PIN">BAILLEAU LE PIN</option>
                        <option value="BEAUGENCY">BEAUGENCY</option>
                        <option value="BLOIS">BLOIS</option>
                        <option value="BOISVILLE LA ST PERE">BOISVILLE LA ST PERE</option>
                        <option value="BRIARE">BRIARE</option>
                        <option value="CENTRAL PERCHE">CENTRAL PERCHE</option>
                        <option value="CERCY LA TOUR">CERCY LA TOUR</option>
                        <option value="CHATEAUDUN">CHATEAUDUN</option>
                        <option value="CHECY T. LOIRE">CHECY T. LOIRE</option>
                        <option value="COULOMBS">COULOMBS</option>
                        <option value="DANZÉ">DANZÉ</option>
                        <option value="GV AUBIGNY SUR NERE">GV AUBIGNY SUR NERE</option>
                        <option value="GV COSNE SUR LOIRE">GV COSNE SUR LOIRE</option>
                        <option value="GV COUR-CHEVERNY">GV COUR-CHEVERNY</option>
                        <option value="GV ISSOUDUN">GV ISSOUDUN</option>
                        <option value="GV LAMOTTE BEUVRON">GV LAMOTTE BEUVRON</option>
                        <option value="GV MENETOU SALON">GV MENETOU SALON</option>
                        <option value="GV MONTRICHARD">GV MONTRICHARD</option>
                        <option value="GV NOGENT LE ROTROU">GV NOGENT LE ROTROU</option>
                        <option value="GV ROMORANTIN">GV ROMORANTIN</option>
                        <option value="GV VENDOME">GV VENDOME</option>
                        <option value="HERBAULT AGRINEGOCE">HERBAULT AGRINEGOCE</option>
                        <option value="ILLIERS COMBRAY">ILLIERS COMBRAY</option>
                        <option value="LA VILLE AUX CLERCS AGRINEGOCE">LA VILLE AUX CLERCS AGRINEGOCE</option>
                        <option value="LE THEIL">LE THEIL</option>
                        <option value="LORRIS">LORRIS</option>
                        <option value="MAGNY-COURS">MAGNY-COURS</option>
                        <option value="MEAUCÉ">MEAUCÉ</option>
                        <option value="MOLINONS">MOLINONS</option>
                        <option value="MONNAIE">MONNAIE</option>
                        <option value="MONTOIRE">MONTOIRE</option>
                        <option value="NOYERS / CHER">NOYERS / CHER</option>
                        <option value="OLIVET">OLIVET</option>
                        <option value="OUCQUES 1458">OUCQUES</option>
                        <option value="OUZOUER LE MARCHÉ 1780">OUZOUER LE MARCHÉ</option>
                        <option value="PANNECIERES 1341">PANNECIERES</option>
                        <option value="PONTLEVOY 1497">PONTLEVOY</option>
                        <option value="POUILLY SUR LOIRE 2514">POUILLY SUR LOIRE</option>
                        <option value="SANDILLON">SANDILLON</option>
                        <option value="SAVIGNY SUR BRAYE 1605">SAVIGNY SUR BRAYE</option>
                        <option value="ST AMAND LONGPRE 1537">ST AMAND LONGPRE</option>
                        <option value="ST GERMAIN DE LA COUDRE 1662">ST GERMAIN DE LA COUDRE</option>
                        <option value="ST MAUR BEL-AIR">ST MAUR BEL-AIR</option>
                        <option value="TIGY">TIGY</option>
                        <option value="TOURY">TOURY</option>
                        <option value="TROUY">TROUY</option>
                        <option value="VENNECY ">VENNECY</option>
                        <option value="VILLEFRANCHE / CHER">VILLEFRANCHE / CHER</option>
                    </select>
                </div>
            </div>
        </div>

        <p v-if="!isOpened" class="order-error">
            Aïe aïe aïe, il n'est plus possible de participer à cette palette.<br>
            Contactez votre parrain !
        </p>
        <p v-else-if="cardboardLeft <= 0" class="order-error">
            Aïe aïe aïe, il n'y a plus de place sur la palette ! <br>
            Envoyez un message à votre parrain pour réserver sur la prochaine.
        </p>
        <p v-else-if="cardboardLeft < nbItems" class="order-error">
            Aïe aïe aïe, vous ne pouvez commander que {{ cardboardLeft }} cartons !<br>
            Envoyez un message à votre parrain pour réserver sur la prochaine palette
        </p>
        <p v-else-if="nbItems < palette.minimumOrderQuantity" class="order-error">
            Aïe aïe aïe, vous devez commander un minimum de {{ palette.minimumOrderQuantity }} produits
        </p>

        <Modal v-if="user_infos">
            <div slot="body" class="form">
                <p>Complétez les informations pour la commande.</p>
                <div class="field">
                    <label class="label">Prénom</label>
                    <input type="text" class="input" name="firstName" v-model="user.firstName">
                </div>
                <div class="field">
                    <label class="label">Nom</label>
                    <input type="text" class="input" name="lastName" v-model="user.lastName">
                </div>
                <div class="field">
                    <label class="label">Téléphone</label>
                    <input type="tel" class="input" name="mobile" v-model="user.mobile">
                </div>
            </div>
            <div slot="footer" class="double-buttons-container">
                <div>
                    <a @click="cancel" class="btn gr">Annuler</a>
                </div>
                <div>
                    <a @click="saveUser" class="btn bg" :class="loading ? 'loading' : ''">Enregistrer</a>
                </div>
            </div>
        </Modal>

        <div class="wrapper fixed-to-bottom" v-else-if="nbItems">

            <input id="cb-18" type="checkbox" value="1" v-model="check18Plus" class="custom-checkbox" v-if="askAge"/>
            <label for="cb-18" v-if="askAge">
                Je confirme avoir plus de 18 ans
            </label>
            <input id="cb-cgv" type="checkbox" value="1" v-model="checkCGV" class="custom-checkbox"/>
            <label for="cb-cgv">
                J'accepte les <a class="_link">conditions générales de ventes</a>
            </label>

            <div class="cart-summary">
                <div class="cart-summary__line total">
                    <div class="label">Total à payer</div>
                    <div class="value">{{ order_amount + '€' }}</div>
                </div>
                <div class="cart-summary__line">
                    <div class="label">Dont TVA</div>
                    <div class="value">{{ tva_amount + '€' }}</div>
                </div>
            </div>

            <StripeCheckout v-if="stripe"
                            ref="stripeCheckout"
                            :pk="publishableKey"
                            :amount="order_amount"
                            :taxes="tva_amount"
                            :stripe="stripe"
                            :data="additional_data"
                            :enabled="checkCGV && check18Plus && isOpened && cardboardLeft >= nbItems && nbItems >= palette.minimumOrderQuantity"/>

        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import StripeCheckout from "../../components/Payment/StripeCheckout";
import Api from "../../services/Api";
import {loadStripe} from '@stripe/stripe-js/pure';
import {palette} from "@/mixins/palette";
import {cart} from "@/mixins/cart";
import {user} from "@/mixins/user";
import UserService from "@/services/UserService";
import {Ui} from "@/class/Ui";
import Modal from "../../components/UX/Modal";

export default {
    name: "Purchase",
    components: {
        TopBar, StripeCheckout, Modal
    },
    mixins: [palette, cart, user],
    data: () => ({
        loading: false,
        publishableKey: null,
        stripe: null,
        checkCGV: false,
        check18Plus: false,
        askAge: true,
        additional_data: {},
        user_infos: false
    }),
    methods: {
        async fetchConfig() {
            return Api.get('/stripe-config').then(async (config) => {
                this.publishableKey = config.publicKey;
                let options;
                if (this.$store.getters.BRAND && this.$store.getters.BRAND.stripeConnect && this.palette.mode !== "collective") {
                    options = {
                        stripeAccount: this.$store.getters.BRAND.stripeConnect
                    }
                }
                this.stripe = await loadStripe(this.publishableKey, options);
            })
        },
        cancel() {
            if (this.hasInformationCompleted) {
                this.$store.commit('USER_LOADED', this.user);
                this.user_infos = false;
            } else {
                this.$router.push('/panier');
            }
        },
        saveUser() {
            if (!this.loading) {
                if (!this.hasInformationCompleted) {
                    Ui.notif("Merci de compléter vos informations.", "error");
                    this.user_infos = true;
                    return false;
                }
                this.loading = true;
                return UserService.editUser(this.user).then((data) => {
                    if (data.success === true) {
                        this.$store.commit('USER_LOADED', this.user);
                        if (this.hasInformationCompleted) {
                            this.user_infos = false;
                        }
                    }
                }).catch(error => {
                    Ui.notif(error, "error");
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
    },
    computed: {
        hasInformationCompleted() {
            return this.user.firstName && this.user.lastName;
        }
    },
    created() {
        if (parseInt(this.nbItems) === 0) {
            this.$router.push('/panier');
        }

        if (this.$store.getters.BRAND && !this.$store.getters.BRAND.requireMajor) {
            this.askAge = false;
            this.check18Plus = true;
        }

        if (!this.user.lastName) {
            this.user_infos = true;
        }

        this.fetchConfig();
    },
    mounted() {
        this.$store.dispatch("me");
    }
}

</script>


<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

hr {
  margin: 12px 0;
  border-top: 0;
  border-bottom: 1px solid #ddd;
}

.cart-summary {
  padding: math.div($padding-wrapper, 2) 0;
  border-top: 1px solid $gray-light;
  margin-top: math.div($padding-wrapper, 2);

  .cart-summary__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
    color: $gray;

    &.total {
      font-size: 1.2rem;
      font-weight: 500;
      color: #000;
    }

    .value {
      text-align: right;
    }
  }
}

.member-item {
  border-bottom: 1px solid $line-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $font-heading;
  padding: math.div($padding-wrapper, 2) 0;

  .member-item__profile {
    height: 42px;
    width: 42px;
    margin-right: math.div($padding-wrapper, 2);
    border-radius: 1000px;
    overflow: hidden;
    background: #eee;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .member-item__content {
    flex: 1;

    .member-item__title {
      font-size: 1.12rem;
      font-weight: 500;
      color: #000;
    }

    .member-item__subtitle {
      font-size: 0.875rem;
      color: $gray;
    }
  }
}

.item-collective-fees {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid transparent;
  font-family: $font-heading;
  padding: math.div($padding-wrapper, 2) 0;

  .item-collective-fees__image {
    height: 42px;
    width: 42px;
    margin-right: math.div($padding-wrapper, 2);
    border-radius: 1000px;
    overflow: hidden;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item-collective-fees__content {
    flex: 1;

    .value {
      font-size: 1.2rem;
      font-weight: 600;
      color: $green;
    }
  }
}

.btn {
  margin-top: 5px;
}

.order-error {
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 2rem 1rem;
  color: $red;
}

</style>