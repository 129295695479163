<template>
    <div class="master scrollable">
        <TopBar :back="editing ? backUrl : 'back'" title="Recevez les paiements"/>
        <div class="wrapper flex">
            <div class="title" v-if="!existingStripeAccount">Plus qu'une étape de configuration</div>
            <div class="title" v-else>Configuration de votre compte Stripe</div>
            <p class="subtitle">
                Nous utilisons Stripe &copy; pour les paiements et reversements sécurisés sur La Palette.
            </p>
            <div>

                <img class="illu-collective" :src="require('@/assets/img/generous.svg')"/>

                <p class="text mt mb">
                    Des informations légales concernant votre entreprise ou votre association vous seront demandées afin
                    de pouvoir recevoir les commissions récoltées sur chaque vente.
                </p>

                <template v-if="!initLoading">
                    <div v-if="existingStripeAccount" class="mt">
                        <div class="stripe-account-recap">
                            <div class="size-4" v-if="existingStripeAccount.business_profile">
                                {{ existingStripeAccount.business_profile.name }}
                            </div>
                            <div class="size-4" v-else>
                                {{ existingStripeAccount.email }}
                            </div>
                            <div class="subtitle">
                                {{ stripeAccountId }}
                            </div>

                            <div class="mt">
                        <span v-if="existingStripeAccount.charges_enabled">
                            <i class="ic icon-tick green"></i> Votre compte Stripe est configuré. Vous êtes prêt à recevoir de l'argent !
                        </span>
                                <span v-else class="error">
                            <i class="ic icon-alert"></i> Certaines informations sont manquantes
                        </span>
                            </div>
                        </div>

                        <a target="_blank" class="btn btn-l stripe-connect" :class="stripeButtonClass"
                           @click.prevent="connectStripeAccount">
                            Accéder au compte <span></span>
                        </a>
                    </div>
                    <div v-else>
                        <a target="_blank" class="btn btn-l stripe-connect" :class="stripeButtonClass"
                           @click.prevent="connectStripeAccount">
                            Configurer le compte <span></span>
                        </a>
                        <p class="tips center" style="margin: 4px">Promis, 2 minutes suffisent !</p>
                    </div>
                </template>
                <div v-else style="min-height: 200px">
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </div>
        </div>

        <div class="wrapper fixed-to-bottom center" v-if="!this.editing">
            <a @click="next" class="btn btn-l invert">Renseigner les informations plus tard</a>
        </div>

    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import Api from "@/services/Api";
import {Ui} from "@/class/Ui";
import LoadingSpinner from "@/components/UX/LoadingSpinner";

export default {
    name: 'PalletManagerStripeConnect',
    props: ['code', 'editing'],
    components: {
        LoadingSpinner,
        TopBar,
    },
    data() {
        return {
            palette: null,
            backUrl: '/palette/' + this.code + '/parametres',
            stripeLoading: false,
            existingStripeAccount: null,
            initLoading: false,
        }
    },
    computed: {
        stripeButtonClass() {
            if (!this.palette.brand || !this.palette.brand.id) {
                return 'disabled';
            }
            return this.stripeLoading ? 'loading' : '';
        },
        stripeAccountId() {
            return this.palette.brand ? this.palette.brand.stripeConnect : null;
        }
    },
    methods: {
        saveStripe() {
            this.$emit('setStripeAccount', null);
            this.next();
        },
        back() {
            this.$router.push(this.backUrl);
        },
        next() {
            if (this.editing) {
                this.back();
            } else {
                this.$router.push('nom');
            }
        },
        connectStripeAccount() {
            if (!this.stripeLoading) {
                this.stripeLoading = true;
                return Api.post('/stripe-connect', {
                    brand: this.palette.brand.id,
                    return_url: '/palette/' + this.code + '/parametres/stripe'
                }).then((resp) => {
                    if (resp.redirect) {
                        if (resp.blank) {
                            window.open(resp.redirect, '_blank').focus();
                        } else {
                            window.location.href = resp.redirect;
                        }
                    }
                }).catch((error) => {
                    Ui.notif(error.message ? error.message : "Une erreur est survenue", "error");
                }).finally(() => {
                    setTimeout(() => {
                        this.stripeLoading = false;
                    }, 1000);
                });
            }
        }
    },
    mounted() {
        this.initLoading = true;
        if (this.palette.mode !== "collective" && !this.stripeAccountId) {
            this.back();
        }

        if (this.stripeAccountId) {
            Api.get('/stripe-connect/' + this.stripeAccountId).then((resp) => {
                console.log("existing account id ", resp);
                this.existingStripeAccount = resp;
                this.initLoading = false;
            });
        } else {
            this.initLoading = false;
        }
    },
    created() {
        this.palette = this.$parent.palette;
        if (!this.palette) {
            this.back();
        }
    },
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.cardboard-collect-container {
  text-align: center;
}

.cardboard-collect-item {
  margin: 10px 0;
}

.collect-price {
  border: 3px solid $gray-light;
  border-radius: 3px;
  padding: 10px 5px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  font-size: 1.4rem;
  text-align: center;

  &.editable {
    border-color: #000;
  }

  input {
    text-align: center;
    max-width: 100%;
    outline: 0;
    border: 0;
    background: none;
    font-weight: bold;
  }
}

.symbol {
  line-height: 1;
  font-size: 48px;
  font-weight: bold;
}

.tips {
  color: $font-light;
  font-size: 0.875rem;
}


.stripe-connect {
  background: #635bff;
  color: #fff;
  text-decoration: none;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -webkit-font-smoothing: antialiased;
  padding-right: 32px;
}

.stripe-connect span {
  color: #ffffff;
  font-family: "sohne-var", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  padding: 11px 0px 0px 24px;
  position: relative;
  text-align: left;
}

.stripe-connect:hover {
  background: #7a73ff;
}

.stripe-connect.slate {
  background: #0a2540;
}

.stripe-connect.slate:hover {
  background: #425466;
}

.stripe-connect.white {
  background: #ffffff;
}

.stripe-connect.white span {
  color: #0a2540;
}

.stripe-connect.white:hover {
  background: #f6f9fc;
}

.stripe-connect span::after {
  background-repeat: no-repeat;
  content: "";
  height: 100%;
  left: 6px;
  top: 5px;
  position: absolute;
  width: 60px;
}

/* Logos */
.stripe-connect span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.illu-collective {
  max-width: 80%;
  max-height: 200px;
  text-align: center;
  display: block;
  margin: 20px auto;
}

.stripe-account-recap {
  border: 1px solid #e0e0e0;
  padding: 10px;

  + .btn {
    border-radius: 0 0 8px 8px;
  }
}

</style>