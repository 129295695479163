import Api from './Api'
import store from '../Store'

export default {
    getPaletteList() {
        return Api.get('/palette');
    },
    getPaletteActive(id = null) {
        if (id === null) {
            id = store.getters.paletteActive;
        }
        console.debug("Fetch active palette", (id ? id : "default"));
        return Api.get('/palette').then((data) => {
            if (id > 0) {
                const activePalette = data.find(palette => palette.id === parseInt(id));
                if (activePalette) {
                    // la palette avec l'id spécifié est trouvée
                    return activePalette;
                }
                console.debug("Error no active palette found with id " + id);
            }

            // sinon on retourne la première palette de la liste
            return data.length > 0 ? data[0] : null;
        });
    },
    getPaletteFromId(id) {
        return Api.get('/palette/' + id);
    },
    getPalette(code) {
        return Api.get('/palette/code/' + code);
    },
    postPalette(palette) {
        console.log("POST palette", palette);
        if (palette.id > 0) {
            return Api.put('/palette/' + palette.id, palette);
        } else {
            return Api.post('/palette', palette);
        }
    },
    subscribe(code) {
        return Api.post('/palette/subscribe/' + code);
    },
    getMembers(palette) {
        if (palette.id > 0) {
            return Api.get('/palette/' + palette.id + '/members');
        }

        throw new Error('Invalid palette');
    },
    checkoutCode(code) {
        return Api.get('/palette/code-checkout/' + code);
    },
    requestShipping(palette) {
        if (palette && palette.id > 0) {
            return Api.put('/palette/' + palette.id + '/state', {
                id: palette.id,
                state: 4,
            });
        }

        return Promise.reject("Invalid palette");
    },
    getPaletteRanking() {
        return Api.get('/ranking');
    }
}
