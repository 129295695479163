<template>
    <div class="master scrollable">
        <TopBar back="/plus" title="Conditions générales de ventes"></TopBar>
        <div class="wrapper">
            <div v-html="cgv_content" class="cgv-content"/>
        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import MarkdownIt from "markdown-it"
import cgv from "../../assets/cgv.md"

export default {
    name: 'CGV',
    components: {
        TopBar
    },
    data() {
        return {
            url: 'lapalettebos.com',
            cgv_content: null,
        }
    },
    methods: {
        getCGVText() {
            return cgv;
        },
        markedCgv: function (value) {
            var md = new MarkdownIt({
                linkify: true,
            })
            return md.render(value);
        }
    },
    created() {
        this.cgv_content = this.markedCgv(this.getCGVText());
    }
}
</script>

<style lang="scss">

@import "../../styles/vars";

.cgv-content {
  h1 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.25rem;
    margin: 2rem 0 1rem;
  }

  h3 {
    font-size: 1rem;
    margin: 1rem 0 0.5rem;
  }

  h2, h3 {
    font-family: $font-heading;
  }

  ul {
    padding-left: 18px;
    margin: 1rem 0;

    li {
      list-style-type: circle;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid #ddd;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
