<template>
    <div class="master">
        <TopBar back="/profil" title="Récompenses"/>
        <div class="wrapper">
            <p>Gagnez des cartons en faisant grandir votre palette !</p>
        </div>
    </div>
</template>

<script>
    import TopBar from "../../components/UX/TopBar";

    export default {
        name: 'ProfileFidelity',
        components: {
            TopBar
        }
    }
</script>