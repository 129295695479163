<template>
    <div class="top-nav-bar">
        <div v-if="back" class="nav-back">
            <a @click="$router.go(-1)" v-if="back === 'back'">
                <i class="icon-back"></i>
            </a>
            <router-link :to="back" v-else>
                <i :class="icon"></i>
            </router-link>
        </div>
        <div class="nav-content">
            <div class="nav-title">{{ title }}</div>
            <div class="nav-subtitle" v-if="subtitle">{{ subtitle }}</div>
        </div>
        <slot name="action"/>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    props: {
        back: {
            type: [String, Boolean],
            default: false
        },
        title: {
            type: String
        },
        subtitle: {
            type: String,
            required: false
        },
        icon: {
            type: String,
            default: 'icon-back'
        },
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

.top-nav-bar {
  display: flex;
  //border-bottom: 1px solid $line-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  flex: 0;
  padding: math.div($padding-wrapper, 2);
  align-items: center;
  min-height: 56px;
  position: sticky;
  background: #fff;
  z-index: 100;
  top: 0;

  > div {
    padding: 0 math.div($padding-wrapper, 2);
  }

  .nav-back {
    line-height: 1.5rem;
    > a {
      cursor: pointer;
    }
  }

  .nav-content {
    flex: 1;
    padding: 0 math.div($padding-wrapper, 2) 0;
    font-family: $font-body;

    .nav-title {
      padding: 0;
      line-height: 1.5rem;
      font-weight: 600;
      font-size: 1.2rem;
    }

    .nav-subtitle {
      font-size: 1rem;
      color: $gray;
    }
  }
}

.action .ic {
  font-size: 18px;
  line-height: 1;
  vertical-align: -2px;
}
</style>