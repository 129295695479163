<template>
    <router-link class="order-item" to="#">
        <div class="order-item__head">
            <div class="order-item__date">{{ orderDate }}</div>
            <div class="order-item__status">
                <span class="badge" :class="orderStateBadge">{{ orderStateLabel }}</span>
            </div>
        </div>
        <div class="order-item__content">
            <div class="order-item__details">
                {{ paletteInfos }}
            </div>
            <div class="order-item__products" v-if="order.items">
                <div v-for="item in order.items" :key="item.id">
                    x{{ item.quantity }} {{ item.productName }}
                </div>
            </div>
            <div class="cart-total">
                <div class="cart-total__line total">
                    <div class="label">Total TTC : <b>{{ this.$_.round(orderTotal) + '€' }}</b></div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import PaletteService from "../../services/PaletteService";
    import moment from "moment";
    import Api from "../../services/Api";

    export default {
        name: 'OrderListItem',
        props: ['order'],
        data() {
            return {
                orderPalette: null,
            }
        },
        computed: {
            orderDate() {
                moment.locale('fr');
                return moment(this.order.creationDate).format('Do MMM YYYY');
            },
            orderTotal() {
                return this.order.montantTTC;
            },
            orderTva() {
                return this.order.montantTVA;
            },
            paletteInfos() {
                return this.orderPalette ? this.orderPalette.name : "";
            },
            orderStateLabel() {
                const states = {
                    0: "Commande non finalisée",
                    1: "Erreur de paiement",
                    2: "Commande payée",
                    3: "Commande terminée",
                    4: "Commande annulée",
                    5: "Commande remboursée"
                };

                return states[this.order.state];
            },
            orderStateBadge() {
                const states_icons = {
                    0: "bg invert",
                    1: "error",
                    2: "green",
                    3: "green",
                    4: "error",
                    5: "invert"
                };

                return states_icons[this.order.state];
            }
        },
        async mounted() {
            //todo cache ! (call à chaque item de la liste)
            this.orderPalette = await PaletteService.getPaletteFromId(this.order.paletteId);
        }
    }
</script>

<style lang="scss" scoped>

    @use "sass:math";
    @import "./src/styles/vars";

    .order-item {
        border-bottom: 1px solid $line-color;
        display: flex;
        flex-direction: column;
        font-family: $font-body-alt;
        padding: math.div($padding-wrapper, 2) 0;

        .order-item__head {
            display: flex;
            flex-direction: row;
        }

        .order-item__date {
            flex: 1;
            font-size: 1.12rem;
            font-weight: 500;
            color: #000;
        }

        .order-item__details {
            margin-bottom: 1rem;
        }

        .order-item__products {
            font-size: 0.875rem;
            color: $gray;
        }

        .cart-total {
            padding: $padding-wrapper 0;
            font-size: 0.875rem;

            .cart-total__line {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 400;
                color: $gray;

                &.total {
                    font-weight: 500;
                    color: #000;
                }

                .value {
                    text-align: right;
                }
            }
        }
    }
</style>