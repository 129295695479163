<template>
    <router-link v-bind:to="to && !disabled ? to : '#'" class="button-list-item" :class="disabled ? 'disabled' : ''">
        <div class="button-list-item__content">
            <div class="button-list-item__title">{{ title }}</div>
            <div class="button-list-item__subtitle" v-if="subtitle">{{ subtitle }}</div>
        </div>
        <div class="button-list-item__icon" v-if="icon">
            <i class="ic" :class="icon"></i>
        </div>
        <div class="button-list-item__icon" v-if="label">
            {{ label }}
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'ButtonListItem',
    props: {
        title: String,
        subtitle: String,
        icon: String,
        label: String,
        to: String,
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

.button-list-item {
  padding: 0 math.div($padding-wrapper, 2);
  @extend .desktop-wrap;
  border-bottom: 1px solid $line-color;
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $font-body-alt;

  &.disabled {
    .button-list-item__title {
      color: $disabled !important;
    }

    .button-list-item__subtitle {
      color: lighten($disabled, 10) !important;
    }
  }

  .button-list-item__content {
    flex: 1;

    .button-list-item__title {
      font-size: 1.12rem;
      font-weight: 500;
      color: #000;
    }

    .button-list-item__subtitle {
      font-size: 0.875rem;
      color: $gray;
    }
  }

  .button-list-item__icon {
    font-size: 22px;
    line-height: 1;
    margin-right: 12px;

    .icon-security {
      font-size: 28px;
      margin-right: 8px;
    }
  }

  &.red .button-list-item__content .button-list-item__title {
    color: $red;
  }

  &.profile-alert,
  &.alert {
    background: $blue;
    color: $secondary-text;
    flex-direction: row-reverse;
    min-height: 78px;

    .button-list-item__title {
      color: $secondary-text;
    }

    .button-list-item__subtitle {
      color: darken($secondary-text, 10);
    }
  }

  &.pad {
    padding: 0 $padding-wrapper;
  }
}
</style>