<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
        <Modal class="desktopExperienceWarning" v-if="showDesktopModal">
            <h3 slot="header">Pour une meilleure expérience, utilisez cette application sur votre mobile.</h3>
            <div slot="body">
                <p>Cette application a été optimisée pour une utilisation sur smartphone. Scannez ce QR code pour y
                    accéder directement !</p>
                <div class="qrcode">
                    <img :alt="qrcodeURL" :src="qrcode"/>
                </div>
            </div>
            <div slot="footer" class="footer">
                <button class="_link" @click="ignoreDesktopAlert">Continuer la navigation</button>
            </div>
        </Modal>
        <Modal class="shitBrowserWarning" v-if="showOldBrowserModal">
            <h3 slot="header">Woops ! Votre navigateur est obsolète</h3>
            <div slot="body">
                <p>Veuillez télécharger un navigateur moderne pour poursuivre la navigation sur La Palette.</p>
                <p>Cette application a été optimisée pour une utilisation sur smartphone. Scannez ce QR code pour y
                    accéder directement !</p>
                <div class="qrcode">
                    <img :alt="qrcodeURL" :src="qrcode"/>
                </div>
            </div>
            <div slot="footer" class="footer"></div>
        </Modal>
    </div>
</template>

<script>
const default_layout = "container-with-navbar";

import Modal from './components/UX/Modal';
import QRCode from 'qrcode'

export default {
    name: 'App',
    components: {Modal},
    data() {
        return {
            showDesktopModal: false,
            showOldBrowserModal: false,
            qrcode: null,
        }
    },
    created: function () {
        if (this.$store.getters.isAuth && !this.$store.getters.isSessionReady) {
            console.error("Is auth but session is not ready => dispatch me");
            this.$store.dispatch("me").catch(() => {
                this.$store.dispatch('AUTH_LOGOUT');
            });
        }
        this.setDeviceMessages();
        if (this.isIOS()) {
            this.addMaximumScaleToMetaViewport();
        }
    },
    methods: {
        ignoreDesktopAlert() {
            this.$store.dispatch('rememberBrowserAlert');
            this.showDesktopModal = false;
            this.showOldBrowserModal = false;
        },
        async setDeviceMessages() {
            await this.$store.dispatch('checkBrowser').then(ok => {
                if (!ok) {
                    if (!this.qrcode) {
                        this.generateQRCode();
                    }
                    this.showOldBrowserModal = true;
                }
            });

            if (!this.$store.getters.alreadyPopBrowserMessage && !this.showDesktopModal) {
                await this.$store.dispatch('checkMobileDevice').then(is_mobile => {
                    if (!is_mobile) {
                        if (!this.qrcode) {
                            this.generateQRCode();
                        }
                        this.showDesktopModal = true;
                    }
                });
            }
        },
        isIOS() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        },
        addMaximumScaleToMetaViewport() {
            const el = document.querySelector('meta[name=viewport]');

            if (el !== null) {
                let content = el.getAttribute('content');
                let re = /maximum-scale=[0-9.]+/g;

                if (re.test(content)) {
                    content = content.replace(re, 'maximum-scale=1.0');
                } else {
                    content = [content, 'maximum-scale=1.0'].join(', ')
                }

                el.setAttribute('content', content);
            }
        },
        generateQRCode() {
            QRCode.toDataURL(this.qrcodeURL, {
                width: 300
            }).then((resp) => {
                this.qrcode = resp;
            });
        }
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || default_layout) + '-layout';
        },
        qrcodeURL() {
            return window.location.protocol + "//" + window.location.host + this.$route.fullPath;
        }
    }
};
</script>

<style src="./styles/app.scss" lang="scss"/>

<style lang="scss" scoped>
.qrcode {
  text-align: center;
  padding: 10px;
}

.desktopExperienceWarning {
  .footer {
    text-align: right;
  }
}
</style>
