<template>
    <div class="scrollable">
        <p v-if="!this.newPallet">Votre petit nom, pour passer les commandes !</p>
        <div class="field">
            <div class="label">Prénom</div>
            <input type="text" class="input" placeholder="" ref="firstName" :value="this.$parent.user.firstName"
                   autocomplete="given-name"/>
        </div>
        <div class="field">
            <div class="label">Nom</div>
            <input type="text" class="input" placeholder="" ref="lastName" :value="this.$parent.user.lastName"
                   autocomplete="family-name"/>
        </div>
        <div class="field" v-if="!this.$parent.user.email">
            <div class="label">Adresse email</div>
            <input type="email" class="input" placeholder="" ref="email" :value="this.$parent.user.email"
                   autocomplete="email"/>
        </div>
        <div class="field">
            <div class="label">Téléphone</div>
            <input type="tel" class="input" placeholder="" ref="phone" :value="this.$parent.user.phone"
                   v-cleave="{phone: true, phoneRegionCode: 'FR'}" autocomplete="phone"/>
        </div>
        <div class="field">
            <button type="button" class="btn btn-l" @click="nextStep">Continuer</button>
            <div class="center mt" v-if="this.$parent.user.code !== 'start'">
                <button type="button" class="_link" @click="skip">Plus tard</button>
            </div>
        </div>
    </div>
</template>

<script>
import {Ui} from "@/class/Ui";
import Cleave from "cleave.js";

export default {
    name: 'RegisterInfos',
    props: ['user'],
    methods: {
        nextStep() {
            var firstName = this.$refs.firstName.value;
            var lastName = this.$refs.lastName.value;
            var email = this.$refs.email ? this.$refs.email.value : null;
            var phone = this.$refs.phone ? this.$refs.phone.value : null;

            if (firstName.length > 0 && lastName.length > 0 && (this.$parent.user.code !== "start" || phone.length > 0)) {
                this.setInfos(firstName, lastName, phone, email);
                console.log(this.$parent.user);
                this.$router.push('/inscription/mot-de-passe');
            } else {
                Ui.notif("Vérifiez vos informations", "error");
            }
        },
        skip() {
            this.$router.push('/inscription/mot-de-passe');
        },
        setInfos(firstName, lastName, phone, email) {
            this.$parent.user.firstName = firstName;
            this.$parent.user.lastName = lastName;
            if (phone !== null) {
                this.$parent.user.phone = phone;
            }
            if (email !== null) {
                this.$parent.user.email = email;
            }
        }
    },
    computed: {
        newPallet(){
            return this.user.code === 'start';
        }
    },
    created() {
        require('cleave.js/dist/addons/cleave-phone.fr');
    },
    mounted() {
        if (!this.user.email) {
            this.$router.push('/inscription/email');
        }
        this.$refs.firstName.focus();
    },
    directives: {
        cleave: {
            inserted: (el, binding) => {
                el.cleave = new Cleave(el, binding.value || {})
            },
            update: (el) => {
                const event = new Event('input', {bubbles: true});
                setTimeout(function () {
                    el.value = el.cleave.properties.result
                    el.dispatchEvent(event)
                }, 100);
            }
        }
    }
}
</script>
