import Vue from 'vue'
import Vuex from 'vuex'
import {Ui} from './class/Ui';
import PaletteService from "./services/PaletteService";
import CartService from "./services/CartService";
import UserService from "./services/UserService";
import BrandService from "./services/BrandService";

Vue.use(Vuex);

const initialState = () => {
    return {
        auth_token: localStorage.getItem('auth_token') || '',
        session_uui: localStorage.getItem('session_uuid') || '',
        palette: null,
        paletteActive: localStorage.getItem('palette_active') || null,
        members: null,
        user: null,
        status: null,
        cart: {
            items: [],
            total: 0.0,
            collectiveFees: 0.0,
            tva: 0.0,
            loaded: false,
        },
        products: [],
        env_brand: null,
        env_brand_data: [],
        categories: [],
        is_desktop: null,
        is_old_browser: null,
        alert_desktop: !!localStorage.getItem('alert_desktop') || false,
        alert_avatar: localStorage.getItem('alert_avatar') || 0,
    }
}

const getters = {
    isAuth: state => !!state.auth_token,
    isSessionReady: state => state.session_uui.length > 0 && state.session_uui !== "undefined",
    AUTH_TOKEN: state => state.auth_token,
    ACTIVE_PALETTE: state => state.palette,
    ACTIVE_PALETTE_ID: state => state.paletteActive,
    USER: state => state.user,
    isGodfather: state => !!(state.user && state.user.accountType),
    isGodfatherPalette: (state) => (palette = null) => {
        if (!state.user) {
            return false;
        }
        if (palette !== null) {
            return parseInt(palette.userId) === parseInt(state.user.id) && state.user.accountType > 0;
        }
        return state.user.accountType > 0;
    },
    SESSION_UUID: state => state.session_uui,
    CART: state => state.cart,
    PRODUCTS: state => state.products,
    CATEGORIES: state => state.categories,
    PALETTE_MEMBERS: state => state.members,
    USER_AS_MEMBER: state => {
        return state.members.find(member => member.user.id === state.user.id);
    },
    getProductById: (state) => (id) => {
        return state.products.find(product => product.id === id);
    },
    shouldAskUserAvatar: (state) => {
        return (state.user.image == null || state.user.image.length === 0) && parseInt(state.alert_avatar) < Date.now();
    },
    getAuthUserImage: (state) => {
        if (state.user.image == null || state.user.image.length === 0) {
            if (state.user.accountType > 0) {
                return require('@/assets/img/people/parrain.svg');
            } else {
                return require('@/assets/img/people/users.svg');
            }
        }
        return state.user.image;
    },
    getMemberImage: (state) => (member) => {
        if (!member.image || member.image.length === 0) {
            if (member.accountType > 0) {
                return require('@/assets/img/people/parrain.svg');
            } else {
                return require('@/assets/img/people/users.svg');
            }
        }
        return member.image;
    },
    getCartTotalHT: (state) => {
        return Vue.prototype.round(state.cart.items.reduce((price, item) => {
            return price + Vue.prototype.round((item.price + item.fees) * item.quantity, 2)
        }, 0.0), 2);
    },
    getCartTotalTTC: (state) => {
        return Math.round((state.cart.items.reduce((price, item) => {
            return price + Vue.prototype.round(((item.price * (1 + (item.tva / 100))) + item.fees) * item.quantity, 2)
        }, 0.0) + Number.EPSILON) * 100) / 100;
    },
    getCartTotalTVA: (state) => {
        return Vue.prototype.round(state.cart.items.reduce((price, item) => {
            return price + (Math.round(item.price * item.tva) * item.quantity / 100)
        }, 0.0), 2);
    },
    getCartTotalCollectiveFees: (state) => {
        return Vue.prototype.round(state.cart.items.reduce((totalFees, item) => {
            return totalFees + Vue.prototype.round(item.fees * item.quantity, 2);
        }, 0.0), 2);
    },
    getCartItemsCount: (state) => {
        return state.cart.items.reduce((count, item) => count + item.quantity, 0);
    },
    IS_SHIT_BROWSER: state => state.is_old_browser,
    IS_DESKTOP: state => state.is_desktop,
    alreadyPopBrowserMessage: state => !!state.alert_desktop,
    BRAND: state => state.env_brand,
    CAN_SHARE: state => state.env_brand && state.env_brand.enableSharing,
    ENV_DATA: (state) => (key, default_value) => {
        if (key in state.env_brand_data && state.env_brand_data[key].length > 0) {
            return state.env_brand_data[key];
        }
        return default_value;
    },
}

const actions = {
    AUTH_REQUEST: ({commit}, authData) => {
        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST')
            UserService.signin(authData.email, authData.password).then((data) => {
                localStorage.setItem('auth_token', data.token);
                commit('USER_LOADED', data.user);
                commit('AUTH_SUCCESS', data.token);
                resolve(data.user);
            }).catch(err => {
                Ui.notif(err, "error");
                localStorage.removeItem('auth_token');
                commit('AUTH_ERROR', err)
                reject(err)
            })
        })
    },
    AUTH_LOGOUT: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('palette_active');
            localStorage.removeItem('session_uuid');
            commit('SET_SESSION', '');
            commit('AUTH_LOGOUT');
            resolve();
        })
    },
    LOAD_PALETTE: ({commit, dispatch}, activeId = 0) => {
        return new Promise((resolve, reject) => {

            if (activeId === 0) {
                activeId = store.getters.ACTIVE_PALETTE_ID;
            }

            if (activeId > 0 && store.getters.ACTIVE_PALETTE_ID !== activeId) {
                // Changement de palette, reset du panier et de la session
                console.log("Change palette active", store.getters.ACTIVE_PALETTE_ID, activeId)
                localStorage.removeItem('palette_active');
                commit('BRAND_LOADED', null);
                commit('BRAND_DATA_LOADED', []);
                commit('UNLOAD_CART');
                dispatch('RESET_SESSION_UUID');
                dispatch('me');
            }

            return PaletteService.getPaletteActive(activeId).then((data) => {
                if (data) {
                    console.log("store palette " + (activeId ? activeId : "default"), data);
                    commit('PALETTE_LOADED', data);
                    dispatch("LOAD_ENVIRONMENT_DATA").then(() => {
                        resolve(data);
                    })
                } else {
                    reject("No palette");
                }
            }).catch((err) => {
                console.log("err");
                reject(err);
            });
        });
    },
    SET_PALETTE: ({commit, dispatch}, data) => {
        return new Promise((resolve, reject) => {
            commit('PALETTE_LOADED', data);
            dispatch("LOAD_ENVIRONMENT_DATA").then(() => {
                resolve();
            })
        });
    },
    LIST_MEMBERS: async ({commit, dispatch}) => {
        return PaletteService.getMembers(store.getters.ACTIVE_PALETTE).then((data) => {
            commit('SET_MEMBERS', data);
            return data;
        });
    },
    LOAD_CART: ({commit, dispatch}) => {
        return CartService.loadCart().then((resp) => {
            commit('CART_LOADED', resp);
        })
    },
    CART_ADD: ({commit, dispatch}, data) => {
        return CartService.addItem(data.productId, data.quantity).then((resp) => {
            console.log("added", resp)
            if (resp.success === true && resp.element) {
                commit('CART_ITEM_ADD', resp.element);
            }
        })
    },
    CART_UPDATE: ({commit, dispatch}, data) => {
        return CartService.editItem(data.productId, data.quantity).then((resp) => {
            console.log("updated", resp)
            if (resp.success === true) {
                commit('CART_ITEM_UPDATE', data);
            }
        })
    },
    CART_REMOVE: ({commit, dispatch}, productId) => {
        return CartService.removeItem(productId).then((data) => {
            if (data.success === true) {
                commit('CART_ITEM_REMOVE', productId);
            }
        })
    },
    LOAD_CATALOG: ({commit, dispatch}, paletteId) => {
        if (!paletteId) {
            paletteId = store.getters.ACTIVE_PALETTE_ID;
        }
        return CartService.getCatalog(paletteId).then((categories) => {
            const products = categories.map(category => category.products.map(product => {
                product.category = category.reference;
                return product;
            })).flat();
            commit('CATALOG_LOADED', products);
            commit('CATEGORIES_LOADED', categories);
        });
    },
    me: ({commit, dispatch, getters}, cache = false) => {
        if (getters.USER && cache) {
            console.debug("/me from cache");
            return getters.USER;
        }
        return UserService.getCurrentUser().then(data => {
            console.log("/me", data);
            commit('USER_LOADED', data.user);
            commit('SET_SESSION', data.sessionId);
            localStorage.setItem('session_uuid', data.sessionId);
        });
    },
    LOAD_ENVIRONMENT_DATA: async ({commit, dispatch, getters}) => {
        if (!getters.ACTIVE_PALETTE) {
            await dispatch('LOAD_PALETTE');
        }

        console.log("Brand from palette", getters.ACTIVE_PALETTE.id, getters.ACTIVE_PALETTE.brand);
        return Promise.all([
            commit('BRAND_LOADED', getters.ACTIVE_PALETTE.brand),

            BrandService.getBrandData(getters.ACTIVE_PALETTE).then((data) => {
                commit('BRAND_DATA_LOADED', data);
            })
        ]);
    },
    RESET_SESSION_UUID: ({commit}) => {
        localStorage.removeItem('session_uuid');
        commit('SET_SESSION', "");
    },
    rememberBrowserAlert: ({commit}) => {
        localStorage.setItem('alert_desktop', '1');
        commit('REMEMBER_BROWSER', true);
    },
    checkMobileDevice: ({commit, dispatch}) => {
        let check = false;
        return new Promise((resolve, reject) => {
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            commit('IS_DESKTOP', !check);
            resolve(check);
        });
    },
    checkBrowser: ({commit}) => {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        return new Promise((resolve, reject) => {
            const is_old = msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./) || typeof Promise === "undefined";
            commit('IS_OLD_BROWSER', is_old);
            resolve(!is_old);
        });
    },
    confirmUserAvatar: ({commit}) => {
        const not_before = Date.now() + (172800 * 1000); // 2 jours
        localStorage.setItem('alert_avatar', not_before)
        commit('CONFIRM_AVATAR_ALERT', not_before);
    }
};

const mutations = {
    AUTH_REQUEST: (state) => {
        state.status = 'loading'
        state.palette = null;
    },
    AUTH_SUCCESS: (state, token) => {
        state.status = 'success'
        state.auth_token = token;
    },
    AUTH_LOGOUT: (state) => {
        state.status = null;
        state.auth_token = null;
        state.palette = null;
        state.members = null;
        state.cart.loaded = false;
    },
    UNLOAD_CART: (state) => {
        state.cart.loaded = false;
        state.members = null;
        state.status = null;
    },
    RESET_ELEMENTS: (state) => {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        });
    },
    AUTH_ERROR: (state) => {
        state.status = 'error'
    },
    PALETTE_LOADED: (state, palette) => {
        state.palette = palette;
        state.paletteActive = palette ? palette.id : 0;
        localStorage.setItem('palette_active', palette ? palette.id : 0);
    },
    SET_MEMBERS: (state, members) => {
        state.members = members;
    },
    USER_LOADED: (state, user) => {
        state.user = user;
    },
    BRAND_LOADED: (state, data) => {
        state.env_brand = data;
    },
    BRAND_DATA_LOADED: (state, data) => {
        state.env_brand_data = data;
    },
    SET_SESSION: (state, sessionId) => {
        console.log("set session id", sessionId);
        state.session_uui = sessionId;
    },
    CART_ITEM_ADD(state, data) {
        const found = state.cart.items.findIndex(p => p.productId === data.productId);
        const product = state.products.find(product => product.id === data.productId);

        if (found >= 0) {
            console.error("add but existing product", state.cart.items);
            state.cart.items[found].quantity += data.quantity;
        } else {
            state.cart.items.push({
                productId: data.productId,
                productName: data.productName,
                quantity: data.quantity,
                price: product.price,
                fees_apply: product.fees_apply,
                fees: data.fees,
                tva: data.tva,
            });
        }
    },
    CART_ITEM_UPDATE(state, data) {
        const found = state.cart.items.findIndex(p => p.productId === data.productId);
        if (found >= 0) {
            if (state.cart.items[found].quantity <= 0) {
                state.cart.items.splice(found, 1);
            } else {
                state.cart.items[found].quantity = data.quantity;
            }
        } else {
            console.error('update but not found ' + data.productId + ' in ', state.cart.items);
        }
    },
    CART_ITEM_REMOVE(state, productId) {
        const found = state.cart.items.findIndex(p => p.productId === productId);
        if (found >= 0) {
            state.cart.items.splice(found, 1);
        }
    },
    CART_LOADED: (state, cart) => {
        console.log("cart loaded", cart);
        state.cart = cart;
        state.cart.loaded = true;
    },
    CATALOG_LOADED: (state, products) => {
        state.products = products;
    },
    CATEGORIES_LOADED: (state, categories) => {
        state.categories = categories;
    },
    IS_DESKTOP: (state, value) => {
        state.is_desktop = value;
    },
    IS_OLD_BROWSER: (state, value) => {
        state.is_old_browser = value;
    },
    REMEMBER_BROWSER: (state, value) => {
        state.alert_desktop = value;
    },
    CONFIRM_AVATAR_ALERT: (state, value) => {
        state.alert_avatar = value;
    }
}

const store = new Vuex.Store({
    state: initialState(),
    actions,
    mutations,
    getters
});

export default store