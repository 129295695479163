<template>
    <form class="master" @submit.prevent="savePalette" v-if="!isLoading">
        <TopBar back="/palette" title="Ma palette"
                subtitle="Configurez vos informations" v-if="isEditing"/>

        <TopBar back="/palette" title="Créer une palette"
                subtitle="Configurez vos informations" v-else/>

        <ButtonListItem to="/profil/informations" v-if="isEditing && palette.state == this.STATE_PENDING_APPROVAL"
                        class="alert pad"
                        :title="paletteState"
                        subtitle="La palette a bien été enregistrée. Nous revenons vers vous sous 24h max."
                        icon="icon-check-1"/>

        <div class="button-list">

            <ButtonListItem to="parametres/nom"
                            v-bind:title="'Nom de cette palette'"
                            v-bind:subtitle="palette.name"
                            v-bind:icon="'icon-place'"/>

            <ButtonListItem to="parametres/mode"
                            v-bind:disabled="!palette.isEditable"
                            v-bind:title="'Type de palette'"
                            v-bind:subtitle="palette.mode === 'collective' ? 'Mode collecte : récoltez des fonds pour votre association. ' : 'Mode standard : des cartons offerts !'"
                            v-bind:icon="'icon-place'"/>

            <ButtonListItem to="parametres/date"
                            v-bind:disabled="!palette.isEditable"
                            v-bind:title="'Date / délai'"
                            v-bind:subtitle="getPaletteDate"
                            v-bind:icon="'icon-calendar'"/>

            <ButtonListItem to="parametres/adresse"
                            v-bind:title="'Lieu de livraison'"
                            v-bind:subtitle="paletteAddress"
                            v-bind:icon="'icon-location'"/>

            <ButtonListItem v-if="isCollective"
                            to="parametres/cagnotte"
                            title="Paramètre de la cagnotte"
                            subtitle="Récoltez de l'argent pour votre collectif"
                            icon="icon-supply"/>

            <ButtonListItem v-if="isCollective || palette.brand.stripeConnect"
                            to="parametres/stripe"
                            title="Paiements Stripe"
                            subtitle="Connectez-vous à Stripe pour recevoir l'argent"
                            icon="icon-stripe"/>
        </div>
        <div class="wrapper fixed-to-bottom">
            <button type="submit" class="btn btn-l bg" ref="btnPaletteSave">
                {{ isEditing ? 'Sauvegarder la palette' : 'Commencer ma palette' }}
            </button>
        </div>
    </form>
</template>


<script>
import ButtonListItem from "../../components/List/ButtonListItem";
import TopBar from "../../components/UX/TopBar";
import moment from "moment";
import {palette} from "@/mixins/palette";

export default {
    name: 'PalletManager',
    mixins: [palette],
    props: ["code", "loading"],
    computed: {
        isEditing() {
            return this.code && this.code.length > 0 && this.code !== 'start';
        },
        palette() {
            return this.$parent.palette;
        },
        getPaletteDate() {
            moment.locale('fr');
            if (!this.palette.startDate) {
                return "Jusqu'au " + moment(this.palette.expirationDate).format('dddd D MMMM');
            }
            return 'Du ' + moment(this.palette.startDate).format('dddd D MMMM') + ' au ' + moment(this.palette.expirationDate).format('dddd D MMMM');
        },
        isLoading() {
            return this.loading;
        },
        paletteAddress() {
            if (this.palette.address) {
                return ((this.palette.address.address ? this.palette.address.address : "") + " " +
                    (this.palette.address.zipCode ? this.palette.address.zipCode : "") + " " +
                    (this.palette.address.city ? this.palette.address.city : "")
                ).trim();
            }

            return "";
        }
    },
    methods: {
        savePalette() {
            this.$emit('save', true);
            this.$refs.btnPaletteSave.classList.add('loading');
            setTimeout(() => {
                this.$refs.btnPaletteSave.classList.remove('loading');
            }, 1000);
        }
        ,
    }
    ,
    components: {
        TopBar,
        ButtonListItem,
    }
}
</script>