<template>
    <div class="master scrollable" v-if="!loading">
        <div class="wrapper head">
            <div class="title">En savoir plus</div>
        </div>
        <div class="button-list">
            <ButtonListItem to="/classement" icon="icon-trophy-1" v-if="showDefaultBrandMenu"
                            title="Classement des palettes"/>

            <ButtonListItem to="/a-propos" title="A propos de la Brasserie BOS"
                            icon="icon-beer"
                            v-if="this.$store.getters.ENV_DATA('about-page', 'about-bos.html') === 'about-bos.html'"/>

            <ButtonListItem to="/a-propos" title="A propos"
                            icon="icon-beer" v-else/>

            <ButtonListItem to="/welcome" icon="icon-question" v-if="this.$store.getters.isGodfather"
                            title="Comment ça marche ?"/>

            <ButtonListItem to="/conditions-generales-de-vente"
                            title="Conditions générales" icon="icon-document"/>

            <ButtonListItem title="Un problème ? Contactez-nous" :subtitle="version" v-on:click.native="help"/>
        </div>
        <div class="wrapper fixed-to-bottom" v-if="isGodFather || survey">
            <button v-if="survey" class="btn bg-red btn-l multilines" v-on:click="survey.open()">
                Donnez votre avis<br>
                <small>Aidez nous à améliorer le service</small>
            </button>
            <div class="form" v-if="palette && isGodFather">
                <div class="field mt">
                    <label class="label">Code palette à partager</label>
                    <input type="text" class="input center" :value="palette.code"
                           @click="copyPaletteCodeToClipboard" readonly/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonListItem from "../../components/List/ButtonListItem";
import {palette} from "@/mixins/palette";
import * as typeformEmbed from '@typeform/embed'

export default {
    name: 'More',
    mixins: [palette],
    components: {
        ButtonListItem
    },
    data() {
        return {
            loading: true,
            account: {
                confirmed: false,
            },
            version: null,
            survey: null,
            surveyCode: null,
        }
    },
    computed: {
        palette() {
            return this.$store.getters.ACTIVE_PALETTE;
        },
        isGodFather() {
            return this.$store.getters.isGodfather;
        },
        showDefaultBrandMenu() {
            return this.$store.getters.BRAND ? this.$store.getters.BRAND.isDefault : true;
        }
    },
    methods: {
        async shareLink() {
            try {
                await window.navigator.share({
                    title: "Découvre l'application La Palette",
                    url: "https://lapalettebos.com"
                });
            } catch (err) {
                //
            }
        },
        help() {
            return window.open('mailto:' + process.env.VUE_APP_CONTACT_EMAIL);
        },
        copyPaletteCodeToClipboard(){
            this.$_.copyToClipboard(this.palette.code);
        }
    },
    mounted() {
        this.loading = true;
        if (!this.$store.getters.ACTIVE_PALETTE) {
            this.getCurrentPalette().then(() => {
                this.loading = false;
            }).catch(e => {
                this.loading = false;
            });
        } else {
            this.loading = false;
        }
        this.version = (require('../../../package.json').version).toString();
    },
    created() {
        this.surveyCode = this.$store.getters.BRAND ? this.$store.getters.BRAND.survey : null;
        if (this.surveyCode) {
            console.log("survey code", this.surveyCode);
            this.survey = typeformEmbed.makePopup(
                'https://form.typeform.com/to/' + this.surveyCode,
                {
                    mode: 'popup',
                    hideHeaders: false,
                    hideFooter: true,
                    autoOpen: false,
                    opacity: 50,
                    autoClose: 3,
                    onSubmit: function (e) {
                        console.log("survey submit", e);
                    }
                }
            );
        }
    }
}
</script>
