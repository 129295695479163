<template>
    <Modal v-if="modalPaletteList" :show-close-button="true" v-on:cancel="modalPaletteList = false">
        <div slot="body">
            <div class="selectable-list" v-if="palettesListLoaded">
                <div class="selectable-list-item no-border" :class="palette && p.id === palette.id ? 'active' : ''"
                     v-for="p in palettesAvailable" v-bind:key="p.id">
                    <a v-on:click="changePalette(p.id)">
                        <i v-if="palette && p.id === palette.id" class="ic icon-correct selected"></i>
                        <i v-else class="ic icon-correct disabled"></i>
                        <div class="palette-details">
                            <div class="palette-name">{{ p.name }}</div>
                            <span class="palette-date" v-if="p.startDate && p.expirationDate">
                                {{ palettePeriod(p.startDate, p.expirationDate) }}
                            </span>
                        </div>
                        <span class="palette-status right badge gr">
                            {{ getStateStr(p.state) }}
                        </span>
                    </a>
                </div>
            </div>
            <LoadingSpinner v-else class="selectable-list loading"/>
        </div>
        <div slot="footer">
            <a v-on:click="changePalette(0)" class="btn btn-l invert">
                <i class="icon-plus mr"></i> Commencer une palette
            </a>
            <router-link to="/rejoindre" class="btn btn-link" style="margin-top: 5px;">
                Rejoindre une palette existante
            </router-link>
        </div>
    </Modal>
</template>

<script>
import PaletteService from "@/services/PaletteService";
import Modal from "@/components/UX/Modal";
import LoadingSpinner from "@/components/UX/LoadingSpinner";
import {palette} from "@/mixins/palette";
import moment from "moment";

export default {
    name: "ModalPaletteList",
    mixins: [palette],
    components: {
        Modal,
        LoadingSpinner
    },
    data() {
        return {
            modalPaletteList: false,
            palettesAvailable: [],
            palettesListLoaded: false
        }
    },
    methods: {
        showPaletteList() {
            this.modalPaletteList = true;
            this.palettesListLoaded = false;
            PaletteService.getPaletteList().then((data) => {
                this.palettesAvailable = data;
                this.palettesListLoaded = true;
            });
        },
        palettePeriod(start, end) {
            moment.locale('fr');
            return "Du " + moment(start).format("Do MMM") + " au " + moment(end).format("Do MMM YYYY");
        }
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

.selectable-list {

  margin-left: math.div(-$padding-wrapper, 2);
  margin-right: math.div(-$padding-wrapper, 2);

  .selectable-list-item {

    > a {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: math.div($padding-wrapper, 2);
      border-radius: 8px;
    }

    &.active > a {
      background: transparentize($primary, .9);
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    .ic {
      font-size: 18px;

      &.disabled {
        color: #ccc;
      }

      &.selected {
        color: $primary;
      }
    }

    .palette-details {
      padding: 0 12px;
      vertical-align: 2px;
      line-height: 1.25;

      .palette-name {
        font-weight: bold;
        color: $gray-dark;
      }

      .palette-date {
        color: $gray;
        font-size: 0.875rem;
      }
    }

    .palette-status {
      margin-left: auto;
    }
  }
}

</style>