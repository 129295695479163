<template>
    <div class="master">
        <TopBar :back="editing ? backUrl : '/palette'" icon="icon-cancel" title="Quel est votre objectif ?"/>
        <div class="wrapper scrollable flex">
            <div class="mode-selector-container">
                <div class="mode-selector">
                    <input id="rd-select-mode-standard" type="radio" v-model="selected_mode" value="standard">
                    <label for="rd-select-mode-standard">
                        <img :src="require('@/assets/img/beer.svg')"/>
                        <div class="selector-title">Des cartons offerts</div>
                        <p class="selector-subtitle handwriting">Idéal pour vous faire plaisir entre amis</p>
                        <div class="tag default-tag invert">PAR DÉFAUT</div>
                        <div class="mode-description">
                            12 bouteilles offertes par tranche de 20 cartons sur la palette !
                        </div>
                    </label>
                </div>

                <div class="mode-selector">
                    <input id="rd-select-mode-collective" type="radio" v-model="selected_mode" value="collective">
                    <label for="rd-select-mode-collective">
                        <img :src="require('@/assets/img/generous.svg')"/>
                        <div class="selector-title">Récoltez des fonds</div>
                        <p class="selector-subtitle handwriting">Animez votre association ou votre club</p>
                        <div class="mode-description">
                            Pour chaque carton ajouté sur votre palette, une partie du prix revient à votre collectif !
                        </div>
                    </label>
                </div>
            </div>
        </div>

        <div class="wrapper fixed-to-bottom">
            <button @click="saveMode" class="btn btn-l bg" :class="!selected_mode ? 'disabled' : ''">
                Continuer
            </button>
        </div>
    </div>
</template>

<script>
import TopBar from "@/components/UX/TopBar";
export default {
    name: 'PalletManagerCollectFee',
    components: {TopBar},
    props: ['code', 'editing'],
    data() {
        return {
            palette: null,
            backUrl: '/palette/' + this.code + '/parametres',
            selected_mode: "standard",
        }
    },
    methods: {
        back() {
            this.$router.push(this.backUrl);
        },
        saveMode() {
            this.$emit('setPaletteMode', this.selected_mode);
            this.$nextTick(() => {
                if (this.selected_mode === 'collective') {
                    this.$router.push('cagnotte');
                } else if (!this.editing) {
                    this.$router.push('nom');
                } else {
                    this.back();
                }
            });
        }
    },
    mounted() {
        if (this.palette.mode !== null) {
            this.selected_mode = this.palette.mode;
        }
    },
    created() {
        this.palette = this.$parent.palette;
        if (!this.palette) {
            this.back();
        }
    },
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.mode-selector-container {
  display: flex;
  align-items: stretch;
  padding: 5px 0;
  flex-direction: row;
  margin-left: -5px;
  margin-right: -5px;

  @media(max-width: $width-m) {
    flex-direction: column;
  }
}

.mode-selector {
  flex: 1;
  margin: 5px;

  input[type="radio"] {
    display: none;
  }

  label {
    text-align: center;
    padding: $padding-wrapper;
    border: 3px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    height: 100%;
    transition: all .2s;
    position: relative;
    background: #fff;
  }

  .default-tag {
    position: absolute;
    left: 5px;
    top: 5px;
    border-color: $green;
    background: $green;
    color: #fff;
    font-weight: 600;
  }

  input[type="radio"]:checked + label {
    border-color: $primary;
    background: transparentize($primary, 0.9);
  }

  svg, img {
    height: 60px;
    max-width: 100%;
    margin: 2px 0 15px;
    fill: #697386;
  }

  .selector-title {
    display: block;
    color: $gray-dark;
    font-weight: 700;
    text-transform: uppercase;
    margin: 5px 0;
    font-size: 1.2rem;
  }

  .mode-description {
    color: $gray;
  }

}

</style>