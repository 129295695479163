<template>
    <div class="form master">
        <TopBar back="/profil" title="Mes commandes"/>
        <div class="wrapper" v-if="!this.loading">
            <div v-if="orders.length > 0">
                <OrderListItem v-for="order in orders"
                               v-bind:order="order"
                               v-bind:key="order.id "/>
            </div>
            <div v-else>
                <p>Aucune commande pour le moment</p>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import OrderListItem from "../../components/List/OrderListItem";
import Api from "../../services/Api";

export default {
    name: 'ProfileOrders',
    data() {
        return {
            orders: [],
            loading: true,
        }
    },
    components: {
        TopBar,
        OrderListItem,
    },
    computed: {
        getUserImage() {
            return this.$store.getters.getAuthUserImage;
        }
    },
    methods: {
        fetchOrders() {
            return Api.get('/order')
        }
    },
    created() {
        this.loading = true;
        this.fetchOrders().then((orders) => {
            this.loading = false;
            this.orders = orders;
            console.log("orders", this.orders);
        });
    }
}
</script>

<style lang="scss" scoped>

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
  flex: 0;

  .profile-header__image {
    margin-right: 12px;
    width: 80px;
    height: 80px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10000px;
    }
  }
}

</style>