import {Ui} from "@/class/Ui";

const common = {
    round: (number, precision = 2, fixedDecimals = false) => {
        var shift = function (number, exponent) {
            var numArray = ("" + number).split("e");
            return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + exponent) : exponent));
        };
        precision = precision === undefined ? 2 : precision;
        const rounded = shift(Math.round(shift(number, +precision)), -precision);
        if (!fixedDecimals && (rounded * 100) % 100 === 0) {
            return rounded;
        }
        return rounded.toFixed(precision);
    },
    copyToClipboard: (input) => {
        var promise;
        if (typeof input === "string") {
            promise = navigator.clipboard.writeText(input);
        } else {
            promise = new Promise((resolve) => {
                input.select();
                resolve(document.execCommand("copy"));
            });
        }

        return promise.then(() => {
            Ui.notif('Le texte a été copié dans le presse-papier', 'success');
        });
    },
    cookies_get: (name) => {
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    },
    cookies_set: (name, value, expiration = -1, path = null, domain = null, secure = false) => {
        if (!name || /^(?:expires|max\-age|path|domain|secure)$/i.test(name)) {
            console.log("err cookie set ");
            return false;
        }
        let sExpires = "";
        if (expiration) {
            switch (expiration.constructor) {
                case Number:
                    sExpires = expiration === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + expiration;
                    break;
                case String:
                    sExpires = "; expires=" + expiration;
                    break;
                case Date:
                    sExpires = "; expires=" + expiration.toUTCString();
                    break;
            }
        }
        var cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + sExpires + (domain ? "; domain=" + domain : "") + (path ? "; path=" + path : "") + (secure ? "; secure" : "");
        console.log("cookie", cookie);
        document.cookie = cookie;
        return true;
    },
    cookies_remove: (name, path = null, domain = null) => {
        if (!name || !this.cookies_has(name)) {
            return false;
        }
        document.cookie = encodeURIComponent(name) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (domain ? "; domain=" + domain : "") + (path ? "; path=" + path : "");
        return true;
    },
    cookies_has: (name) => {
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }
}

export default common