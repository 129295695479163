<template>
    <div class="master scrollable" v-if="palette">
        <TopBar :back="editing ? backUrl : 'back'" title="La date" subtitle="Vous avez le choix aussi"/>
        <div class="wrapper form">
            <div class="field date-selector-container center">
                <div class="label">Sélectionner la période de commande</div>
                <VueCtkDateTimePicker
                        :format="'YYYY-MM-DD'"
                        :formatted="'ll'"
                        :label="'Modifiez la date'"
                        :only-date="true"
                        :no-weekends-days="false"
                        :locale="'fr-FR'"
                        :min-date="today.format('YYYY-MM-DD')"
                        :no-label="true"
                        :range="true"
                        :button-now-translation="'Aujourd\'hui'"
                        :no-shortcuts="true"
                        :overlay="false"
                        :inline="true"
                        :no-header="true"
                        :color="'#e89321'"
                        v-model="dateRange">

                </VueCtkDateTimePicker>

                <div class="date-str error" v-if="errorDate">
                    {{ errorDate }}
                </div>
                <div v-else class="date-str">
                    Vous avez <strong>{{ getDeliveryDays }}</strong> pour compléter la palette
                    <span v-if="getDateStart && getDateEnd" v-html="getDeliveryDateRange"></span>
                </div>
            </div>
            <p>
                Pour garder une bonne dynamique et ne pas risquer de recevoir vos bières dans 2 ans, le délai maximum
                de commande est de <span class="highlight"><span>1 mois</span></span>.
            </p>
            <p>
                Si toutefois le coeur vous en dit, et la soif vous tiraille, vous pouvez choisir un délai plus court.
                Dans tous les cas, vous pourrez déclencher la livraison dès 20 cartons !
            </p>
        </div>
        <div class="wrapper fixed-to-bottom">
            <button class="btn btn-l bg" :class="btnClass"
                    @click="saveDate">
                Confirmer la date
            </button>
            <div class="illustration">
                <img :src="require('@/assets/img/ui/datte.jpg')" alt="La datte"/>
                <span class="handwriting">Une datte</span>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from "moment";

export default {
    name: 'PalletManagerName',
    props: ['code', 'editing'],
    components: {
        TopBar, VueCtkDateTimePicker
    },
    data() {
        return {
            palette: null,
            backUrl: '/palette/' + this.code + '/parametres',
            today: this.moment(),
            dateRange: {
                start: null,
                end: null,
            }
        }
    },
    computed: {
        btnClass() {
            if (this.errorDate) {
                return 'disabled';
            }
            return this.editing ? 'invert' : '';
        },
        todayStr() {
            return this.today.format('Y-m-d');
        },
        getDateStart() {
            return moment(this.dateRange.start);
        },
        getDateEnd() {
            return moment(this.dateRange.end);
        },
        getDeliveryDateRange() {
            let str = "(du ";
            if (this.getDateStart.month() === this.getDateEnd.month()) {
                str += this.getDateStart.format("Do");
            } else {
                str += this.getDateStart.format("Do MMMM");
            }

            str += " au " + this.getDateEnd.format("Do MMMM") + ")";
            return str;
        },
        getDeliveryDays() {
            moment.relativeTimeThreshold('d', 100);
            return moment(this.dateRange.end).from(this.dateRange.start, true);
        },
        errorDate() {
            if (moment(this.dateRange.end).toDate() > this.maximumDate.toDate()) {
                return "Grand fou, une date au-delà d'un mois n’est pas possible !";
            }
            if (moment(this.dateRange.start).toDate() < this.today.toDate()) {
                console.error(moment(this.dateRange.start).toDate(), this.today, moment(this.dateRange.start).toDate() < this.today);
                return "Nom de Zeus Marty ! La date est dans le passé !";
            }
            if (!this.dateRange.end || moment(this.dateRange.end).toDate() < this.dateRange.start) {
                return "Choisissez une date de fin pour votre palette";
            }

            return false;
        },
        maximumDate() {
            var start = this.dateRange.start ? this.moment(this.dateRange.start) : this.today;
            return start.add(1, 'month');
        }
    },
    methods: {
        calendar(date) {
            return moment(new Date(date.year, date.month, date.date, 1, 0, 0));
        },
        saveDate() {
            if (!this.errorDate) {
                console.log("emit", this.dateRange);
                this.$emit('date', this.dateRange);
                this.next();
            }
        },
        next() {
            if (this.editing) {
                this.$router.push(this.backUrl);
            } else {
                this.$router.push('adresse')
            }
        },
        moment: function (p = null) {
            moment.locale('fr');
            if (p) {
                return moment(p);
            }
            return moment();
        }
    },
    mounted() {
        this.palette = this.$parent.palette;
        if (!this.palette || !this.palette.expirationDate) {
            this.$router.push('/palette/' + this.code + '/parametres');
        } else {
            this.dateRange.start = this.palette.startDate ? moment(this.palette.startDate, 'YYYY-MM-DD hh:mm') : null;
            this.dateRange.end = moment(this.palette.expirationDate, 'YYYY-MM-DD hh:mm').hours(1).minutes(0).seconds(0);
        }
    },
    created() {
        this.today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        console.log("today", this.today);
    }
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.date-selector-container {
  padding: 1rem 0;

  .label {
    margin-bottom: 10px;
    text-align: center;
  }
}

.date-str {
  font-size: 1rem;
  color: $gray;
  padding: 8px 0;
  text-align: center;

  &.error {
    color: $red;
    font-weight: 500;
  }
}

.illustration {
  text-align: center;

  img {
    width: 150px;
    max-width: 100%;
  }

  .handwriting {
    font-family: $font-handwriting;
    padding: 5px;
    display: block;
    color: $gray;
  }
}
</style>