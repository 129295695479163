<template>
    <div class="master">
        <About :show-top-bar="false"/>
        <div class="wrapper fixed-to-bottom">
            <router-link class="btn btn-l" to="palette">C'est parti !</router-link>
        </div>
    </div>
</template>

<script>
import About from "@/views/More/About";

export default {
    name: 'WelcomeAbout',
    components: {About},
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

</style>
