<template>
    <transition :name="transitionName">
        <router-view class="scrollable" v-on:set_user="saveUser"></router-view>
    </transition>
</template>

<script>
    import UserService from "../../services/UserService";

    export default {
        name: 'Profile',
        computed: {
            active_user() {
                return this.$store.getters.USER;
            },
        },
        methods: {
            saveUser(user_data) {
                return UserService.editUser(user_data).then((data) => {
                    if (data.success === true) {
                        this.$store.commit('USER_LOADED', user_data);
                    }
                });
            }
        },
        data() {
            return {
                transitionName: 'slide-left'
            }
        },
        beforeRouteUpdate(to, from, next) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
            next()
        },
    }
</script>
