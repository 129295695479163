<template>
    <div class="product">
        <div class="product__infos">
            <div class="product__image_container">
                <img :src="product.image" :alt="product.name" class="product__image"
                     :class="product.stock > 0 ? '' :'bw'"/>
            </div>
            <div class="product__body">
                <div class="primary_content">
                    <div class="product__tag tag light" v-if="product.details && product.details.highlight">
                        {{ product.details.highlight }}
                    </div>
                    <h3 class="product__title">
                        {{ product.name }}
                        <span v-if="product.details && product.details.alcohol > 0">/ {{
                                product.details.alcohol
                            }}°</span>
                    </h3>
                    <p class="product__subtitle" v-if="product.short_description">{{ product.short_description }}</p>
                    <div class="product__description" v-html="description"/>
                    <span class="tag invert see-more" v-if="product.long_description">&bullet;&bullet;&bullet;</span>
                    <div class="product__price" v-if="product.stock > 0">
                        <span v-if="old_price" class="old_price">{{ old_price }}</span>
                        <span class="price">{{ price }}</span>
                    </div>
                    <div class="product__icons" v-if="product.details">
                        <img :src="require('@/assets/img/rewards/bio-ab.jpg')" v-if="product.details.bio === true">
                        <img class="medal" v-for="reward in product.details.rewards" v-bind:key="reward.image"
                             :title="reward.title" :alt="reward.title"
                             :src="require('@/assets/img/rewards/' + reward.image)"/>
                    </div>
                </div>
                <div class="secondary_content">
                    <div class="product__actions" v-if="isLoaded && product.stock > 0">
                        <template v-if="in_cart > 0">
                            <a class="btn btn-m btn-remove-qty" v-on:click.stop="$emit('removeFromCart', product, 1)">
                                <i class="icon-minus"></i>
                            </a>
                            <input type="text" class="quantity-input input-number" readonly :value="in_cart"/>
                            <a class="btn btn-m btn-add-qty" v-on:click.stop="$emit('addToCart', product, 1)">
                                <i class="icon-plus"></i>
                            </a>
                        </template>
                        <a v-else class="btn btn-m btn-add-qty" v-on:click.stop="$emit('addToCart', product, 1)">
                            <i class="icon-plus"></i> <span>Ajouter</span>
                        </a>
                    </div>
                    <div class="product__unavailable" v-if="isLoaded && product.stock <= 0">
                        <div class="unavailable">Epuisé !</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdditionalShopItem',
    props: ['product'],
    computed: {
        price() {
            if (this.product.tva) {
                return (parseFloat(this.product.price) * (1 + (this.product.tva / 100))).toFixed(2) + '€';
            }
            return parseFloat(this.product.price).toFixed(2) + '€';
        },
        old_price() {
            if (this.product.old_price) {
                if (this.product.tva) {
                    return (parseFloat(this.product.old_price) * (1 + (this.product.tva / 100))).toFixed(2) + '€';
                }
                return parseFloat(this.product.old_price).toFixed(2) + '€';
            }
            return null;
        },
        description() {
            return this.product.description.replace(/\n/g, "<br>");
        },
        isLoaded() {
            return this.$parent.cart.loaded && this.$store.getters.ACTIVE_PALETTE !== null;
        },
        in_cart() {
            const item = this.$store.getters.CART.items.find(p => p.productId === this.product.id);
            if (item) {
                return item.quantity;
            }
            return 0;
        },
    },
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "./src/styles/vars";

.product {
  padding: math.div($padding-wrapper, 2) 0;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  .product__infos {
    display: flex;
    flex-direction: row;
    align-items: center;

    .image_content {
      flex: 0 80px;
    }

    .primary_content {
      flex: 1;
      padding-left: $padding-wrapper;
      padding-right: $padding-wrapper;

      .product__title {
        font-family: $font-body-alt;
        font-weight: 500;
        color: #000;
      }

      .product__subtitle {
        color: $dark;
        margin: 5px 0;
      }

      .product__description {
        font-size: .9em;
        color: $gray;
        line-height: 1.2;
      }

      .product__price {
        padding: 5px 0;
        color: #333;
        font-size: 1rem;

        .price {
          font-weight: 600;
        }

        .old_price {
          text-decoration: line-through;
          margin-right: 5px;

          + .price {
            font-size: 1.12em;
            color: $red;
          }
        }
      }
    }

    .secondary_content {
      flex: 0 128px;
      max-width: 40vw;
      position: relative;

      @media(max-width: $width-m) {
        flex: 0;
        padding-left: 24px;
      }

      img {
        max-width: 100%;
        border-radius: 4px;

        &.bw {
          filter: grayscale(0.8);
        }
      }
    }
  }

  .product__actions {
    padding: 10px 0;
    display: flex;
    max-width: 100%;
    justify-content: center;

    input {
      width: 36px;
      max-width: 36px;
      text-align: center;
      padding: 5px;
    }

    input + .btn,
    .btn + input {
      margin-left: 5px;
    }

    .btn-add-qty {
      i + span {
        margin-left: 6px;
      }
    }

    .quantity-input {
      flex: 0;
      outline: 0;
      background: transparent;
    }

    .btn-add-qty,
    .btn-remove-qty {
      background: none;
      border-radius: 1000px;
      border: 1px solid $gray;
      color: #222;
      background: #fff;
      flex: 0;

      > i {
        font-size: 11px;
        font-weight: bold;
      }
    }
  }

  .product__unavailable {
    text-align: center;
    position: absolute;
    top: 46%;
    left: 0;
    right: 0;

    .unavailable {
      background: $red;
      color: #fff;
      text-align: center;
      font-weight: 600;
      display: inline-block;
      padding: 4px 7px;
      line-height: 1;
    }
  }

  .product__icons {
    img {
      max-height: 32px;

      &.medal {
        max-height: 48px;
      }
    }
  }
}

.product__image {
  height: 80px;
  width: 80px;
  object-fit: contain;
  max-height: 50vh;
  max-width: 100%;
  border-radius: 8px;
}

.product__body {
  display: flex;
  flex: 1;
  align-items: center;

  @media(max-width: $width-m) {
    flex-direction: column;
    align-items: flex-start;
  }
}


@media(max-width: $width-m) {
  .product {
    .product__infos {
      align-items: stretch;
    }

    .product__image_container {
      margin-top: 5px;
    }
  }
}

</style>