<template>
    <div class="category-selector-container">
        <ul class="category-selector" ref="tab_container">
            <li v-for="category in categories" v-bind:key="'tab_' + category.id" :ref="'tab_' + category.id">
                <a v-on:click="setTab(category.id)"
                   v-bind:class="{active:currentTab === category.id}">
                    {{ category.title }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CategorySelector',
    props: ['categories', 'selected'],
    watch: {
        selected: function (current) {
            if (current !== null) {
                const tab = this.$refs['tab_' + current][0];
                const bounding = tab.getBoundingClientRect();

                let scrollH = 0;
                if (bounding.width < window.innerWidth) {
                    scrollH = tab.offsetLeft - ((window.innerWidth / 2) - (bounding.width / 2));
                }

                this.$refs.tab_container.scrollLeft = scrollH;
            }
        }
    },
    computed: {
        currentTab() {
            return this.selected ? this.selected : this.categories[0].id;
        }
    },
    methods: {
        setTab(id) {
            this.$emit('setTabAndScroll', parseInt(id));
        }
    },
}
</script>

<style lang="scss" scoped>

@import "src/styles/vars";

.category-selector-container {
  flex: 0;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(-100%);
  transition: all .2s ease-in-out;

  &.active {
    transform: translateY(0);
  }
}

.category-selector {
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    white-space: nowrap;
    display: inline-block;

    a {
      padding: 12px;
      display: block;
      color: $gray;
      position: relative;
      font-size: 1.1rem;
      cursor: pointer;

      &.active {
        font-weight: 500;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background: #000;
        }

        color: #000;
      }
    }
  }
}
</style>