<template>
    <router-link class="order-item" to="#">
        <div class="order-item__head">
            <div class="order-item__date">{{ orderDate }}</div>
        </div>
        <div class="order-item__content">
            <div class="order-item__details">
                <div v-for="item in order.items" :key="item.id">
                    x{{ item.quantity }} {{ item.productName }}
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import moment from "moment";

    export default {
        name: 'OrderListItemLight',
        props: ['order'],
        computed: {
            orderDate() {
                moment.locale('fr');
                return moment(this.order.creationDate).format('Do MMM YYYY');
            },
            orderTotal() {
                return this.order.montantTTC;
            },
            orderTva() {
                return this.order.montantTVA;
            },
            countCardBoards() {
                return 0;
            }
        },
        methods: {
            fetchCart() {
                return [];
            },
        },
    }
</script>

<style lang="scss" scoped>

    @use "sass:math";
    @import "./src/styles/vars";

    .order-item {
        border-bottom: 1px solid $line-color;
        display: flex;
        flex-direction: column;
        font-family: $font-body-alt;
        padding: math.div($padding-wrapper, 2) 0;

        .order-item__head {
            display: flex;
            flex-direction: row;
        }

        .order-item__date {
            flex: 1;
            font-size: 1.12rem;
            font-weight: 500;
            color: #000;
        }

        .order-item__details {
            font-size: 0.875rem;
            color: $gray;
        }

        .cart-total {
            padding: $padding-wrapper 0;
            font-size: 0.875rem;

            .cart-total__line {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 400;
                color: $gray;

                &.total {
                    font-weight: 500;
                    color: #000;
                }

                .value {
                    text-align: right;
                }
            }
        }
    }
</style>