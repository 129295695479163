<template>
    <div class="master scrollable">
        <div class="wrapper">
            <h1 class="title">Paiement finalisé</h1>
            <p class="subtitle">
                Vous avez chargé la palette !
            </p>
            <br>
            <div v-if="palette">
                <p v-if="palette.currentCardBoardCounter > 0">
                    Déjà un total de <span class="highlight"><span>{{ palette.currentCardBoardCounter }} cartons</span></span>
                    sur la palette <b>"{{ palette.name }}"</b>
                </p>
                <p v-html="this.$store.getters.ENV_DATA('order_end_success', 'Une confirmation de paiement vous a été envoyée par email.')"></p>
            </div>
            <div v-if="palette.mode === 'collective'">
                Vous avez contribué à votre collectif !
            </div>
            <div class="illustration">
                <img :src="require('@/assets/img/cheers.svg')"/>
            </div>
        </div>
        <div class="wrapper fixed-to-bottom">
            <p v-if="palette && canShare">Vous avez envie d'inviter des proches sur la Palette ? Ils sont les bienvenus,
                c'est ici !</p>
            <a class="btn invert" v-if="palette && canShare" v-on:click="shareLink">Inviter des amis</a>
            <router-link to="/" class="btn btn-l bg">Retour au tableau de bord</router-link>
        </div>
    </div>
</template>

<script>
import Api from "@/services/Api";
import {palette} from "@/mixins/palette";
import {share} from "@/mixins/share";

export default {
    name: 'Success',
    mixins: [palette, share],
    data() {
        return {
            session: null,
        }
    },
    computed: {
        cardboardsLeft() {
            return this.palette.cardboardCapacity - this.palette.currentCardBoardCounter;
        }
    },
    methods: {
        fetchSession() {
            const sessionId = this.$route.query.session_id;
            if (sessionId == null) {
                this.$router.push('/');
                return Promise.reject('Invalid session ID');
            }
            return Api.get('/checkout-session?sessionId=' + sessionId).then((session) => {
                this.session = session;
            });
        },
        resetInternalSession() {
            console.log("reset session");
            return this.$store.dispatch('RESET_SESSION_UUID').then(() => {
                return this.$store.dispatch('me', false);
            });
        },
        shareLink() {
            this.browserShareLink(this.palette.code);
        },
    },
    created() {
        this.getCurrentPalette();
        this.fetchSession().then(() => {
            this.resetInternalSession();
        });
    }
}
</script>

<style lang="scss" scoped>
.illustration {
  text-align: center;
  display: block;
  margin-top: 10px;

  img {
    max-width: 100%;
    max-height: 22vh;
  }
}
</style>