<template>
    <div>
        <div class="profile-header">
            <div class="wrapper">
                <div class="profile-header__image" @click="avatar_selection_mode = true">
                    <img :src="userImage"/>
                </div>
                <div class="profile-header__content">
                    <div class="title">{{ user.publicName }}</div>
                    <div class="subtitle">Membre depuis {{ member_since }}</div>
                    <!--
					<span v-if="verified">Compte vérifié <i class="ic icon-check"></i></span>
					<span v-else class="red">Compte non-confirmé</span>
					-->
                </div>
            </div>
        </div>
        <AvatarSelector v-bind:user="user"
                        v-bind:modalOpen="avatar_selection_mode"
                        v-on:modalClosed="avatar_selection_mode=false"
                        v-on:cancel="avatar_selection_mode=false"
                        v-on:uploadEnd="$store.dispatch('LIST_MEMBERS')"/>
        <ButtonListItem to="/profil/informations" v-if="!user.mobile"
                        class="profile-alert"
                        title="Ajoutez votre numéro de téléphone"
                        icon="icon-security"/>

        <div class="button-list">
            <ButtonListItem to="/profil/informations"
                            :title="'Informations personnelles'"
                            :icon="'icon-user'"/>

            <ButtonListItem to="/profil/adresses" v-if="this.$store.getters.isGodfather"
                            :title="'Mes adresses de livraison'"
                            :icon="'icon-location'"/>

            <ButtonListItem to="/profil/commandes"
                            :title="'Mes commandes'" :icon="'icon-supply'"/>

            <ButtonListItem to="/contacts" icon="icon-people" v-if="this.$store.getters.isGodfather"
                            title="Mes contacts"/>

            <ButtonListItem :title="'Palette active'" :icon="'icon-pallet'"
                            v-on:click.native="$refs.modalPalettelist.showPaletteList()"
                            :subtitle="palette ? palette.name : 'Aucune palette'"/>

            <ButtonListItem :title="'Déconnexion'" :class="'red'" v-on:click.native="logout()"/>

            <ModalPaletteList ref="modalPalettelist"/>
        </div>
    </div>
</template>

<script>
import ButtonListItem from "../../components/List/ButtonListItem";
import moment from "moment";
import PaletteService from "../../services/PaletteService";
import {palette} from "@/mixins/palette";
import {user} from "@/mixins/user";
import AvatarSelector from "@/views/Profile/AvatarSelectorModal";
import ModalPaletteList from "@/components/List/ModalPaletteList";

export default {
    name: 'ProfileBoard',
    data() {
        return {
            avatar_selection_mode: false,
        }
    },
    mixins: [palette, user],
    components: {
        ModalPaletteList,
        AvatarSelector,
        ButtonListItem
    },
    computed: {
        member_since() {
            moment.locale('fr');
            return moment(this.user.creationDate).utc(true).fromNow(true);
        },
        verified() {
            return this.user.verified > 0;
        },
    },
    methods: {
        showPaletteList() {
            this.modalPaletteList = true;
            this.palettesListLoaded = false;
            PaletteService.getPaletteList().then((data) => {
                this.palettesAvailable = data;
                this.palettesListLoaded = true;
            });
        },
    },
    mounted() {
        this.getCurrentPalette();
    }
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.profile-header {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.profile-header > .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    padding-top: 0;
  }

  .subtitle {
    font-size: 1rem;
    color: $gray;
  }

  .profile-header__image {
    margin-right: 12px;
    width: 60px;
    height: 60px;
    border-radius: 10000px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

</style>
