import axios from 'axios';
import store from '../Store'

export default {
    client() {
        return axios.create({
            baseURL: process.env.VUE_APP_SERVER_HOST,
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Auth-Token': store.getters.AUTH_TOKEN,
                'Session-Id': store.getters.isSessionReady ? store.getters.SESSION_UUID : ''
            }
        })
    },
    handleError(error) {
        if (!error.response) {
            throw new Error("Erreur lors de la connexion au serveur");
        }
        if (error.response.status === 401 || error.response.status === 403) {
            throw new Error("Vous n'êtes pas autorisés à accéder à cette page");
        }

        if (error.response.data && error.response.data.errorMessage) {
            throw new Error(error.response.data.errorMessage);
        } else {
            throw new Error(error.response.statusText);
        }
    },
    request(options) {
        return this.client().request(options).then(response => response.data)
            .catch(error => {
                this.handleError(error);
            });
    },
    get(uri) {
        return this.request({
            method: 'get',
            url: uri,
        });
    },
    post(uri, data) {
        return this.request({
            method: 'post',
            url: uri,
            data: data
        });
    },
    put(uri, data) {
        return this.request({
            method: 'put',
            url: uri,
            data: data,
        })
    },
    delete(uri, data) {
        return this.request({
            method: 'delete',
            url: uri,
            data: data,
        })
    }
}