<template>
    <div class="master">
        <TopBar :back="editing ? backUrl : 'back'" title="Nom de la palette"/>
        <div class="wrapper form">
            <div class="field">
                <div class="label">Donnez un nom à votre palette</div>
                <input class="input" :value="palette.name" ref="name">
            </div>
        </div>
        <div class="wrapper fixed-to-bottom">
            <button class="btn btn-l bg" :class="editing ? 'invert' : ''" @click="saveName">Confirmer le nom</button>
        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";

export default {
    name: 'PalletManagerName',
    props: ['code', 'editing'],
    components: {
        TopBar,
    },
    data() {
        return {
            palette: null,
            backUrl: '/palette/' + this.code + '/parametres',
        }
    },
    methods: {
        saveName() {
            this.$emit('name', this.$refs.name.value);
            this.next();
        },
        next() {
            if (this.editing) {
                this.$router.push(this.backUrl);
            } else {
                this.$router.push('date')
            }
        }
    },
    created() {
        this.palette = this.$parent.palette;
        if (this.palette == null) {
            this.$router.push('/palette/' + this.code + '/parametres');
        }
    }
}
</script>