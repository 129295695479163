<template>
    <div class="master">
        <div class="wrapper scrollable">
            <h1 class="title">Bienvenue {{ this.$store.getters.USER.firstName }} !</h1>
            <p class="subtitle">Vous êtes un <span class="highlight"><span>BOSS LaPalette</span></span></p>
            <div class="text">
                <ol>
                    <li>
                        1. Commencez une nouvelle palette
                        <span class="handwriting">vous pouvez même lui donner un petit nom</span>
                    </li>
                    <li>
                        2. <span class="color">Invitez</span> vos... invités :) <span class="handwriting">Et faites-les payer !</span>
                    </li>
                    <li>
                        3. Déclenchez la <span class="color">livraison</span> quand vous voulez, à partir de 20 cartons</li>
                    <li>
                        4. Réceptionnez, <span class="color">partagez</span>, trinquez !
                    </li>
                </ol>
            </div>
            <div class="illustration">
                <img :src="require('@/assets/img/thumbs-up.svg')"/>
            </div>
        </div>
        <div class="wrapper fixed-to-bottom">
            <router-link class="btn btn-l" to="palette">C'est parti !</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Welcome',
    }
</script>

<style lang="scss" scoped>

    @import "../../styles/vars";

    .fixed-to-bottom {
        padding-top: 0;
    }

    .text {
        font-size: 1rem;
        padding-bottom: 1rem;
        padding-top: 1rem;

        .handwriting {
            display: block;
            padding-left: 10px;
            font-family: $font-handwriting;
            color: $gray;
            font-size: 1.2rem;
        }

        .color {
            font-weight: 600;
        }

        li {
            padding: 2% 0;
        }
    }

    .illustration {
        text-align: center;
        display: block;

        img {
            max-width: 100%;
            max-height: 22vh;
        }
    }
</style>
