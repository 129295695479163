const notifStack = [];

export class Ui {

    /**
     *
     * @param message
     * @param status
     */
    static notif(message, status) {
        let alertItem = document.createElement('div');
        alertItem.className = "notification " + (status ? status : "");
        alertItem.innerHTML = message;

        document.body.appendChild(alertItem);

        var pos = notifStack.length;
        notifStack.push(alertItem);

        setTimeout(function () {
            alertItem.classList.add('active');
            if (pos > 0) {
                alertItem.style.bottom = 15 + Math.round((5 + 48) * pos) + 'px';
            }
            setTimeout(function () {
                alertItem.classList.remove('active');
                notifStack.splice(0, 1);
                notifStack.forEach((el, i) => {
                    el.style.bottom = 15 + Math.round((5 + 48) * i) + 'px';
                });
                setTimeout(function () {
                    alertItem.remove();
                }, 200);
            }, 5000);
        }, 200);
    }
}

