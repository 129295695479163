<template>
    <div class="app-content">
        <div class="app-body">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoBottomNav'
}
</script>