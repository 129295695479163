<template>
    <div class="step-selector">
        <input v-bind:id="'rd-step' + step" class="step-selector" name="current_step" type="radio"
               v-bind:checked="checked" v-on:change="$emit('change')"/>
        <label v-bind:for="'rd-step' + step"></label>
    </div>
</template>

<script>
export default {
    name: 'StepSelector',
    props: {
        checked: Boolean,
        step: Number,
    },
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

input[type="radio"] {
  display: none;

  + label {
    display: block;
    width: 12px;
    height: 12px;
    background: darken($primary-text, 10);
    margin: 0;
    border-radius: 100px;
    transition: width .2s;
    cursor: pointer;
    min-height: auto;
    padding: 0;
    &:hover {
      background: $primary-text;
    }
  }

  &:checked + label {
    width: 24px;
    background: $primary;
  }
}

</style>