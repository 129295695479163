<template>
    <div class="form master">
        <TopBar back="/profil" title="Informations personnelles">
            <div slot="action" class="action">
                <a @click="editing = true">
                    <i class="ic icon-pen"></i>
                </a>
            </div>
        </TopBar>
        <div class="wrapper profile-header">
            <label class="profile-header__image" @click="avatar_selection_mode = true">
                <img :src="userImage" :class="uploading ? 'loading' : ''"/>
                <span class="camera">
                    <i class="icon-camera"></i>
                </span>
            </label>
            <AvatarSelector v-bind:user="user_edition"
                            v-bind:modalOpen="avatar_selection_mode"
                            v-on:modalClosed="avatar_selection_mode=false"
                            v-on:uploadEnd="$store.dispatch('LIST_MEMBERS')"
                            v-on:cancel="avatar_selection_mode=false"/>
        </div>
        <div class="wrapper">
            <div class="field">
                <label class="label">Nom complet</label>
                <div class="value">
                    {{ fullName }}
                </div>
            </div>
            <div class="field">
                <label class="label">Adresse e-mail</label>
                <div class="value" v-if="this.$parent.active_user.email">
                    {{ email }}
                </div>
                <div class="value" v-else>
                    <span class="disabled">Non renseigné</span>
                </div>
            </div>
            <div class="field">
                <label class="label">Numéro de téléphone</label>
                <div class="value" v-if="this.$parent.active_user.mobile">
                    {{ phoneNumber }}
                </div>
                <div class="value" v-else>
                    <span class="disabled">Non renseigné</span>
                </div>
            </div>
            <div class="field">
                <label class="label">Mot de passe</label>
                <div class="value">
                    <span class="disabled">*******</span>
                </div>
            </div>
        </div>

        <Modal v-if="editing">
            <form action="#" method="post" slot="body" class="form">
                <div class="field">
                    <label class="label">Prénom</label>
                    <input type="text" class="input" name="firstName" autocomplete="given-name"
                           v-model="user_edition.firstName">
                </div>
                <div class="field">
                    <label class="label">Nom</label>
                    <input type="text" class="input" name="lastName" autocomplete="family-name"
                           v-model="user_edition.lastName">
                </div>
                <div class="field">
                    <label class="label">Email</label>
                    <input type="email" class="input" name="email" autocomplete="email" v-model="user_edition.email">
                </div>
                <div class="field">
                    <label class="label">Téléphone</label>
                    <input type="tel" class="input" name="mobile" v-model="user_edition.mobile" autocomplete="tel-local"
                           v-cleave="{phone: true, phoneRegionCode: 'FR'}">
                </div>
                <div class="field">
                    <label class="label">Mot de passe</label>
                    <input type="password" autocomplete="current-password" style="display: none"/>
                    <input type="password" class="input" name="password" autocomplete="new-password"
                           placeholder="Laissez vide pour ne pas modifier"
                           v-model="user_edition.password">
                </div>
            </form>
            <div slot="footer" class="double-buttons-container">
                <div>
                    <a @click="cancel" class="btn gr">Annuler</a>
                </div>
                <div>
                    <a @click="saveUser" class="btn bg" :class="loading ? 'loading' : ''">Enregistrer</a>
                </div>
            </div>
        </Modal>

    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import Modal from "../../components/UX/Modal";
import {Ui} from "@/class/Ui";
import UserService from "../../services/UserService";
import AvatarSelector from "@/views/Profile/AvatarSelectorModal";
import {user} from "@/mixins/user";
import Cleave from 'cleave.js';

export default {
    name: 'ProfileInfo',
    mixins: [user],
    components: {
        AvatarSelector,
        Modal,
        TopBar,
    },
    data() {
        return {
            editing: false,
            user_edition: null,
            loading: false,
            uploading: false,
            avatar_selection_mode: false
        }
    },
    methods: {
        formattedNumber(phone) {
            const phoneNumber = parsePhoneNumberFromString(phone, 'FR');
            if (phoneNumber) {
                return phoneNumber.formatNational();
            }
            return phone;
        },
        cancel() {
            this.editing = false;
            this.user_edition = this.getUserCopy();
        },
        saveUser() {
            if (!this.loading) {
                this.loading = true;
                return UserService.editUser(this.user_edition).then((data) => {
                    if (data.success === true) {
                        this.$store.dispatch('me');
                        this.editing = false;
                    }
                }).catch(error => {
                    Ui.notif(error, "error");
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        getUserCopy() {
            let copy = JSON.parse(JSON.stringify(this.$parent.active_user));
            copy.password = '';
            return copy;
        },
        reloadUser() {
            console.debug("profile info reload");
            this.$store.dispatch('me', false);
        }
    },
    computed: {
        phoneNumber() {
            return this.formattedNumber(this.$parent.active_user.mobile);
        },
        email() {
            return this.$parent.active_user.email;
        },
    },
    created() {
        this.user_edition = this.getUserCopy();
        require('cleave.js/dist/addons/cleave-phone.fr');
    },
    directives: {
        cleave: {
            inserted: (el, binding) => {
                el.cleave = new Cleave(el, binding.value || {})
            },
            update: (el) => {
                const event = new Event('input', {bubbles: true});
                setTimeout(function () {
                    el.value = el.cleave.properties.result
                    el.dispatchEvent(event)
                }, 100);
            }
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 0 120px;

  .profile-header__image {
    width: 110px;
    height: 110px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10000px;
    }

    .camera {
      background: transparentize($dark, 0.25);
      color: $primary-text;
      padding: 0px;
      border-radius: 10000px;
      height: 2em;
      width: 2em;
      line-height: 2em;
      text-align: center;
      display: block;
      position: absolute;
      top: calc(50% - 2em / 2);
      left: 0;
      right: 0;
      margin: auto;

      i {
        line-height: 0;
        font-size: 14px;
        vertical-align: -1px;
      }
    }
  }
}

</style>