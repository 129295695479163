<template>
    <div class="bottom-nav">
        <router-link to="/palette" class="nav-item" v-bind:class="{active:this.current === 'palette'}">
            <span class="nav-item__icon icon-pallet"></span>
            <span class="nav-item__title">Ma Palette</span>
        </router-link>
        <router-link to="/catalogue" class="nav-item" v-bind:class="{active:this.current === 'catalogue'}">
            <span class="nav-item__icon icon-beer"></span>
            <span class="nav-item__title">Catalogue</span>
        </router-link>
        <router-link to="/profil" class="nav-item" v-bind:class="{active:this.current === 'profile'}">
            <span class="nav-item__icon icon-boy"></span>
            <span class="nav-item__title">Profil</span>
        </router-link>
        <router-link to="/plus" class="nav-item" v-bind:class="{active:this.current === 'plus'}">
            <span class="nav-item__icon icon-menu-2"></span>
            <span class="nav-item__title">Plus</span>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'BottomNav',
        props: {
            current: String,
        },
    }
</script>

<style lang="scss" scoped>

    @import "../../styles/vars";

    .bottom-nav {
        background: #fff;
        box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.09);
        color: #333;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 15px;
        z-index: 80;

        .nav-item {
            font-weight: bold;
            border: 1px solid #fff;
            flex: 1;

            &.active {
                color: $primary;

                svg {
                    fill: $primary;
                }
            }

            span {
                display: block;
            }

            .nav-item__icon {
                font-size: 24px;
                line-height: 1;
                font-weight: normal;
            }

            .nav-item__title {
                font-size: 10px;
                text-transform: uppercase;
                line-height: 1;
                padding-top: 5px;
            }
        }
    }
</style>
