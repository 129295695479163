<template>
    <div class="master scrollable">
        <TopBar :back="editing ? backUrl : 'back'" title="Cagnotte"/>
        <div class="wrapper flex">
            <div class="text">
                <div class="title">Contribuez à la vie de votre collectif !</div>
                <p>
                    Pour chaque carton acheté, choisissez la somme que nous vous reversons.
                </p>
            </div>
            <div class="cardboard-collect-container">
                <div class="cardboard-collect-item">
                    <label>Votre prix d'achat <small>par btl. 33cL</small></label>
                    <div class="collect-price">
                        2.00€
                    </div>
                    <div class="tips">Soit 24€ / carton de 12 bouteilles</div>
                </div>

                <div class="symbol">+</div>

                <div class="cardboard-collect-item">
                    <label class="b">
                        <span :class="colorClass">Au bénéfice de votre collectif <small>par btl. 33cL</small></span>
                    </label>
                    <div class="collect-price editable" :class="colorClass">
                        <input ref="input-fees" type="text" v-model="bottleCollectFeesInput" placeholder="0"/>
                    </div>
                    <div class="tips">Soit <b :class="colorClass">{{ getCardboardFees }}€</b> reversés par carton</div>
                </div>

                <div class="symbol">=</div>

                <div class="cardboard-collect-item">
                    <label>Prix pour vos invités <small>par btl. 33cL</small></label>
                    <div class="collect-price">
                        <b>{{ bottlePriceWithFees }}€</b> <small>TTC</small>
                    </div>
                    <div class="tips">Soit <b>{{ cardboardPriceWithFees }}€ / carton</b> de 12 bouteilles</div>
                </div>

            </div>
        </div>

        <div class="wrapper fixed-to-bottom">
            <p class="error b center" v-if="errorValueCollect">
                Le montant sélectionné doit être compris entre 1 centime et 1 euro maximum par bouteille.
            </p>
            <p class="text mt mb" v-if="!editing">
                Des informations légales concernant votre entreprise ou votre association vous seront demandées afin
                de pouvoir recevoir les commissions récoltées sur chaque vente. Paiement et transfert sécurisé par Stripe.
            </p>
            <button @click="saveCagnotte" class="btn btn-l bg" :class="errorValueCollect ? 'disabled':''">
                Valider la somme récoltée
            </button>
        </div>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";

export default {
    name: 'PalletManagerCollectFee',
    props: ['code', 'editing'],
    components: {
        TopBar,
    },
    data() {
        return {
            palette: null,
            cardboardCollectFees: 0,
            bottleCollectFees: 0,
            bottleCollectFeesInput: 0,
            backUrl: '/palette/' + this.code + '/parametres',
            defaultBottlePrice: 2,
            defaultCardboardBottleNumber: 12,
            errorValueCollect: false,
        }
    },
    watch: {
        bottleCollectFeesInput: function (v) {
            if (v) {
                v = v.toString().replace(',', '.');
            }
            if (!v || isNaN(Number(v))) {
                this.bottleCollectFees = 0;
            } else {
                this.bottleCollectFees = parseFloat(v);
            }
        },
        bottleCollectFees: function (v) {
            this.errorValueCollect = v > 1.00 || v < 0.00;
        }
    },
    methods: {
        saveCagnotte() {
            this.$emit('setCollectiveFees', this.$_.round(this.getCardboardFees, 2));
            this.$nextTick(() => {
                if (this.palette.id > 0) {
                    this.back();
                } else {
                    //this.$router.push('stripe');
                    this.$router.push('nom');
                }
            });
        },
        back() {
            this.$router.push(this.backUrl);
        }
    },
    mounted() {
        if (this.palette.mode !== "collective") {
            this.back();
        }
        this.cardboardCollectFees = this.palette.cardboardCollectiveFees;
        //this.bottleCollectFeesInput = this.palette.cardboardCollectFees;
        if (this.cardboardCollectFees > 0) {
            this.bottleCollectFeesInput = this.$_.round(this.cardboardCollectFees / this.defaultCardboardBottleNumber, 2);
            this.bottleCollectFees = this.bottleCollectFeesInput;
        }

        this.$refs['input-fees'].focus();
    },
    computed: {
        getCardboardFees() {
            return this.$_.round(this.defaultCardboardBottleNumber * parseFloat(this.bottleCollectFees), 2);
        },
        bottlePriceWithFees() {
            return this.$_.round(this.defaultBottlePrice + parseFloat(this.bottleCollectFees), 2);
        },
        cardboardPriceWithFees() {
            return this.$_.round(this.bottlePriceWithFees * this.defaultCardboardBottleNumber, 2);
        },
        colorClass() {
            if (this.bottleCollectFees > 0.71) {
                return 'red';
            } else if (this.bottleCollectFees > 0.51) {
                return 'orange';
            } else if (this.bottleCollectFees > 0) {
                return 'green';
            }
            return 'gray';
        }
    },
    created() {
        this.palette = this.$parent.palette;
        if (!this.palette) {
            this.back();
        }
    },
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.cardboard-collect-container {
  text-align: center;
}

.cardboard-collect-item {
  margin: 10px 0;
}

.collect-price {
  border: 3px solid $gray-light;
  border-radius: 3px;
  padding: 10px 5px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  font-size: 1.4rem;
  text-align: center;

  &.editable {
    border-color: #000;

    &.green {
      border-color: $green;
      input {
        color: darken($green, 5);
      }
    }

    &.red {
      border-color: $red;
      input {
        color: darken($red, 5);
      }
    }

    &.orange {
      border-color: $orange;
      input {
        color: darken($orange, 5);
      }
    }
  }

  input {
    text-align: center;
    max-width: 100%;
    outline: 0;
    border: 0;
    background: none;
    font-weight: bold;
  }
}

.symbol {
  line-height: 1;
  font-size: 48px;
  font-weight: bold;
}

.tips {
  color: $font-light;
  font-size: 0.875rem;
}

</style>