export const steps = {
    data() {
        return {
            steps: [
                {count: 40, description: "En-dessous ce n’est pas une Palette digne de ce nom !", gifts: 1, active: false,},
                {count: 60, description: "Bien joué ! \n3 cartons en plus !", gifts: 2, active: false,},
                {count: 80, description: null, gifts: 3, active: false},
                {count: 100, description: "Ca commence à être du sérieux !!\n5 cartons offerts", gifts: 4, active: false,},
                {count: 120, description: "Bingo !!! 8 cartons offerts à déguster, offrir, ou ce que vous voulez", gifts: 5, active: false,},
            ]
        }
    }
};