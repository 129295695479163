<template>
    <div class="step" ref="step">
        <div class="text-intro">
            <div class="logo" v-if="logo">
                <img :src="logo" :title="title"/>
            </div>
            <div class="title" v-if="title.length">{{ title }}</div>
            <div v-html="intro"/>
        </div>
        <div class="onboarding-illu" v-if="(user && user.image) || image">
            <div v-if="user && user.image" class="avatar">
                <img :src="user.image"/>
            </div>
            <img alt="La Palette" :src="image" v-if="image"/>
        </div>
        <slot name="body" class="step-body"></slot>
    </div>
</template>

<script>
export default {
    name: 'Step',
    props: {
        title: String,
        image: String,
        intro: String,
        user: Object,
        logo: String,
    }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/vars";

.title {
  font-size: 2.1rem;
  line-height: 1.2;
  font-weight: 500;;
  font-family: $font-heading-serif;
  padding: 2rem 0;
  color: $dark-colored-title;
  letter-spacing: 0.01em;

  @media (min-width: $width-l) {
    font-size: 3.2rem;
    line-height: 1;
  }
}

.logo {
  display: block;
  padding: 0;
  margin-bottom: 1rem;

  + .title {
    padding-top: 1rem;
  }

  img {
    width: 100%;
    max-width: 140px;
  }
}

.onboarding-illu {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
  align-self: stretch;

  .avatar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    img {
      border-radius: 1000px;
      box-shadow: 0 1px 14px rgba(0, 0, 0, 0.12);
      height: 100px;
      width: 100px;
    }
  }

  img {
    //height: 200px;
    max-width: 100%;
    max-height: 30vh;
    border-radius: 12px;
  }
}

.text-intro {
  text-align: center;
  margin: 0.5rem auto;
  font-size: 1.1rem;
  max-width: 300px;
  width: 100%;
  flex: 1;
  font-weight: 500;
  color: $gray-colored-title;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $width-l) {
    max-width: 480px;
    font-size: 1.3rem;
  }

  b, strong, .bold {
    font-weight: 600 !important;
  }
}

.step-body {
  flex: 1;
  padding: 0.5rem 0 2rem;
}

</style>
