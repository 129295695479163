<template>
    <div class="wrapper">
        <div class="title">Inviter des amis</div>
    </div>
</template>

<script>
    export default {
        name: 'Invite'
    }
</script>
