<template>
    <div id="pallet_model" ref="pallet_model" :class="classname"></div>
</template>

<script>
import {PalletModelThreeJS} from "@/class/PalletModelThreeJS";

export default {
    name: 'PalletModel3D',
    props: {
        cartons: {
            type: Number,
            required: true
        },
        rotate: {
            type: Boolean,
            default: true
        },
        classname: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            loadingTimeout: null,
            containerWidth: 0,
            threejsModel: null
        }
    },
    methods: {
        loadPalletModel() {
            this.containerWidth = document.getElementById('pallet_model').offsetWidth;
            if (this.threejsModel !== null) {
                document.getElementById('pallet_model').innerHTML = '';
                this.threejsModel.clearModel();
                console.log("clear 3d model");
            }
            console.log("load 3d model");
            this.threejsModel = new PalletModelThreeJS(document.getElementById('pallet_model'), this.cartons, {
                box_image_path: this.$store.getters.ENV_DATA('model_box_image_path', 'models/images/'),
                auto_rotate: this.rotate
            });
        },
        handleResize() {
            if (document.getElementById('pallet_model') && this.containerWidth !== document.getElementById('pallet_model').offsetWidth) {
                if (this.loadingTimeout) {
                    clearTimeout(this.loadingTimeout);
                }
                this.loadingTimeout = setTimeout(() => {
                    this.loadPalletModel();
                }, 300);
            }
        }
    },
    mounted() {
        if (!this.threejsModel) {
            this.loadingTimeout = setTimeout(() => {
                this.loadPalletModel();
            }, 300);
        }

        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        if (this.threejsModel) {
            this.threejsModel.clearModel();
            window.removeEventListener('resize', this.handleResize);
        }
    }
}
</script>

<style lang="scss" scoped>

#pallet_model {
  height: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  &.closed {
    opacity: 0.5;
  }
}

</style>