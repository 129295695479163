<template>
    <Modal v-if="show">
        <div slot="header">
            <div class="title">Pas de panique ! </div>
            <p>Saisissez votre adresse email pour recevoir un lien de récupération de mot de passe.</p>
        </div>
        <form class="form" method="post" slot="body" v-on:submit.prevent="sendRecoverMail">
            <div class="field">
                <input type="email" class="input" v-model="this.inputEmail" placeholder="Votre adresse email"/>
            </div>
            <div class="field">
                <button type="submit" class="btn btn-l bg" :class="loading ? 'loading' : ''">Envoyer un lien</button>
            </div>
        </form>
        <div slot="footer" class="center">
            <a class="_link" @click="closeModal">Annuler</a>
        </div>
    </Modal>
</template>

<script>
import Modal from "../../components/UX/Modal";
import Api from "../../services/Api";
import {Ui} from "@/class/Ui";

export default {
    name: 'RecoverPasswordModal',
    components: {Modal},
    props: ['email', 'show'],
    data() {
        return {
            loading: false,
            inputEmail: this.email
        }
    },
    methods: {
        sendRecoverMail() {
            if (!this.loading) {
                this.loading = true;
                Api.post('/user/lost-password', {
                    login: this.email,
                }).then((data) => {
                    if (this.$gtag) {
                        this.$gtag.event('password-recover');
                    }
                    if (data.success === true) {
                        Ui.notif("Vous allez recevoir un email contenant un lien de récupération", "success");
                    }
                    this.closeModal();
                    return true;
                }).catch((error) => {
                    Ui.notif(error, "error");
                    return false;
                }).finally(() => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 1500);
                })
            }
        },
        closeModal() {
            this.$parent.recoverPassword = false;
            this.loading = false;
        }
    }
}
</script>