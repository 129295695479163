import Api from './Api'

export default {
    getCurrentUser() {
        return Api.get('/me');
    },
    getUser(id = null) {
        if (id === null) {
            return Api.get('/me');
        } else {
            return Api.get('/user/' + id)
        }
    },
    signin(email, password) {
        return Api.post('/user/login', {
            email: email,
            password: password,
        });
    },
    signup(payload) {
        return Api.post('/user/register', payload);
    },
    editUser(data) {
        console.log("edit user", data);
        return Api.put('/user/profile', data);
    },
    verifyAccount(params) {
        return Api.post('/account/validate', params);
    }
}