<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" :class="{loading:loading}">
                <div class="modal-container">
                    <div v-if="showCloseButton" class="modal-close" @click="$emit('close')">
                        <i class="icon-cancel"></i>
                    </div>
                    <div slot="content">
                        <div class="image-container">
                            <img :src="image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ImagePreview',
    props: {
        showCloseButton: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        image: {
            type: String,
            default: null
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";
@import "../../styles/base/all";

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  font-size: 18px;
}

.modal-mask {
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 0 1rem;
  max-height: 100%;
  position: relative;
  z-index: 1500;
}

.modal-container {
  width: auto;
  max-width: 100%;
  margin: 0px auto;
  background-color: #000;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: $font-body;
  max-height: 94%;
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  vertical-align: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .image-container, .image-container img {
    max-width: 100%;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-wrapper.loading {
  .modal-body {
    transition: opacity .2s;
    opacity: 0.25;
  }

  @extend .spinLoader;
}

</style>