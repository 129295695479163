<template>
    <div class="master scrollable about" v-if="!loading" :class="isMarkdown?'markdown':''">
        <TopBar back="/plus" title="A propos" v-if="showTopBar"/>
        <div class="wrapper" v-html="about_html"/>
    </div>
</template>

<script>
import TopBar from "../../components/UX/TopBar";
import axios from 'axios';
import MarkdownIt from "markdown-it"

export default {
    props: {
        showTopBar: {
            type: Boolean,
            default: true
        }
    },
    name: 'About',
    data() {
        return {
            about: '',
            loading: true,
            isMarkdown: false,
            isHTML: false,
        }
    },
    components: {TopBar},
    computed: {
        about_html() {
            return this.about;
        }
    },
    methods: {
        loadAboutFile() {
            const aboutPage = this.$store.getters.ENV_DATA('about-page', 'about-bos.html');

            if (aboutPage.endsWith(".html") || aboutPage.endsWith(".md")) {
                axios({
                    method: "get",
                    url: "../../static/" + aboutPage
                }).then(result => {
                    if (aboutPage.endsWith(".md")) {
                        this.about = this.markedCgv(result.data);
                        this.isMarkdown = true;
                    } else {
                        this.about = result.data;
                        this.isHTML = true;
                    }
                    this.loading = false;
                }).catch(error => {
                    console.error("error getting file " + aboutPage);
                });
            } else {
                this.about = aboutPage;
                this.loading = false;
            }
        },
        markedCgv: function (value) {
            var md = new MarkdownIt({
                linkify: true,
            })
            return md.render(value);
        }
    },
    mounted() {
        this.loadAboutFile();
    }
}
</script>

<style lang="scss">

@import "../../styles/vars";

.about {
  img {
    max-width: 100%;
    border-radius: 12px;
  }

  .illu-on-left, .illu-on-right {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;

    .illu {
      flex-basis: 33%;
      max-width: 180px;
    }

    .text {
      flex-basis: 66%;
    }
  }

  .illu-on-left .text {
    padding-left: 1rem;
  }

  .illu-on-right .text {
    padding-right: 1rem;
  }

  &.markdown {
    img {
      display: block;
      margin: auto;
    }

    h1 {
      text-align: center;

      + h2 {
        margin-top: -5px;
        text-align: center;
      }
    }

    h1, h2 {
      margin: 10px 0;
    }
  }

  .text {
    font-size: 1.1rem;
    color: $font-light;
  }

  .alt {
    margin-top: 1rem;
    font-family: $font-heading;
  }
}

</style>