<template>
    <div class="master scrollable">
        <div class="wrapper">
            <form @submit.prevent="subscribeToPallet" ref="form">
                <router-view v-on:setCode="setCode"
                             v-on:subscribe="subscribeToPallet"
                             :user="user"
                             :loading="loading"/>
            </form>
        </div>
    </div>
</template>

<script>
import {Ui} from "@/class/Ui";
import UserService from "../../../services/UserService";
import PaletteService from "../../../services/PaletteService";

export default {
    name: 'Register',
    data() {
        return {
            user: {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                password: null,
                code: this.$route.query.code,
            },
            ready: false,
            loading: false,
            referral: null,
        }
    },
    computed: {
        isReady() {
            return this.user.code && this.user.password && (this.user.code !== "start" || this.user.phone);
        }
    },
    methods: {
        async subscribeToPallet() {
            if (this.isReady && !this.loading) {
                this.loading = true;
                UserService.signup({
                    login: this.user.email || this.user.phone,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    password: this.user.password,
                    contact: this.user.phone,
                    invitedBy: parseInt(this.referral),
                    code: this.user.code
                }).then((resp) => {
                    if (this.$gtag) {
                        this.$gtag.event('sign_up');
                    }
                    // autologin
                    return this.$store.dispatch('AUTH_REQUEST', {
                        email: resp.email,
                        password: this.user.password
                    }).then(() => {
                        this.$store.dispatch('me', false);
                    });
                }).then(() => {
                    if (this.user.code === "start") {
                        // Création d'une nouvelle palette.
                        return this.$router.push('/palette/start/parametres');
                    } else {
                        // on rejoint la palette après la création du compte
                        return PaletteService.subscribe(this.user.code)
                            .then((palette) => {
                                // Palette active
                                return this.$store.dispatch('LOAD_PALETTE', palette.id)
                            })
                            .then(() => {
                                this.loading = false;
                                if (!localStorage.getItem('welcome-' + this.user.code)) {
                                    localStorage.setItem('welcome-' + this.user.code, '1');
                                    this.$router.push('/welcome-about');
                                } else {
                                    // Redirection sur l'accueil
                                    this.$router.push('/palette');
                                }
                            })
                            .catch((error) => {
                                this.loading = false;
                                Ui.notif(error, "error");
                                // Erreur pour rejoindre la palette, mais inscription réussie
                                this.$router.push('/rejoindre');
                            });
                    }
                }).catch(err => {
                    // user create error
                    Ui.notif(err, "error");
                    this.loading = false;
                });
            } else {
                console.error("Les informations sont incomplètes", this.user.code, this.user.password)
            }
        },
        async setCode(code) {
            this.user.code = code;
        }
    },
    mounted() {
        this.referral = this.$route.query.referral;
    },
    created() {
        if (!this.$route.query.code) {
            // Le code n'est pas transmis dans l'URL
            var cookie_code = this.$_.cookies_get("palette_register_code");
            if (cookie_code && cookie_code !== "start") {
                // Mais il est stocké dans les cookies.
                this.setCode(cookie_code);
            }
        }

        if (this.$store.getters.isAuth) {
            this.$router.push('/rejoindre' + (this.user.code ? '?code=' + this.user.code : ''));
        }
    }
}
</script>
