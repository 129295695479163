<template>
    <router-link :to="to" class="member-item" v-if="member">
        <div class="member-image">
            <img :src="memberImage"/>
        </div>
        <span>{{ member.user.publicName }}</span>
    </router-link>
    <router-link :to="to" class="member-item" v-else-if="to">
        <div class="member-image">
            <i class="ic icon-plus"></i>
        </div>
        <span>Détails</span>
    </router-link>
    <div class="member-item placeholder" v-else>
        <div class="member-image"></div>
        <span></span>
    </div>
</template>

<script>
export default {
    name: 'BoardMemberItem',
    props: ['member', 'to'],
    computed: {
        memberImage() {
            return this.$store.getters.getMemberImage(this.member.user);
        },
        itsMe() {
            return this.$store.getters.USER.id === this.member.user.id;
        }
    }
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "../../styles/vars";

.member-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: math.div($padding-wrapper, 2);
  cursor: pointer;
  width: 42px;

  &.placeholder {
    position: relative;

    > span {
      width: 100%;

      &::after {
        content: '';
        display: block;
        background: #eee;
        color: #eee;
        border-radius: 10px;
        height: 11px;
        width: 80%;
        position: relative;
        margin: 4px auto 0;
      }
    }
  }

  .member-image {
    width: 42px;
    height: 42px;
    border-radius: 1000px;
    background: #eee;
    //padding: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .15s;

    &:hover {
      box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.3);
      background: #fff;
    }

    &:active {
      box-shadow: inset 0 -1px 4px rgba(0, 0, 0, 0.2);
      background: #fff;
    }
  }

  > span {
    font-size: 0.875rem;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: clip;
    color: $gray;
    display: block;
    min-height: 18px;
  }

  img {
    max-width: 100%;
    text-align: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ic {
    text-align: center;
  }
}

</style>