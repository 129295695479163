<template>
    <div id="progress-bar">
        <div class="steps-indicators" v-if="this.max === this.steps[this.steps.length - 1].count">
            <div class="step" v-for="step in steps" :data-step="step.count" :key="step.count"
                 :class="step.active ? 'active': ''">
                <div class="count">{{ step.count }}</div>
                <span class="gift" v-if="step.gifts > 0 && showGifts">
                    <img :src="require('@/assets/img/gift.svg')"/> <span>x{{ step.gifts }}</span>
                </span>
            </div>
        </div>
        <div class="steps-indicators" v-else>
            <template v-for="n in this.max">
                <div class="step" v-if="n % 25 === 0" :data-step="n" v-bind:key="n">
                    <div class="count" v-if="n % 50 === 0">{{ n }}</div>
                </div>
            </template>
        </div>
        <progress :max="max" :value="current" :class="current < 40 ? 'empty' : ''"></progress>
    </div>
</template>

<script>
    import {steps} from "@/mixins/steps";

    export default {
        name: 'PalletProgressBar',
        props: ['max', 'current', 'goal', 'showGifts'],
        mixins: [steps],
        mounted() {
            this.$el.querySelectorAll('.step').forEach(step => {
                const count = parseInt(step.getAttribute('data-step'));
                step.style.left = this.percentValue(count) + "%";
                if (count <= this.goal && count > this.goal - 10) {
                    step.classList.add('goal');
                }
            });
        },
        methods: {
            percentValue(value) {
                if (value > 0) {
                    return value / this.max * 100;
                }
                return 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/vars";

    #progress-bar {
        width: 100%;
        margin: auto;
        padding: 10px 0 15px;
    }

    progress {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        height: 14px;
        padding: 0;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 7px;
        background-color: #eceff1;
        border: 2px solid #cfd8dc;
    }

    progress:not([value]) {
        background: #fff;
    }

    progress[value]::-webkit-progress-bar {
        background-color: #eceff1;
        border-radius: 5px;
    }

    progress::-webkit-progress-value {
        background-color: $primary;
        border-radius: 5px;
    }

    progress::-moz-progress-bar {
        background-color: $primary;
        border-radius: 5px;
    }

    progress.empty::-webkit-progress-value {
        background-color: $gray;
        border-radius: 5px;
    }

    .steps-indicators {
        position: relative;

        .step {
            position: absolute;
            margin-left: -16px;
            width: 30px;
            height: 25px;
            text-align: center;
            font-size: 0.875rem;
            transition: all .2s;
            top: -20px;
            color: $gray;
            font-weight: 500;

            .count {
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
            }

            &::before {
                content: '';
                position: absolute;
                top: 15px;
                border-left: 2px solid #cfd8dc;
                right: 0;
                left: 50%;
                height: 10px;
            }

            .gift {
                position: absolute;
                bottom: -30px;
                white-space: nowrap;
                margin-left: -15px;

                span {
                    padding-left: 3px;
                }

                img {
                    height: 12px;
                    vertical-align: -2px;
                }
            }

            &:last-child::before {
                display: none;
            }

            &.active {
                color: #000;

                .gift {
                    color: #000;
                }
            }

            &.goal {
                color: $primary;

                &::before {
                    border-color: $primary;
                    border-width: 4px;
                    margin-left: -2px;
                    height: 18px;
                }

                .gift {
                    color: $gray;
                }
            }
        }
    }
</style>