<template>
    <div class="product">
        <div class="product__infos">
            <div class="primary_content">
                <h3 class="product__title">
                    {{ product.name }}
                </h3>
                <p class="product__subtitle" v-if="product.short_description">{{ product.short_description }}</p>
                <div class="product__price">
                    <span class="price">{{ price }}</span>
                    <span class="fees green b" v-if="collectiveFees > 0">
                        + {{ collectiveFees }}€ reversés
                    </span>
                </div>
            </div>
            <div class="secondary_content">
                <div class="product__actions">
                    <input type="text" readonly class="quantity" v-model="in_cart"/>
                    <a class="delete-btn" v-on:click="$emit('removeFromCart', product, 1)">
                        <i class="ic icon-remove"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CartItem',
    props: {
        product: {
            type: Object,
            required: true
        },
        paletteCardboardCollectiveFees: {
            type: Number,
            default: 0.0
        },
    },
    computed: {
        price() {
            if (this.product.tva) {
                return (parseFloat(this.product.price) * (1 + (this.product.tva / 100))).toFixed(2) + '€';
            }
            return parseFloat(this.product.price).toFixed(2) + '€';
        },
        in_cart() {
            const item = this.$store.getters.CART.items.find(p => p.productId === this.product.id);
            if (item) {
                return item.quantity;
            }
            return 0;
        },
        collectiveFees() {
            if (this.product.fees_apply) {
                return this.$_.round(this.paletteCardboardCollectiveFees, 2);
            }

            return 0.0;
        },
    },
}
</script>

<style lang="scss" scoped>

@use "sass:math";
@import "./src/styles/vars";

.product {

  padding: math.div($padding-wrapper, 2) 0;

  &:not(:last-child) {
    // border-bottom: 1px solid #ccc;
  }

  .product__infos {
    display: flex;
    flex-direction: row;
    align-items: center;

    .primary_content {
      flex: 1;
      padding-right: $padding-wrapper;
    }

    .product__title {
      font-family: $font-body-alt;
      font-weight: 500;
      color: #000;
    }

    .product__subtitle {
      margin: 0;
      color: $gray;
    }

    .secondary_content {
      flex: 0;
    }
  }

  .product__actions {
    padding: 10px 0;
    display: flex;
    max-width: 100%;

    .quantity {
      text-align: center;
      border: 1px solid $gray;
      padding: 8px;
      width: 36px;
      height: 36px;
    }

    .delete-btn {
      display: flex;
      align-items: center;
      padding: 0px 8px;
      margin-left: 8px;

      .ic {
        font-size: 18px;
        color: $red;
      }

      &:hover .ic {
        color: lighten($red, 10);
      }
    }
  }
}

</style>