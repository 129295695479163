<template>
    <div class="master scrollable">
        <TopBar :back="backUrl" title="Bonus de cartons"/>
        <div class="wrapper flex">
            <div class="text">
                <p class="b">Remplissez votre palette et obtenez des cartons offers !</p>
            </div>
            <div class="cardboards-steps-container">
                <input type="range" class="range" step="10"
                       v-model="cardboardSelection"
                       :min="palette.cardboardMinimumCapacity"
                       :max="palette.cardboardMaximumCapacity">

                <div class="steps-indicators">
                    <div class="step" v-for="step in steps" :style="'left:' + percentValue(step.count) + '%'"
                         :key="step.count"
                         :class="(step.active ? 'active': '')
                            + (step.description ? ' extended' : '')
                            + (step.count === cardboardSelection || step.count === (cardboardSelection - 10) ? ' current' : '') ">
                        <div class="count">{{ step.count }}</div>
                        <span class="gift" v-if="step.gifts > 0">
                            <i class="i icon-gift"></i><span>x{{ step.gifts }}</span>
                        </span>
                        <div class="step-description">
                            {{ step.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TopBar from "../../components/UX/TopBar";
    import {steps} from "../../mixins/steps";

    export default {
        name: 'PalletManagerCardboards',
        mixins: [steps],
        props: ['code'],
        components: {
            TopBar,
        },
        data() {
            return {
                palette: null,
                cardboardSelection: 0,
                backUrl: '/palette/' + this.code + '/parametres',
            }
        },
        watch: {
            cardboardSelection: function (new_range, old_range) {
                this.steps.map(step => {
                    step.active = step.count <= new_range;
                })
            },
        },
        methods: {
            saveCardboards() {
                this.$emit('capacity', this.cardboardSelection);
                this.back();
            },
            back() {
                this.$router.push(this.backUrl);
            },
            percentValue(value) {
                value -= this.palette.cardboardMinimumCapacity;
                if (value > 0) {
                    return (value / (this.palette.cardboardMaximumCapacity - this.palette.cardboardMinimumCapacity) * 100);
                }
                return 0;
            },
            currentStep() {
                return this.steps.find(step => step.count === this.cardboardSelection);
            }
        },
        mounted() {
            this.cardboardSelection = this.palette.cardboardCapacity;
        },
        created() {
            this.palette = this.$parent.palette;
            if (!this.palette || !this.palette.cardboardMinimumCapacity) {
                this.back();
            }
        },
    }
</script>

<style lang="scss" scoped>

    @import "../../styles/vars";

    .text {
        margin-bottom: .5rem
    }

    .cardboards-steps-container {
        flex: 2;
        padding-top: 25px;
    }

    .range {
        margin: 0;
        padding: 2px 0;
        background: #fff;
        position: relative;
        z-index: 50;
    }

    .steps-indicators {
        position: relative;
        width: calc(100% - 30px);
        height: 100%;
        margin: 0 auto;
        pointer-events: none;

        .step {
            position: absolute;
            margin-left: -15px;
            width: 30px;
            height: 5px;
            text-align: center;
            font-size: 0.875rem;
            transition: all .2s;
            color: $disabled;

            .count {
                position: absolute;
                top: -42px;
                left: 0;
                right: 0;
                background: #fff;
            }

            .gift {
                position: absolute;
                top: -7px;
                margin-left: -13px;
                font-weight: normal;
                color: $gray;
                z-index: 12;
                white-space: nowrap;

                .ic {

                }

                span {
                    padding-left: 5px;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: -35px;
                border-left: 1px solid;
                right: 0;
                left: 50%;
                bottom: 18px;
                color: #ddd;
            }

            &.active {
                color: $secondary;
                font-weight: bold;

                .gift {
                    color: $secondary;
                }

                .step-description {
                    color: $gray;
                }
            }

            &.current {
                color: $primary;

                .gift {
                    color: $secondary;
                }

                &::before {
                    color: $primary;
                }

                .step-description {
                    color: $primary;
                }
            }

            .step-description {
                position: absolute;
                //border: 1px solid;
                font-size: 11px;
                line-height: 1.3;
                width: 110px;
                padding: 6px 0;
                background: #fff;
                display: none;
                bottom: 0;
                pointer-events: none;
                border-radius: 4px;
                z-index: 10;
            }

            &:nth-child(1), &:nth-child(2) {
                .step-description {
                    left: -15px;
                }
            }

            &:nth-child(3) .step-description {
                margin-left: -30px;
            }

            &:nth-child(4), &:nth-child(5) {
                .step-description {
                    right: -15px;
                }
            }

            &.extended .step-description {
                display: block;
            }

            @media (min-height: 500px) {
                &.extended {
                    &:nth-of-type(1) {
                        height: 25%;
                    }

                    &:nth-of-type(2) {
                        height: 50%;
                    }

                    &:nth-of-type(4) {
                        height: 75%;
                    }

                    &:nth-of-type(5) {
                        height: 100%;
                    }
                }
            }

            @media (min-width: 500px) and (min-height: 500px) {
                &.extended {
                    &:nth-of-type(1) {
                        height: 30%;
                    }

                    &:nth-of-type(2) {
                        height: 40%;
                    }

                    &:nth-of-type(4) {
                        height: 50%;
                    }

                    &:nth-of-type(5) {
                        height: 60%;
                    }
                }
            }

            @media (max-height: 500px) {
                &:not(.current) {
                    height: 18px !important;

                    .step-description {
                        opacity: 0 !important;
                    }
                }
                &.current {
                    /*
                    height: 200px !important;
                    .step-description {
                        right: 0;
                        width: 200px !important;
                    }
                    */
                }
            }

        }
    }
</style>