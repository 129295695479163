import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './Store'
import VueGtag from 'vue-gtag';

import AppContainer from "./layouts/AppContainer.vue";
import NoBottomNav from "./layouts/NoBottomNav.vue";
import './registerServiceWorker'

Vue.component('container-with-navbar-layout', AppContainer);
Vue.component('no-navbar-layout', NoBottomNav);

Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
});

Vue.prototype.round = (number, decimals = 2) => {
    return Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
};

router.beforeEach(async (to, from, next) => {

    if (store.getters.isAuth) {

        console.debug("is auth");

        if (!store.getters.ACTIVE_PALETTE) {
            console.debug("*** load default palette ***");
            await store.dispatch("LOAD_PALETTE").then(palette => {
                console.debug("Palette loaded", palette);
            }).catch(error => {
                console.debug("No palette : redirect to join ?");
            });
        }

        if (!store.getters.BRAND && store.getters.ACTIVE_PALETTE) {
            console.debug("*** load environment data ***");
            await store.dispatch("LOAD_ENVIRONMENT_DATA").catch(error => {
                console.debug("No palette");
            });
        }
    }

    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.getters.isAuth && store.getters.USER !== null) {
            next()
            return
        }

        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        if (params.get("p") !== null && parseInt(params.get("p")) > 0) {
            console.debug("Force loading palette from params", params.get("p"));
            await store.dispatch('LOAD_PALETTE', params.get("p")).catch(async () => {
                await store.dispatch('LOAD_PALETTE', 0);
            });
        }

        if (store.getters.AUTH_TOKEN != null && store.getters.AUTH_TOKEN.length > 0) {
            console.debug("autologin attempt");
            // autologin
            store.dispatch('me').then(data => {
                console.debug("auth success");
                next();
            }).catch((error) => {
                console.debug(error, "=> redirect to onboarding");
                store.dispatch('AUTH_LOGOUT');
                next('/onboarding')
            });
        } else {
            console.debug("auth required => onboarding");
            next('/onboarding')
        }
    } else {
        console.debug("no auth required", to.name);
        next()
    }
});

import common from './common.js'

Vue.prototype.$_ = common

if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtag, {
        config: {
            id: 'UA-134995993-2',
            params: {
                send_page_view: true
            }
        },
    }, router);
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
