export const cart = {
    computed: {
        cart() {
            return this.$store.getters.CART;
        },
        nbItems() {
            return this.$store.getters.getCartItemsCount;
        },
        productsInCart() {
            return this.cart.items.map(data => this.$store.getters.getProductById(data.productId));
        },
        tva_amount() {
            return this.$_.round(this.$store.getters.getCartTotalTVA);
        },
        order_amount() {
            return this.$_.round(this.$store.getters.getCartTotalTTC);
        },
        collective_fees() {
            return this.$_.round(this.$store.getters.getCartTotalCollectiveFees);
        },
    },
    methods: {
        async fetchCart() {
            if (!this.$store.getters.CART.loaded) {
                return this.$store.dispatch('LOAD_CART');
            }
            return this.$store.getters.CART;
        },
        addToCart(product, quantity = 1) {
            const index = this.cart.items.findIndex(p => p.productId === product.id);
            let p;
            console.log("product" , product);
            if (index > -1) {
                p = this.$store.dispatch('CART_UPDATE', {
                    productId: product.id,
                    productName: product.name,
                    quantity: this.cart.items[index].quantity + quantity,
                });
            } else {
                p = this.$store.dispatch('CART_ADD', {
                    productId: product.id,
                    productName: product.name,
                    quantity: quantity,
                });
            }
            if (this.$gtag) {
                this.$gtag.event('add_to_cart', {
                    'product_id': product.id,
                    'product_name': product.name,
                    'quantity': quantity
                });
            }

            return p;
        },
        removeFromCart(product, quantity = -1) {
            const index = this.cart.items.findIndex(p => p.productId === product.id);
            if (index > -1) {
                let p;
                let new_quantity = this.cart.items[index].quantity - quantity;
                if (new_quantity <= 0) {
                    p = this.$store.dispatch('CART_REMOVE', product.id);
                } else {
                    p = this.$store.dispatch('CART_UPDATE', {
                        productId: product.id,
                        quantity: new_quantity,
                    });
                }
                if (this.$gtag) {
                    this.$gtag.event('remove_from_cart', {
                        'product_id': product.id,
                        'product_name': product.name,
                        'quantity': new_quantity
                    });
                }
                return p;
            }
        },
    }
}