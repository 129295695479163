export const user = {
    computed: {
        user() {
            return this.$store.getters.USER;
        },
        userImage() {
            return this.$store.getters.getAuthUserImage;
        },
        fullName() {
            var dspName = (this.$store.getters.USER.firstName + " " + this.$store.getters.USER.lastName).trim();
            if (!dspName.length) {
                return "Anonyme";
            }
            return dspName;
        },
        isGodFather() {
            return this.$store.getters.isGodfather;
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('AUTH_LOGOUT').then(() => {
                window.location.replace("/onboarding?_t=" + (new Date()).getTime());
            });
        },
        isGodfatherPalette(palette) {
            return this.$store.getters.isGodfatherPalette(palette);
        }
    }
}