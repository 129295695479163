import Api from './Api'
import store from '../Store'

export default {
    formatDataCart(data) {
        const product = store.getters.PRODUCTS.find(product => product.id === data.productId);
        if (product) {
            return {
                productId: data.productId,
                quantity: data.quantity,
                price: product.price,
                tva: product.tva ? product.tva : 20,
                fees: product.fees_apply ? data.fees : 0.0,
            }
        }
        return null;
    },
    loadCart() {
        return Api.get('/cart')
            .then(async (data) => {
                console.log("Load cart", data, data.length > 0);
                const items = await data.map(item => this.formatDataCart(item)).filter(el => el !== null);
                console.log("formatted", items);
                return {
                    items: items,
                    total: items.length,
                };
            });
    },
    addItem(productId, quantity) {
        return Api.post('/cart', {
            productId: productId,
            quantity: quantity,
            paletteId: store.getters.ACTIVE_PALETTE_ID
        });
    },
    editItem(productId, quantity) {
        return Api.put('/cart', {
            productId: productId,
            quantity: quantity,
            paletteId: store.getters.ACTIVE_PALETTE_ID
        });
    },
    removeItem(productId) {
        return Api.delete('/cart', {
            productId: productId,
        });
    },
    getCatalog(paletteId) {
        return Api.get('/palette/' + paletteId + '/catalog');
    }
}
