<template>
    <div class="app-content">
        <div class="app-body with-nav">
            <slot/>
        </div>
        <BottomNav v-bind:current="currentMenu"/>
    </div>
</template>

<script>
import BottomNav from "../components/UX/BottomNav";

export default {
    name: 'AppContainer',
    components: {BottomNav},
    computed: {
        currentMenu() {
            return this.$route.meta.current || null;
        }
    },
}
</script>