<template>
    <div>
        <h1 class="title mb" v-if="this.newPallet">Créer une palette</h1>
        <h1 class="title mb" v-else>Rejoindre une palette</h1>
        <p v-html="registerText"/>
        <p class="b" v-if="emailWhitelist.length > 0">
            Utilisez votre adresse @{{ emailWhitelist.join(", @") }}
        </p>
        <div class="field">
            <div class="label">Votre adresse email</div>
            <input type="email" name="email" class="input" placeholder="Indiquez une adresse email"
                   autocomplete="email" v-model="input_email" v-on:keyup="handleKeyEnter" v-focus/>
        </div>
        <div class="field">
            <button type="button" class="btn btn-l" @click="nextStep">Continuer</button>
            <div class="center mt">
                <router-link :to="'/connexion?code=' + code" class="small">
                    Déjà un compte ? <span class="_link">Connectez-vous</span>
                </router-link>
            </div>
        </div>
        <Modal v-if="accountExists">
            <div slot="header">
                <div class="title">Ravis de vous revoir</div>
            </div>
            <div slot="body" class="form">
                <p>
                    <b>Un compte existe déjà avec cette adresse email</b>.
                    <br>Voulez-vous plutôt vous connecter ?
                </p>
            </div>
            <div slot="footer">
                <div class="double-buttons-container">
                    <div>
                        <router-link :to="'/connexion?code=' + code + '&email=' + input_email" class="btn bg">
                            Se connecter
                        </router-link>
                    </div>
                    <div>
                        <a class="btn btn-link" @click="accountExists = false;">Non, annuler</a>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>

import {Ui} from "@/class/Ui";
import UserService from "@/services/UserService";
import Modal from "@/components/UX/Modal";

export default {
    name: 'RegisterLogin',
    components: {Modal},
    props: ['user'],
    data() {
        return {
            accountExists: false,
            code: this.$route.query.code,
            input_email: this.$parent.user.email
        }
    },
    methods: {
        handleKeyEnter(e) {
            if (e.keyCode === 13) {
                this.nextStep();
            }
        },
        nextStep() {
            if (this.checkEmail(this.input_email)) {
                this.checkExistingAccount(this.input_email).then((valid) => {
                    if (valid) {
                        this.setEmail(this.input_email);
                        this.$router.push('/inscription/informations');
                    } else {
                        console.log("valid", valid);
                    }
                });
            } else {
                if (this.emailWhitelist.length > 0) {
                    Ui.notif("Vous devez renseigner une adresse email @" + this.emailWhitelist.join(", @"), "error");
                } else {
                    Ui.notif("Votre email ou téléphone n'est pas valide", "error");
                }
            }
        },
        checkExistingAccount(email) {
            return UserService.verifyAccount({
                email: email
            }).then(response => {
                if (response.valid === true) {
                    return true;
                } else {
                    this.accountExists = true;
                    // Ui.notif("Un compte associé à cette adresse existe déjà, connectez-vous", "error");
                    return false;
                }
            }).catch(error => {
                Ui.notif("Une erreur est survenue", "error");
            });
        },
        checkEmail(email) {
            let emailDomain = "(.+){2,}.(.+){2,}";
            if (this.emailWhitelist.length > 0) {
                emailDomain = "(" + this.emailWhitelist.join("|") + "|boreal-business.net|brasserie-bos.fr)";
            }
            const regexEmail = new RegExp("(.+)@" + emailDomain);
            return regexEmail.test(email);
        },
        setEmail(email) {
            this.$parent.user.email = email;
        }
    },
    computed: {
        registerText() {
            return this.$store.getters.ENV_DATA("register_text", "Pour vous tenir informé des avancées de votre palette ! " +
                "Vos informations ne sont utilisées que pour La Palette, absolument aucune information ne sera transmise à des tiers.");
        },
        emailWhitelist() {
            return this.$store.getters.BRAND && this.$store.getters.BRAND.emailWhitelist !== null ? this.$store.getters.BRAND.emailWhitelist : [];
        },
        newPallet(){
            return this.user.code === 'start';
        }
    },
    mounted() {
        if (!this.user.code && !this.code) {
            this.$router.push('/inscription');
        }
        if (!this.code && this.user.code) {
            this.code = this.user.code;
        }
    }
}
</script>
