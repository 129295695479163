<template>
    <Modal v-if="modalOpen" v-on:cancel="back" :loading="loading"
           :showCloseButton="true">
        <div slot="body">
            <div>
                <h2 class="title">Importez une photo</h2>
                <label class="upload_header">
                    <span class="camera">
                        <i class="icon-camera"></i>
                    </span>
                    <input type="file" ref="image_input" name="image" @change="onFileChange" style="display: none"/>
                </label>
            </div>
            <div class="or">OU</div>
            <div>
                <h2 class="title">
                    Choisissez un avatar
                </h2>
                <div class="avatar-list">
                    <div class="avatar-container" v-for="(avatar, k) in avatars" v-bind:key="k"
                           @click.stop="avatar.pathLong ? selectAvatar(avatar) : null">
                        <template v-if="avatar && avatar.pathLong">
                            <input style="display: none" type="radio" name="rd_avatar" :value="avatar.pathShort"/>
                            <img :src="avatar.pathLong"/>
                        </template>
                        <div v-else class="avatar-placeholder"/>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" />
    </Modal>
</template>

<script>

import Modal from "@/components/UX/Modal";
import Api from "@/services/Api";

export default {
    name: 'AvatarSelector',
    props: ['modalOpen'],
    components: {Modal},
    data() {
        return {
            avatars: new Array(10),
            uploading: false,
            maxWidth: 300,
            maxHeight: 300,
            loading: false,
        }
    },
    methods: {
        async readDefaultAvatars() {
            const files = require.context('@/assets/img/avatars/', true, /\.png$/);
            const imgs = [];
            files.keys().forEach(key => (imgs.push({pathLong: files(key), pathShort: key})));
            this.avatars = imgs;
        },
        back() {
            console.log("modalClosed");
            this.$emit('modalClosed')
        },
        uploadEnd() {
            console.debug("upload ended")
            this.loading = true;
            this.reloadUser().then(() => {
                this.loading = false;
                this.back();
            });
        },
        selectFile(file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.base64_src = e.target.result
                this.image = new Image();
                this.image.src = this.base64_src;

                this.image.onload = (e) => {
                    var imageWidth = this.image.width;
                    var imageHeight = this.image.height;

                    if (imageWidth > imageHeight) {
                        if (imageWidth > this.maxWidth) {
                            imageHeight *= this.maxWidth / imageWidth;
                            imageWidth = this.maxWidth;
                        }
                    } else {
                        if (imageHeight > this.maxHeight) {
                            imageWidth *= this.maxHeight / imageHeight;
                            imageHeight = this.maxHeight;
                        }
                    }

                    var canvas = document.createElement('canvas');
                    canvas.width = imageWidth;
                    canvas.height = imageHeight;
                    this.image.width = imageWidth;
                    this.image.height = imageHeight;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(this.image, 0, 0, imageWidth, imageHeight);

                    // Convert the resize image to a new file to post it.
                    this.uploadFile(canvas.toDataURL(file.type)).then(() => {
                        this.$emit('uploadEnd');
                    });
                }
            }

            reader.readAsDataURL(file);
        },
        onFileChange(e) {
            this.uploading = true;
            const file = e.target.files[0];
            if (file && file.type.indexOf('image') === 0) {
                return this.selectFile(file);
            }
            return false;
        },
        uploadFile(src) {
            this.uploading = true;
            return Api.put('/user/image', {
                image: src,
            }).then(() => {
                this.uploadEnd();
            });
        },
        async selectAvatar(avatar) {
            console.log("select avatar ", avatar);
            let response = await fetch(avatar.pathLong);
            let data = await response.blob();
            let metadata = {
                type: 'image/png'
            };
            let file = new File([data], avatar.pathShort, metadata);
            this.selectFile(file);
        },
        reloadUser() {
            return this.$store.dispatch('me', false);
        }
    },
    mounted() {
        this.readDefaultAvatars();
    }
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.upload_header {
  height: 100px;
  background: #eee;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;

  .camera {
    display: block;
    text-align: center;
    margin: auto;
    font-size: 24px;
    color: $gray;
  }
}

.avatar-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 15px;
}

.avatar-container {
  width: 20%;
  padding: 5px;
  cursor: pointer;

  img {
    max-width: 100%;
    border-radius: 1000px;
  }

  .avatar-placeholder {
    width: 68px;
    height: 68px;
    max-width: 100%;
    background: #eee;
    border-radius: 1000px;
  }
}

.or {
  text-align: center;
  padding: 15px 0 10px;
  font-weight: bold;
  color: $primary;
  font-size: 1.5rem;
}

</style>