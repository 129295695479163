<template>
    <div class="wrapper">
        <form id="login-form" @submit.prevent="login">
            <h1 class="title mb size-2">Connexion</h1>
            <div class="field">
                <label class="label">Votre adresse email</label>
                <input type="email" class="input" placeholder="Votre email" autocomplete="email"
                       v-model="email" ref="input_email" required>
            </div>
            <div class="field">
                <label class="label">Votre mot de passe</label>
                <input type="password" class="input" placeholder="Votre mot de passe" autocomplete="current-password"
                       v-model="password" ref="input_password" required>
            </div>
            <div class="field mt">
                <button type="submit" class="btn btn-l" :class="loading ? 'loading' : ''">Connexion</button>
                <div class="center mt">
                    <a @click="recoverPassword = true" class="_link">
                        Zut, mot de passe oublié
                    </a>
                </div>
            </div>
            <RecoverPasswordModal v-bind:email="email" :show="recoverPassword"/>
        </form>
    </div>
</template>

<script>
import RecoverPasswordModal from "./RecoverPasswordModal";

export default {
    name: 'Connexion',
    components: {RecoverPasswordModal},
    data() {
        return {
            email: this.$route.query.email,
            password: "",
            recoverPassword: false,
            loading: false,
            redirectToCode: this.$route.query.code
        };
    },
    methods: {
        login: function () {
            const {email, password} = this
            if (!this.loading) {
                this.loading = true;
                let firstAuth = true;
                if (localStorage.getItem('welcome')) {
                    firstAuth = false;
                }
                this.$store.dispatch('AUTH_REQUEST', {email, password}).then(() => {
                    this.$store.dispatch('me', false);
                }).then(() => {
                    if (this.$gtag) {
                        this.$gtag.event('login');
                    }
                    this.loading = false;
                    if (this.redirectToCode) {
                        if (this.redirectToCode === 'start') {
                            this.$router.push('/palette/start/parametres');
                        } else {
                            // Rejoindre une palette
                            this.$router.push('/rejoindre?code=' + this.redirectToCode);
                        }
                    } else if (this.$store.getters.isGodfather && firstAuth) {
                        localStorage.setItem('welcome', '1');
                        this.$router.push('/welcome');
                    } else {
                        // Page palette par défaut
                        this.$router.push('/palette');
                    }
                }).catch(() => {
                    this.loading = false;
                })
            }
        }
    },
    mounted() {
        if (this.email && this.email.length > 0) {
            this.$refs.input_password.focus();
        } else {
            this.$refs.input_email.focus();
        }
    }
}
</script>
