<template>
    <div class="loader-container">
        <div class="loader">
            <svg class="circular-loader" viewBox="25 25 50 50">
                <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke-width="4"/>
            </svg>
        </div>
    </div>
</template>


<script>
export default {
    name: 'LoadingSpinner',
}
</script>

<style lang="scss" scoped>

@import "../../styles/vars";

.loader-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5em 0;
}

.loader {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;

  .circular-loader {
    -webkit-animation: rotate 1.8s linear infinite;
    animation: rotate 1.8s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }

  .loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  0% {
    stroke: $primary;
  }
  40% {
    stroke: $primary;
  }
  66% {
    stroke: $primary;
  }
  80%, 90% {
    stroke: $primary;
  }
}

@keyframes color {
  0% {
    stroke: $primary;
  }
  40% {
    stroke: $primary;
  }
  66% {
    stroke: $primary;
  }
  80%, 90% {
    stroke: $primary;
  }
}

</style>