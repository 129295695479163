<template>
    <div class="">
        <div class="field">
            <div class="label">Choisissez votre mot de passe</div>
            <input type="password" class="input" placeholder="" autocomplete="new-password" ref="password"
                   v-model="password"/>
        </div>
        <div class="field">
            <button type="button" class="btn btn-l" :class="loading ? 'loading' : ''" @click="nextStep">
                C'est parti !
            </button>
        </div>
    </div>
</template>

<script>
    import {Ui} from "@/class/Ui";

    export default {
        name: 'RegisterPassword',
        data() {
            return {
                password: null,
            }
        },
        computed: {
            loading() {
                return this.$parent.loading;
            }
        },
        methods: {
            nextStep() {
                if (this.checkPassword(this.password)) {
                    this.setPassword(this.password);
                    this.$emit('subscribe');
                } else {
                    Ui.notif("Choisissez un mot de passe de minimum 5 caractères", "error");
                }
            },
            checkPassword(password) {
                return password.length >= 5;
            },
            setPassword(password) {
                this.$parent.user.password = password;
            },
        },
        mounted() {
            if (!this.$parent.user.code) {
                this.$router.push('/inscription');
            }
            this.$refs.password.focus();
            this.password = this.$parent.user.password;
        }
    }
</script>
